import * as React from 'react';
import { animateScroll as scroll } from 'react-scroll';

import './styles.less';

class LongCopy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isShowScrollButton: true
        };

        this.parentRef = React.createRef();
        this.contentRef = React.createRef();
        this.buttonRef = React.createRef();
        this.headerRef = React.createRef();
        this.mainRef = React.createRef();
        this.ellipsisRef = React.createRef();
        this.questionRef = React.createRef();
        this.choicesRef = React.createRef();
    }

    componentDidMount() {
        this.showScrollButton();
        window.addEventListener('resize', this.showScrollButton);
        window.addEventListener('scroll', this.showScrollButton, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.showScrollButton);
        window.removeEventListener('scroll', this.showScrollButton);
    }

    showScrollButton = () => {
        const maxPageHeight = this.headerRef.current.offsetHeight + this.mainRef.current.offsetHeight;
        const currentScrollPosition = window.pageYOffset + window.innerHeight;
        if (currentScrollPosition > maxPageHeight - 30) {
            this.setState({ isShowScrollButton: false });
        } else {
            this.setState({ isShowScrollButton: true });
        }
    };

    scrollToBottom = () => {
        scroll.scrollToBottom({
            duration: 500,
            smooth: 'easeInOut',
        })
    };

    ellipsisAnimationEnd = () => {
        this.ellipsisRef.current.style.display = 'none';
        this.questionRef.current.style.display = 'block';
    };

    questionAnimationEnd = () => {
        this.choicesRef.current.style.transition = 'opacity .5s .5s';
        this.choicesRef.current.style.opacity = 1;
    };

    clickBack = () => {
       this.props.history.push(this.props.prevLocation);
    };

    render() {
        return (
            <div className={'long-copy-container'}>
                <header
                    ref={this.headerRef}>
                    <div>
                        <button
                            className={'back'}
                            onClick={ this.clickBack }
                        >
                            <i className="material-icons">arrow_back</i>
                        </button>
                        <h1>Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis?</h1>
                    </div>
                </header>
                <main
                    ref={this.mainRef}>
                    <div
                        ref = { this.parentRef }
                        className={'content'}>
                        <div
                            ref = {this.contentRef}
                        >
                            <h1>Heading 1 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, cupiditate dignissimos dolorum eligendi enim excepturi harum iure magnam mollitia voluptas. Adipisci aperiam distinctio doloremque neque nihil omnis possimus, vel voluptatum!</h1>
                            <h2>Heading 2 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos ipsa, maxime! Aut, consequatur, debitis? Accusantium animi consectetur dicta dolore et ex, facere hic laboriosam libero molestias mollitia nesciunt praesentium suscipit.</h2>
                            <h3>Heading 3 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus consectetur culpa, delectus distinctio dolore eius expedita facere id ipsa ipsum itaque labore maiores officia quaerat quam quo similique ullam vero.</h3>
                            <p><strong>Paragraph</strong> - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus dolorem molestiae necessitatibus officiis provident qui veniam voluptate voluptatibus? Aspernatur culpa error est harum possimus quaerat tenetur voluptate. Illo, incidunt, voluptate.</p>
                            <strong>Strong - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aperiam beatae consequatur, culpa dolores eaque eos esse, et facere libero nulla praesentium, quis ratione reprehenderit sint sit tenetur ullam velit?</strong>
                            <em>Em - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit incidunt odio vitae voluptas! Alias, aliquid animi beatae dolorem, eum facere fugit iste quasi rem reprehenderit unde veniam! Expedita, officiis, sequi?</em>
                            <div className="flex-table">
                                <div className="flex-row h">
                                    <div className="flex-cell"></div>
                                    <div className="flex-cell xs-3">Week 16 (FAS)b</div>
                                    <div className="flex-cell xs-3"> Week 52 (FAS Week 52)b</div>
                                </div>
                                <div className="flex-row h">
                                    <div className="flex-cell"></div>
                                    <div className="flex-cell xs">Placebo + TCS</div>
                                    <div className="flex-cell xs">Dupixent 300 mg Q2W + TCS</div>
                                    <div className="flex-cell xs">Dupixent 300 mg QW + TCS</div>
                                    <div className="flex-cell xs">Placebo + TCS</div>
                                    <div className="flex-cell xs">Dupixent 300 mg Q2W + TCS</div>
                                    <div className="flex-cell xs">Dupixent 300 mg QW + TCS</div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-cell b i">Patients randomized</div>
                                    <div className="flex-cell xs">315</div>
                                    <div className="flex-cell xs">106</div>
                                    <div className="flex-cell xs">319</div>
                                    <div className="flex-cell xs">264</div>
                                    <div className="flex-cell xs">89</div>
                                    <div className="flex-cell xs">270</div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-cell i">IGA 0 or 1c, %</div>
                                    <div className="flex-cell xs">12.4 %</div>
                                    <div className="flex-cell xs">38.7 %f</div>
                                    <div className="flex-cell xs">39.2 %f</div>
                                    <div className="flex-cell xs">12.5 %</div>
                                    <div className="flex-cell xs">36.0 %f</div>
                                    <div className="flex-cell xs">40.0 %f</div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-cell i">EASI-50, % respondersd</div>
                                    <div className="flex-cell xs">12.4 %</div>
                                    <div className="flex-cell xs">38.7 %f</div>
                                    <div className="flex-cell xs">39.2 %f</div>
                                    <div className="flex-cell xs">12.5 %</div>
                                    <div className="flex-cell xs">36.0 %f</div>
                                    <div className="flex-cell xs">40.0 %f</div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-cell i">EASI-50, % respondersd</div>
                                    <div className="flex-cell xs">12.4 %</div>
                                    <div className="flex-cell xs">38.7 %f</div>
                                    <div className="flex-cell xs">39.2 %f</div>
                                    <div className="flex-cell xs">12.5 %</div>
                                    <div className="flex-cell xs">36.0 %f</div>
                                    <div className="flex-cell xs">40.0 %f</div>
                                </div>
                            </div>
                            <blockquote>
                                quote - Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, adipisci architecto consequuntur cum dolore dolorum error, est expedita nisi officia praesentium quam, sequi similique sint sit tempora veritatis? Ipsa, quod?
                            </blockquote>
                            <p className={'caption'}>Caption - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias aliquid, culpa, cumque dolorem, dolorum id inventore maxime minima mollitia nemo nesciunt numquam qui quos reiciendis repellat sapiente similique sunt.</p>
                            {/*eslint-disable-next-line*/}
                            <a>
                                <i className="material-icons">
                                    link
                                </i>
                                Link
                            </a>

                            <p className={'figure-header'}>Figure 1: Mean percent change from baseline in EASI in SOLO 1a and SOLO 2a (FAS)b</p>
                            <div className={'figure-container'}>
                                <p className={'figure-title'}>SOLO 1</p>
                                <div className={'img ratio-1-1'}/>
                            </div>
                            <div className={'figure-container'}>
                                <p className={'figure-title'}>SOLO 2</p>
                                <div className={'img ratio-1-1'}/>
                            </div>
                            <p className={'caption-figure'}>LS = least squares <br/><br/>a In the primary analyses of the efficacy endpoints, patients who received rescue treatment or with <br/><br/>missing data were considered non-responders. b Full analysis set (FAS) includes all patients randomized.</p>

                            <div className={'img-container-ratio-2-1'}>
                                <div className={'img ratio-2-1'}/>
                            </div>
                            <div className={'img-container-ratio-1-1'}>
                                <div className={'img ratio-1-1'}/>
                            </div>
                            <div className={'img-container-ratio-1-1'}>
                                <div className={'img ratio-1-1'}/>
                            </div>
                            <div className={'img-container-ratio-1-1'}>
                                <div className={'img ratio-1-1'}/>
                            </div>
                            <div className={'img-container-ratio-3-2'}>
                                <div className={'img ratio-3-2'}/>
                            </div>
                            <div ref={ this.ellipsisRef }
                                onAnimationEnd={ this.ellipsisAnimationEnd }
                                className={'ellipsis'}><span/><span/><span/></div>
                            <div ref = { this.questionRef }
                                 className={'question-message'}
                                 onAnimationEnd={ this.questionAnimationEnd }
                            >
                                <h3>Is this information helpful or do you need further assistance?</h3>
                            </div>
                            <div ref = { this.choicesRef } className={'choices'}>
                                <h6>Please choose</h6>
                                <button>Helpful</button>
                                <button>Unsatisfactory, I need assistance.</button>
                                <button>I want to reframe my question.</button>
                            </div>
                        </div>
                        <div className="show" align="right">
                            { this.state.isShowScrollButton && <button
                                    ref={this.buttonRef}
                                    onClick={this.scrollToBottom}
                                >
                                    <img src="/show-more.png" width="42" height="42" title="Scroll to end" alt="Scroll to end" />
                                </button>
                            }
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default LongCopy;
