import Layer from '@layerhq/web-xdk';
import _isEmpty from 'lodash/isEmpty';
import { getIOSVersion, isIOSChromeBrowser } from '../utils/common';

const TextModel = Layer.Core.Client.getMessageTypeModelClass('TextModel');

const isChrome = isIOSChromeBrowser();
const [version] = getIOSVersion();

const LayerComposeBarMixin = {
	methods: {
    onAfterCreate: function() {
      if (this.nodes.input && isChrome && version && version === 12) {
        this.nodes.input.addEventListener('focus', () => this._handleComposeBarFocus());
        this.nodes.input.addEventListener('blur', () => this._handleComposeBarBlur());
      }
    },
		onRender: function () {
      setTimeout(() => {
				this.nodes.resizer.innerHTML = this.nodes.input.value.replace(/\n/g, '<br/>') || '&nbsp;';
				this.nodes.lineHeighter.innerHTML = this.nodes.input.value.replace(/\n/g, '<br/>') || '&nbsp;';
				const willBeOneLine = !this.nodes.input.value.match(/\n/) && (this.nodes.resizer.clientHeight - this.nodes.lineHeighter.clientHeight < 10);

				// Prevent scrollbar flickering in and then out
				if (willBeOneLine) {
					this.nodes.input.style.overflow = 'hidden';
					setTimeout(() => { this.nodes.input.style.overflow = ''; }, 1);
				}

				this.toggleClass('layer-compose-bar-one-line-of-text', willBeOneLine);
			}, 10);
    },
    onDestroy: function() {
      if (this.nodes.input && isChrome && version && version === 12) {
        this.nodes.input.removeEventListener('focus', () => this._handleComposeBarFocus());
        this.nodes.input.removeEventListener('blur', () => this._handleComposeBarBlur());
      }
    },
    send: {
      mode: Layer.UI.registerComponent.MODES.OVERWRITE,
      value() {
        if (!_isEmpty(this.nodes.input.value.trim())) {
          const text = this.nodes.input.value;
          let model;
          model = new TextModel({text});
          if (this.conversation) {
            model.generateMessage(this.conversation, message => this._send(model));
          } else {
            this._send(model);
          }
          this.nodes.input.value = '';
          this._onInput({});
        }
      }
    },
    _handleComposeBarFocus: function() {
      this.classList.add('ios-12-chrome-active-input');
    },
    _handleComposeBarBlur: function() {
      this.classList.remove('ios-12-chrome-active-input');
    }
	}
};

export default LayerComposeBarMixin;