import Layer from '@layerhq/web-xdk';
import _get from 'lodash/get';
import MESSAGE_MODEL_TYPES from '../const/message-model-types';
import {isOperator, isUser} from '../utils/user';
import { isReactSnap } from '../utils/common';

const MessageListMixin = {
  properties: {
    conversation: {
      set: function set(value) {
        if (value) {
          value.isDoneSizingContent = false;
        }
      }
    }
  },
  methods: {
    getMessageItemTagName: function(message) {
      const rootPart = message.getRootPart();

      if (this._isStatusMessage(rootPart, message)) {
        return 'layer-message-item-status';
      } else if (isOperator(Layer.client.user) && isOperator(message.sender)) {
        return 'layer-message-item-sent';
      } else if (!isOperator(Layer.client.user) && isUser(message.sender)) {
        return 'layer-message-item-sent';
      } else {
        return 'layer-message-item-received';
      }
    },
    onGenerateListItem: function (widget) {
      this.updateDrugSelectorPosition();
      if (this.conversation.id !== this.conversationId) {
        this.conversationId = this.conversation.id;
      } else {
        this._toggleInput(widget);

        if (this.conversation.isDoneSizingContent) {
          this._hideBackButton();
        }
      }
    },
    updateDrugSelectorPosition() {
      const convoViewElm = document.querySelector('layer-conversation-view');
      const showMoreSelectDrugButton = document.querySelector('#show-more-button');
      const [selectDrug] = document.getElementsByClassName('select-drug');

      if (isReactSnap()) {
        convoViewElm.classList.add('conversation-view-snapshot')
      } else {
        const messageListElm = document.querySelector('layer-message-list');
        
        const headerElm = document.querySelector('.panel-header');
        if (!headerElm) return;
        const scrollTop = document.querySelector('HTML').scrollTop;
        const headerHeight = headerElm.clientHeight;
        
        let drugViewHeight = 0;
        const drugSelectorElm = document.querySelector('xircles-select-drug-view');
        const [mmiBanner] = document.getElementsByClassName('mmi-banner');
        const mmiBannerHeight = mmiBanner ? mmiBanner.clientHeight : 0;
        if (drugSelectorElm) {
          drugViewHeight = selectDrug.clientHeight;
          // pin drawer on header's bottom
          drugSelectorElm.style.top = `${-scrollTop + headerHeight + mmiBannerHeight}px`;
        }

        // adjust gap between drawer and first message
        if (convoViewElm) {
          convoViewElm.style.paddingTop = `${headerHeight}px`;
          convoViewElm.style.setProperty('height', `calc(100vh - ${headerHeight + mmiBannerHeight}px)`, 'important');
        }
        if (messageListElm) {
          if (
            showMoreSelectDrugButton &&
            showMoreSelectDrugButton.classList.contains('show-more-button--expanded') &&
            selectDrug &&
            selectDrug.classList.contains('select-drug--expanded')
          ) {
            if (drugViewHeight + 70 < messageListElm.clientHeight) {
              messageListElm.style.paddingTop = `${drugViewHeight}px`;
            } else {
              messageListElm.style.paddingTop = `${drugViewHeight + 70}px`;
            }
          } else {
            messageListElm.style.paddingTop = `${drugViewHeight}px`;
          }
        }
      }
    },
		onAfterCreate: function () {
      if (this.conversation) {
        this.conversationId = this.conversation.id;
      }      
      this.trigger('xircles-set-keyboard', { visibility: 'hidden', conversation: this.conversation });
      this.updateDrugSelectorPosition();
      window.addEventListener('resize', () => this.updateDrugSelectorPosition());
      window.addEventListener('scroll', () => this.updateDrugSelectorPosition());
		},
    onPagedDataDone: function (isDoneSizingContent) {
      this.updateDrugSelectorPosition();
      if (isDoneSizingContent) {
        this.conversation.isDoneSizingContent = isDoneSizingContent;
        Layer.Settings.client.on('conversations:change', function (evt) {
          if (evt.target.isDoneSizingContent) {
            const change = evt.changes.filter((c) => c.property === 'lastMessage').pop();

            if (change && change.newValue && change.newValue.hasOwnProperty('receivedAt') && change.newValue.sender.displayName === 'Bot') {
              const endTyping = change.newValue._messageTypeModel.customData.endTyping;
              const text = (change.newValue._messageTypeModel.text || '').toLowerCase().trim();

              if (text !== 'hidden' && text !== 'end typing' && !endTyping) {
                Layer.Settings.client.trigger('typing-indicator-change', {
                  conversationId: evt.target.id,
                  typing: [Layer.Settings.client.user],
                  paused: []
                })
              } else if (text === 'end typing' || endTyping) {
                Layer.Settings.client.trigger('typing-indicator-change', {
                  conversationId: evt.target.id,
                  typing: [],
                  paused: [Layer.Settings.client.user]
                })
              }
            }
          }
        });
      }
    },
    _toggleInput: function (widget) {
      const widgetId = widget.item.id;
      const conversationLastMessageId = this.conversation.lastMessage && this.conversation.lastMessage.id;

      if (widgetId === conversationLastMessageId) {
        let lastMessageType = _get(this.conversation, 'lastMessage._messageTypeModel.currentMessageRenderer');
        let isMessageHideInput = MESSAGE_MODEL_TYPES.indexOf(lastMessageType) >= 0;

        if (isMessageHideInput) {
          this.conversation.isMessageHideInput = true;
          this.trigger('xircles-set-keyboard', {visibility: 'hidden', conversation: this.conversation});
          widget.querySelector('.layer-list-item').classList.remove('xircles-hide');
          this.conversation.lastVisibleMessage = widget;
        } else {
          this.conversation.isMessageHideInput = false;
          this.trigger('xircles-set-keyboard', {visibility: 'visible', conversation: this.conversation});
          const backBtn = widget.querySelector('.icon-up');
          backBtn && backBtn.classList.remove('xircles-hide');
        }
      }
    },
    _hideBackButton: function () {
      // next tick, to ensure new list item is in DOM
      setTimeout(() => {
        const sent = this.querySelector('layer-message-item-sent:last-child');
        if (sent) {
          const backBtn = this.querySelector(
            'layer-message-item-received.layer-message-item-layer-text-message-view:not(.xircles-mode-hidden) .icon-up:not(.xircles-hide)'
          );
          backBtn && backBtn.classList.add('xircles-hide');
        }
      })
    },
    _renderInsertedData: function (evt) {
      // sconsole.log('rid');
    },
    onDestroy() {
			window.removeEventListener('resize', () => this.updateDrugSelectorPosition());
			window.removeEventListener('scroll', () => this.updateDrugSelectorPosition());
    }
  }
};

export default MessageListMixin;
