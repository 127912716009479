// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _has from 'lodash/has';
import _get from 'lodash/get';

import MaterialIcon from '@material/react-material-icon';
import IconButton from '../../../material-components/IconButton';
import Dialog, { DialogTitle, DialogContent } from '../../../material-components/Dialog';

import SelectModalFragment from '../SelectModalFragment';
import { SELECT_MODAL_PATHS } from '../../../containers/SelectModalContainer';
import './styles.less';

type Props = {
  isOpen: boolean,
  conversationId: string,
  onToggle: Function,
  t: Function,
};

type State = {};

class SelectModal extends React.Component<Props, State> {
  t = this.props.t;

  modalTitles = {
    [SELECT_MODAL_PATHS.SELECT_DRUG]: this.t('COMMON_SELECT_DRUG'),
  };

  handleOptionSelect = (e, drugs) => {
    const { history } = this.props;

    if (e.target.nodeName === 'LI') {
      const selectedProduct = drugs.find((drug) => drug.title === e.target.innerHTML);

      window.dispatchEvent(new CustomEvent('select-drug', {
        detail: { selectedProduct }
      }));

      history.goBack();
    }
  };

  handleSelectDrugFragment = (props) => {
    const drugs = _get(props, 'location.state.detail.drugs', []);
    const selectedProduct = _get(props, 'location.state.detail.selectedProduct');
    const selectedDrug = drugs.find((drug) => drug.slug === selectedProduct);
    const selectedProductTitle = (selectedDrug || {}).title;

    return (
      <SelectModalFragment
        selectedOption={selectedProductTitle}
        selectOptions={drugs}
        onOptionSelect={(e) => this.handleOptionSelect(e, drugs)}
      />
    );
  };

  onClose = (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.props.onToggle(ev);
  };

  getTitle = (matchPath) => _has(this.modalTitles, matchPath) ? this.modalTitles[matchPath] : '';

  renderCloseButton() {
    return (
      <div style={{ position: 'absolute', top: '-11px', right: '0px', color: 'xSlateLighter' }}>
        <IconButton
          id="close-info-modal"
          size="small"
          className="closeBtn"
          onClick={this.onClose}
        >
          <MaterialIcon icon="clear" />
        </IconButton>
      </div>
    );
  }

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener('click', (event) => {
      if (!Array.from(this.node.classList).includes('mdc-dialog--opening') && this.props.isOpen) {
        this.props.onToggle(event);
      }
    });
  }

  render() {
    const {
      isOpen,
      matchPath,
      onToggle,
    } = this.props;

    return (
      <Dialog className="select-dialog" open={isOpen} onClose={onToggle}>
        <DialogTitle style={{ textAlign: 'center' }}>
          {this.getTitle(matchPath)}
          {this.renderCloseButton()}
        </DialogTitle>
        <DialogContent>
          <div className="select-dialog__content">
            <section className="select-dialog__routes">
              <Switch location={this.props.location}>
                <Route
                  exact
                  path={SELECT_MODAL_PATHS.SELECT_DRUG}
                  render={this.handleSelectDrugFragment}
                />
              </Switch>
            </section>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(SelectModal));