// @flow

import React, { Component } from 'react';
import CommonContext from '../CommonContext';

type Props = {
  children: any,
}

type State = {
  currentAction: ?string,
};

export default class CommonProvider extends Component<Props, State> {
  state = {
    currentAction: null,
  };

  handleCurrentActionChange = (currentAction) => {
    this.setState({ currentAction });
  };

  render() {
    const { children } = this.props;
    const value = {
      ...this.state,
      onCurrentActionChange: this.handleCurrentActionChange,
    };
    return <CommonContext.Provider value={value}>{children}</CommonContext.Provider>;
  }
}
