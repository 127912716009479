// @flow

/**
 *      Creates stateless UI component for Question page
 *
 *      onToggleMenu     - callback for toggle menu ()=>{}, NOT required
 *      titleProps       - props for title, NOT required
 */

/**
 * GTM - Triggers
 *
 * name: Click on Anmelden
 * fires on: Click element matches CSS selector h6.login
 * 
 * name: Click on Menu
 * fires on: Click Element matches CSS selector div.menu-button button
 * fires on: Click Element matches CSS selector .menu-button__content
 * fires on: Click Element matches CSS selector div.menu-button .menu-icon
 * firex on: Click Element matches CSS selector .material-icons.menu-icon
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import Button from '../../material-components/Button';

import { getAppUrl, isDeletedRequestsRoute, isMobileDevice, slugify } from '../../utils/common';
import config from '../../config.json';

import './styles.less'

type Props = {
  onToggleMenu?: Function,
  isLoggedIn: boolean,
  userName?: string,
  isReady: string,
  onLogin: Function,
  onDisplayName: Function,
  titleProps?: Array,
  t: any,
  isOperator: boolean
};

const Header = (props: Props) => {
  const { t, displayName, isOperator, isIOS12, isInsideWidget, isSuggestionsShown, forwardedRef, pathname } = props;
  const isDeletedRequests = isDeletedRequestsRoute(pathname);
  const prepareLocalLink = (externalLink) => externalLink.replace(/^https:\/\/.*.com\//, '');

  const prepareExternalLink = (externalLink) => {
    const appUrl = getAppUrl();
    return externalLink.replace(/^https:\/\/.*.com\//, `${appUrl}/`);
  };

  const getTimeValue = () => {
    if (window.timeLeaveAnswerPage) {
      return new Date().getTime() - new Date(window.timeLeaveAnswerPage).getTime();
    }

    if (window.performance) {
      return Math.round(performance.now());
    }
  };

  const handleTimeMeasure = (e) => {
    if (window.performance) {
      const time = getTimeValue();

      const description = window.timeLeaveAnswerPage
        ? 'Time after User go back from Answer page and Product info button click'
        : 'Time after Load and User click on Product info button';

      document.dispatchEvent(new CustomEvent('time-measure', {
        detail: {
          id: e.target.id,
          pathname: window.location.pathname,
          time,
          description
        }
      }));
    }
  };

  return (
    <div ref={forwardedRef} className={`panel-header conversations-header ${isSuggestionsShown && isMobileDevice() && !isIOS12 ? 'panel-header_disabled' : ''}`}>
      {
        isOperator && <div className={'grey-top'}></div>
      }
      {config.RegistrationEnabled && (props.isLoggedIn ?
        <h6 className={`login ${isOperator ? 'login__operator' : ''}`} onClick={props.onDisplayName}>{displayName}</h6> :
        <h6 data-tr-event className={`login ${isOperator ? 'login__operator' : ''}`} onClick={props.onLogin}>{t('COMMON_MENU_LOGIN')}</h6>)
      }
      <h6 className={`menu ${isOperator ? 'menu__operator' : ''}`} onClick={props.onToggleMenu}>{t('COMMON_MENU_MENU')}</h6>
      <div className={`menu-button ${isOperator ? 'menu-button__operator' : ''}`}>
        {_get(props.themeSettings, 'isMenuVisible', false) && <button id="menu-button" className="menu-button__content" data-tr-event onClick={props.onToggleMenu}><i data-tr-event className="material-icons menu-icon">menu</i></button>}
      </div>
      {isInsideWidget && isMobileDevice() && !isIOS12 &&
        <div className="widget__back-button-wrapper">
          <button id="widget-back-button" data-tr-event className="widget__back-button widget__back-button--hidden" onClick={props.onWidgetGoBackClick}><i className="material-icons">arrow_back</i></button>
        </div>
      }
      {!isInsideWidget &&
        <div className={`title ${isOperator ? 'title__operator' : ''} ${isDeletedRequests ? 'deleted-requests__title' : ''}`}>
          {!props.product && props.titleProps}
          {props.product && (
            <div className="product-title">
              {!isDeletedRequests && <button className="product-title__back-button" onClick={() => props.history.push('/')}><i className="material-icons">arrow_back</i></button>}
              <div className="product-title__content">
                <div className="product-title__name">
                  <h1>{props.productName}</h1>
                  <span>{props.productCompoundComponent}</span>
                </div>
                <div className="product-title__controls_wrapper">
                  {props.product.product_information && (
                    <Button
                      className="product-title__specialist-button"
                      outlined
                      onClick={(e) => {
                        e.preventDefault();
                        handleTimeMeasure(e);
                        props.history.push({
                          pathname: prepareLocalLink(props.product.product_information),
                          state: {
                            detail: { productName: slugify(props.productName) }
                          },
                        });
                      }}
                    >
                      <a
                        rel="canonical"
                        key={`product-information-${props.product.id}`}
                        href={prepareExternalLink(props.product.product_information)}
                      >
                        {t('COMMON_SPECIALIST_INFORMATION')}
                      </a>
                    </Button>)}
                  {props.product.package_leaflet && (
                    <Button
                      className="product-title__open-leaflet"
                      outlined
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push(prepareLocalLink(props.product.package_leaflet));
                      }}
                    >
                      <a
                        rel="canonical"
                        key={`product-leaflet-${props.product.id}`}
                        href={prepareExternalLink(props.product.package_leaflet)}
                      >
                        {t('COMMON_OPEN_LEAFLET')}
                      </a>
                    </Button>)}
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  )
};

Header.defaultProps = {
  onToggleMenu: null,
  imgProps: {},
};

export default withTranslation()(withRouter(Header));