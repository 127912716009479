// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import A from '../../A';

import './styles.less';

type Props = {
  conversationUrl: string,
  phoneNumberOrEmail: string,
  onAcknowledge: Function,
  t: Function,
};

function ShareConfirmationModalFragment({
  conversationUrl,
  phoneNumberOrEmail,
  onAcknowledge,
  t,
}: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center" mb={2}>
        <A href={`${conversationUrl}`} target="_blank">{conversationUrl}</A>
      </Box>
      <Box textAlign="center" mb={2}>
        <Box fontSize="h5.fontSize">{t('MODAL_SENT_LINK_TO')}</Box>
        <Box fontSize="h5.fontSize" className="share-confirmation__phone-email">{phoneNumberOrEmail}</Box>
      </Box>
      <Box textAlign="center">
        <Button
          id="share-confirmation-ok"
          data-keystroke
          outlined
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onAcknowledge(e)
          }}
        >
          OK
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default withTranslation()(ShareConfirmationModalFragment);
