import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

const SaveChangesModalFragment = ({
	onAgree,
	onDismiss,
	t,
}) => (
		<Fragment>
			<Box textAlign="center" mt={2} mb={4}>
				<Box fontSize="h5.fontSize">{t('MODAL_SAVE_CHANGES')}</Box>
			</Box>
			<Box mt={2}>
				<Button outlined style={{ width: '100%' }} onClick={onAgree}>{t('COMMON_YES')}</Button>
			</Box>
			<Box mt={1}>
				<Button id="cancel-external-disclaimer" outlined style={{ width: '100%' }} color="secondary" onClick={onDismiss}>{t('COMMON_NO')}</Button>
			</Box>
		</Fragment>
	);

export default withTranslation()(SaveChangesModalFragment);