import React from 'react';
import QuestionPage from '../components/QuestionPage';
import logout from '../common/logout';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';

export default function QuestionPageContainer(props) {
  // we don't case about the `Promise` here
  return (
    <LayerContext.Consumer>
      {({ userId, isLoggedIn, onLoginAnonymously }) => (
        <CommonContext.Consumer>
          {({ onCurrentActionChange }) => (
            <QuestionPage
              {...props}
              userId={userId}
              isLoggedIn={isLoggedIn}
              onToggleMenu={() => onCurrentActionChange(null)}
              onLogout={() => logout().then(() => onCurrentActionChange(null)).then(onLoginAnonymously)}
            />
          )}
        </CommonContext.Consumer>
      )}
    </LayerContext.Consumer>
  );
}
