const pdf = 'layer-file-application-pdf';
const image = 'layer-file-image-png';
const text = 'layer-file-text-plain';
const sheet = 'layer-file-sheet';
const doc = 'layer-file-document';
const audio = 'layer-file-audio-wav';
const archive = 'layer-file-application-zip';

export const fileCardClass = {
  'pdf': pdf,

  'png': image,
  'jpeg': image,
  'jpg': image,
  'gif': image,
  'svg': image,
  'tiff': image,
  'tif': image,
  'bmp': image,
  'ai': image,
  'ico': image,

  'ps': image,
  'psd': image,


  'plain': text,
  'text': text,
  'txt': text,
  'html': text,

  'ods': sheet,
  'xlr': sheet,
  'xls': sheet,
  'xlsx': sheet,

  'doc': doc,
  'docx': doc,
  'rtf': doc,

  'ogg': audio,
  'mpeg': audio,
  'wav:': audio,
  'aac': audio,
  'mp3': audio,
  'mp4': audio,

  '7z': archive,
  'arj': archive,
  'deb': archive,
  'pkg': archive,
  'rar': archive,
  'rpm': archive,
  'tar': archive,
  'gz': archive,
  'z': archive,
  'zip': archive
}