import * as React from 'react';
import { withTranslation } from 'react-i18next';
import config from '../../config.json';
import _get from 'lodash/get';
import './styles.less';
import { isHcp } from '../../utils/user';

import SearchArea from '../SearchArea';

const BrandHeader = (props) => (
    <div className={`brand-header ${props.className ? props.className : ''}`}>
        <img
            className="xircles-logo"
            alt="Xircle"
            data-tr-event
            id="xircles-logo"
            onClick={props.onLogoClick}
        />
        <div className="brand-header__login">
            {config.RegistrationEnabled && (props.isLoggedIn ?
                <h6 className='only-desktop' onClick={props.onDisplayName}>{props.displayName}</h6> :
                <h6 className='only-desktop' data-tr-event onClick={props.onLogin}>{props.t('COMMON_MENU_LOGIN')}</h6>)
            }
            {!config.RegistrationEnabled && isHcp(props.layerClient.user) && 
                <h6 onClick={props.onDisplayName}>{props.t('LOGOUT_DOCCHECK_TITLE')}</h6>
            }
            {_get(props.themeSettings, 'isMenuVisible', false) && <button id="menu-button" data-tr-event onClick={props.onToggleMenu}><i data-tr-event className="material-icons menu-icon">menu</i></button>}
        </div>
        {props.children}
        <SearchArea {...props} onPostQuestion={props.onPostQuestion} />
    </div>
);

export default withTranslation()(BrandHeader);