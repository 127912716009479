import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import './styles.less';
import dataPrivacyImage from '../../../images/data-privacy.png';

const DataPrivacyFragment = ({
    t,
}) => (
    <Fragment>
        <p className="data-privacy__message">{t('MODAL_DATA_PRIVACY')}</p>
        <img className="data-privacy__image" alt="data-privacy" src={dataPrivacyImage} />
    </Fragment>
);

export default withTranslation()(DataPrivacyFragment);
