import React from 'react';
import AnswerPage from '../components/AnswerPage';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';
import { normalizePathname } from '../utils/common';
import logout from '../common/logout';
import config from '../config.json';

export default function AnswerPageContainer(props) {
  return (
    <LayerContext.Consumer>
      {({ displayName, layerClient, isLoggedIn, userId, onLoginAnonymously }) => (
        <CommonContext.Consumer>
          {({ onCurrentActionChange }) => (
            <AnswerPage
              {...props}
              userId={userId}
              isLoggedIn={isLoggedIn}
              layerClient={layerClient}
              displayName={displayName}
              onLogin={(e) => {
                e.preventDefault();
                props.history.push(normalizePathname(props.history.location.pathname) + '/login');
              }}
              onLogoClick={(e) => {
                e.preventDefault();
                props.history.push('/');
                window.dispatchEvent(new CustomEvent('tr-event', { detail: { target: e.target } }));
              }}
              onDisplayName={(e) => {
                e.preventDefault();
                const redirectPath = config.UserManagementEnabled ? '/my-account' : '/logout';
                props.history.push(normalizePathname(props.history.location.pathname) + redirectPath);
              }}
              onToggleMenu={() => onCurrentActionChange('menu')}
              onLogout={() => logout().then(() => onCurrentActionChange(null)).then(onLoginAnonymously)}
            />
          )}
        </CommonContext.Consumer>
      )}
    </LayerContext.Consumer>
  );
}
