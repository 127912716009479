// @flow
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import Box from '../../material-components/Box';
import Tag from './Tag';
import './style.less';

type Props = {
  suggestion: String,
  onClick: Function,
  preventBubblingEvent: Function
};

const applySuggestionHighlight = suggestion => {
  return suggestion.replace(/<\/?(?!em)\w*\b[^>]*>/gi, ''); // only allow em
};

const handleTitleSuggestion = ({ title, matchedTags }) => {
  const lastIndex = title.lastIndexOf(' ');
  const preparedSuggestion = !_isEmpty(matchedTags)
    ? title.substring(0, lastIndex)
    : title;
  return applySuggestionHighlight(preparedSuggestion);
};

const handleLastWordSuggestion = suggestion => {
  const lastWord = suggestion.split(/[\s,]+/).pop();

  return applySuggestionHighlight(lastWord);
};

function PullDownItem({
  suggestion,
  onClick,
  preventBubblingEvent
}: Props) {
  return (
    <div
      className="pull-down-item"
      onClick={onClick}
      onMouseDown={preventBubblingEvent}
      onMouseUp={preventBubblingEvent}
      onTouchStart={preventBubblingEvent}
      onTouchEnd={preventBubblingEvent}
    >
      <Box my={1} className="pull-down-item__suggestion">
        <div className="pull-down-item__suggestion-content">
          <div
            className="pull-down-item__suggestion-text"
            dangerouslySetInnerHTML={{
              __html:
                typeof suggestion === 'object'
                  ? handleTitleSuggestion(suggestion)
                  : suggestion
            }}
          ></div>
          {suggestion.matchedTags && (
            <div className="pull-down-item__suggestion-tags">
              {handleLastWordSuggestion(suggestion.title)}
              {suggestion.matchedTags.map(tag => (
                <Tag key={tag} title={tag} />
              ))}
            </div>
          )}
        </div>
        <button id="pull-down-item-go" className="pull-down-item__go-button" data-tr-event>
          <i className="xircles-icons icon-select-suggestion" />
        </button>
      </Box>
    </div>
  );
}

export default PullDownItem;

