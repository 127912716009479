// @flow

import React from 'react';
import camelCase from 'lodash/camelCase';
import { Route, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _values from 'lodash/values';
import LoginModal from '../components/Modal/LoginModal';
import {
  sendVerificationCode,
  codeVerify,
  authenticate,
  checkUserStatus,
  authenticateDocCheck,
  upsertPassword,
  updateUser,
  updatePhoneEmail,
  transferConversations
} from '../utils/api';
import conversationManager from '../hocs/conversationManager';
import { getModalWindowRoute, getOriginalPathname, isEmail, isGermanPhoneNumber, isModalWindowRoute, isPhoneNumber, isSecurityCode, isUtf8, isValidPassword} from '../utils/common';
import { executeRecaptcha } from '../common/recaptcha';
import { HTTP_STATUSES } from '../const/http-statuses';
import logout from '../common/logout';

const mapPrevStepByStep = {
  login: null,
  sendCode: 'login',
  resetPassword: 'password',
  createPassword: null,
  loginUnregister: 'login',
  passwordCode: null,
  setPassword: null,
  code: 'sendCode',
  password: 'login',
  passwordSubmit: null,
  welcome: null,
};

type Props = {
  t: Function,
};

type State = {
  pathname: string,
  loginError: any,
  loginState: 'loading' | 'loaded' | 'error',
  credential: any,
  accountErrors: Object,
};

class LoginModalContainer extends React.Component<Props, State> {
  state = {
    accountErrors: {},
    pathname: null,
    loginState: null,
    loginError: null,
    credential: null,
  };

  MAX_STRING_LENGTH = 200;
  isGoBackRequired = false;
  popUpBasePath = undefined;

  triggerCaptchaModalRoutes = [
    '/code',
    '/doccheck',
    '/password',
    '/password-code',
    '/password-submit',
    '/password-confirm'
  ];

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;

    if (pathname !== prevState.pathname) {
      return {
        pathname,
        loginState: null,
        loginError: null,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.history.location.pathname === this.popUpBasePath) {
      this.isGoBackRequired = false;
      this.popUpBasePath = undefined;

      if (this.state.loginType !== undefined) {
        const path = getOriginalPathname(this.props.history.location.pathname);

        this.props.history.push({
          pathname: path + '/login-was-removed',
          state: {
            detail: {
              loginType: this.state.loginType,
            }
          }
        });

        this.setState({ loginType: undefined });
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname && !prevProps.location.pathname.includes(this.props.location.pathname) && isModalWindowRoute(this.props.location.pathname)) {
      const modalRoute = getModalWindowRoute(this.props.location.pathname);

      if (modalRoute) {
        const modalRoutePath = '/' + modalRoute.pop();

        if (this.triggerCaptchaModalRoutes.includes(modalRoutePath)) {
          executeRecaptcha(HTTP_STATUSES.TOO_MANY_REQUESTS.camelCaseMessage);
        }
      }
    }

    if (isModalWindowRoute(this.props.location.pathname) && prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname !== this.popUpBasePath && this.isGoBackRequired && this.popUpBasePath) {
      this.handleGoBack(this.props.history, this.popUpBasePath);
    }

    if (isModalWindowRoute(this.props.location.pathname) && this.props.location.pathname !== this.popUpBasePath && this.props.location.pathname.includes('my-account') && this.isGoBackRequired && this.popUpBasePath) {
      setTimeout(() => this.props.history.replace(this.popUpBasePath), 200);
    }
  }

  onHttpEvent = (apiCall, credentials) => {
    this.setState({ loginState: 'loading', loginError: null, credential: credentials.credential });
    return apiCall(credentials)
      .then(data => {
        this.setState({ loginState: 'loaded' });
        return data;
      })
      .catch(loginError => {
        const errorMessage = _get(loginError, 'response.data.error.id', 'SOMETHING_WENT_WRONG');

        this.setState({
          loginState: 'error',
          loginError: this.props.t('ERROR_' + errorMessage),
        });

        throw loginError;
      });
  };

  validatePhoneNumberOrEmail = (phoneNumberOrEmail) => {
    const isInvalidGermanNumber = isUtf8(phoneNumberOrEmail) && isPhoneNumber(phoneNumberOrEmail) && !isGermanPhoneNumber(phoneNumberOrEmail);
    const isValidPhoneNumberOrEmail = isUtf8(phoneNumberOrEmail) && (isEmail(phoneNumberOrEmail) || isGermanPhoneNumber(phoneNumberOrEmail));
    let errorType;

    if (isInvalidGermanNumber) {
      errorType = 'ERROR_ONLY_AVAILABLE_IN_GERMANY';
    } else {
      errorType = 'ERROR_ENTER_VALID_NUMBER_OR_EMAIL';
    }

    const updatedState = !isValidPhoneNumberOrEmail
      ? { loginState: 'error', loginError: this.props.t(errorType) }
      : { loginError: null };

    this.setState(updatedState);
    return isValidPhoneNumberOrEmail;
  };

  validatePhoneNumber = (phoneNumber) => {
    const isValidPhoneNumber = isUtf8(phoneNumber) && isPhoneNumber(phoneNumber) && isGermanPhoneNumber(phoneNumber);

    if (!isValidPhoneNumber) {
      this.setState({
        accountErrors: {
          ...this.state.accountErrors,
          phoneNumber: false
        }
      });
    }

    return isValidPhoneNumber;
  };

  validateEmail = (email) => {
    const isValidEmail = isUtf8(email) && isEmail(email);

    if (!isValidEmail) {
      this.setState({
        accountErrors: {
          ...this.state.accountErrors,
          emailAddress: false
        }
      });
    }

    return isValidEmail;
  };

  comparePasswords = (password, passwordRepeat) => {
    const arePasswordsEqual = _isEqual(password, passwordRepeat);

    const updatedState = !arePasswordsEqual
      ? { loginState: 'error', loginError: this.props.t('ERROR_PASSWORDS_ARE_NOT_EQUAL') }
      : { loginError: null };

    this.setState(updatedState);
    return arePasswordsEqual;
  };

  validatePassword = (password, modalWindowType = '') => {
    const isPasswordFragment = modalWindowType === 'passwordFragment';
    const isPasswordPassedValidation = isValidPassword(password);

    const errorMessages = {
      [_isEmpty(password)]: 'ERROR_ENTER_PASSWORD',
      [!isPasswordPassedValidation && isPasswordFragment]: 'ERROR_AUTHENTICATION_WRONG_CREDENTIALS',
      [!isPasswordPassedValidation && !isPasswordFragment]: 'ERROR_ENTER_LONGER_PASSWORD',
    };
    const errorMessage = errorMessages[true];

    const updatedState = errorMessage
      ? { loginState: 'error', loginError: this.props.t(errorMessage) }
      : { loginError: null };

    this.setState(updatedState);
    return !errorMessage;
  };

  validateSecurityCode = (securityCode) => {
    const isValidSecurityCode = isUtf8(securityCode) && isSecurityCode(securityCode);

    const updatedState = !isValidSecurityCode
      ? { loginState: 'error', loginError: this.props.t('ERROR_CODE_NOT_VALID') }
      : { loginError: null };

    this.setState(updatedState);
    return isValidSecurityCode;
  };

  validateLogin = (property, oppositeProperty, isEnabled) => {
    if (isEnabled && property.length !== 0) {
      return 'remove-error';
    } else if (isEnabled && property.length === 0 && oppositeProperty.length === 0) {
      return 'empty-error';
    } else {
      return true;
    }
  };

  validateTextField = (property, maxLength) => {
    if (property.length === 0) {
      return 'empty-field-error';
    } else if (property.length > maxLength) {
      return 'max-length-error';
    } else {
      return true;
    }
  }

  validateUser = (user, isEmailChangeEnabled, isPhoneNumberChangeEnabled) => {
    const { city, disease_areas, emailAddress, firstName, institution, lastName, phoneNumber, title, zip_code } = user;

    const accountErrors = {
      title: isUtf8(title) && this.validateTextField(title, this.MAX_STRING_LENGTH),
      disease_areas: disease_areas ? isUtf8(disease_areas) && this.validateTextField(disease_areas, this.MAX_STRING_LENGTH) : true,
      firstName: isUtf8(firstName) && this.validateTextField(firstName, this.MAX_STRING_LENGTH),
      lastName: isUtf8(lastName) && this.validateTextField(lastName, this.MAX_STRING_LENGTH),
      institution: institution ? isUtf8(institution) && this.validateTextField(institution, this.MAX_STRING_LENGTH) : true,
      emailAddress: this.validateLogin(emailAddress, phoneNumber, isEmailChangeEnabled),
      phoneNumber: this.validateLogin(phoneNumber, emailAddress, isPhoneNumberChangeEnabled),
      zip_code: isUtf8(zip_code) && this.validateTextField(zip_code, this.MAX_STRING_LENGTH),
      city: isUtf8(city) && this.validateTextField(city, this.MAX_STRING_LENGTH)
    };

    const isValidUser = _values(accountErrors).some((error) => !error || typeof error === 'string');

    const updatedState = isValidUser
      ? { loginState: 'error', loginError: this.props.t('ERROR_VALIDATION_ERROR'), accountErrors }
      : { loginError: null };

    this.setState(updatedState);
    return !isValidUser;
  };

  handleAccountErrors = (property, value) => {
    if (property === 'emailAddress' || property === 'phoneNumber') {
      this.setState({
        accountErrors: {
          ...this.state.accountErrors,
          [property]: isUtf8(value) && value.length >= 0,
        }
      });
    } else {
      this.setState({
        accountErrors: {
          ...this.state.accountErrors,
          [property]: isUtf8(value) && this.validateTextField(value, this.MAX_STRING_LENGTH),
        }
      });
    }
  };

  // TODO: move into LayerProvider
  connectWithLayerClient = (popUpBasePath, data) => {
    const { layerClient } = this.props;
    const { user_id, session_token, username, password_code, update_code } = data;
    let conversationID = popUpBasePath.match(/questions\/(.{36})/);
    let oldUserID = layerClient.userId;

    const doLogout = (resolve) => {
      logout().then(() => {
        layerClient.connectWithSession(user_id, session_token);
        this.setState({password_code}, () => resolve({ username, password_code }));
      });
    };


    const goToQuestionsPageReframe = () => {
      layerClient.syncManager.once({
        'sync:success': () => {
          this.props.history.push({
            pathname: '/questions',
          });
        },
      });
    };

    const doHandover = (resolve) => {
      transferConversations(oldUserID, user_id, session_token)
        .catch(() => goToQuestionsPageReframe());
      // TODO: find a better way to prevent sending of response messages for outdated component after login
      conversationID && Array.from(document.querySelectorAll('layer-message-item-received')).pop().destroy();
      doLogout(resolve);
    };

    return new Promise((resolve) => {
      if (oldUserID !== user_id) {
        if (layerClient.isReady) {
          doHandover(resolve);
        } else {
          layerClient.once('ready', () => {
            doHandover(resolve);
          });
        }
      } else {
        this.setState({password_code}, () => resolve({ username, password_code, update_code }));
      }
    });
  };

  switchUserMode = () => {
    const { layerClient } = this.props;
    const { credential, password_code } = this.state;
    codeVerify({
      credential,
      verification_code: password_code,
      action: 'Login',
    }).then(({data}) => {
      const {user_id, session_token, password_code} = data;
      logout().then(() => {
        layerClient.connectWithSession(user_id, session_token);
        this.setState({password_code});
      });
    })
  };

  resetError = () => this.setState({ loginError: null });

  resetAccountErrors = () => this.setState({ accountErrors: {} });

  normalizePopUpBasePath = (path) => path.replace(/\/$/, '') || '/';

  closePopUp = () => {
    const modal = document.querySelector('.mdc-dialog');
    modal && modal.classList.remove('mdc-dialog--open');
  };

  handleGoBack = (history, popUpBasePath) => {
    this.popUpBasePath = popUpBasePath;
    this.isGoBackRequired = true;
    this.closePopUp();

    setTimeout(() => {
      history.replace(popUpBasePath);
    }, 100);
  };

  allowedRoutes = [
    '/code',
    '/create-password',
    '/logout',
    '/my-account',
    '/password-code',
    '/password-confirm',
    '/password-submit',
    '/success',
    '/welcome',
  ];

  render() {
    const { isLoggedIn, location } = this.props;

    return (
      <Route path="*" render={({ match, history }) => {
        // TODO: Maybe not smartest approach and some non greedy regex could also do the job
        // using two regexes to cope with matching order ('send-code' vs. 'code')
        const regexMatchA = /^(\/(questions|([a-zA-Z-]*))\/[^\/]*)\/?(\b(send-code|password-code|create-password|password-submit|doccheck-welcome|login-unregister|need-to-login)\b)+?/.exec(match.url); // eslint-disable-line
        const regexMatchB = /^(\/(questions|([a-zA-Z-]*))\/[^\/]*)\/?(\b(login|my-account|logout|code|password|success|welcome|doccheck)\b)+?/.exec(match.url); // eslint-disable-line
        const regexMatchC = /(\/(documents|medikamente)\/.*)\/(\b(code|my-account|create-password|login|login-unregister|password|password-code|password-submit|send-code|success|welcome)\b)+?/.exec(match.url);
        const regexMatchD = /(\/)(\b(send-code|password-code|create-password|password-submit|doccheck-welcome|login-unregister|need-to-login|login|my-account|logout|code|password|success|welcome|doccheck)\b)+?/.exec(
          match.url
        );
        let popUpBasePath = null;
        let currentStep = null;
        if (regexMatchA) {
          popUpBasePath = regexMatchA[1];
          currentStep = camelCase(regexMatchA[2]);
        } else if (regexMatchB) {
          popUpBasePath = regexMatchB[1];
          currentStep = camelCase(regexMatchB[2]);
        } else if (regexMatchC) {
          popUpBasePath = regexMatchC[1];
          currentStep = camelCase(regexMatchC[2]);
        } else if (regexMatchD) {
          popUpBasePath = regexMatchD[1];
          currentStep = camelCase(regexMatchD[2]);
        }

        if (isLoggedIn && !this.allowedRoutes.some((route) => location.pathname.includes(route))) {
          currentStep = undefined;
        }

        const canGoBack = !!mapPrevStepByStep[currentStep];

        const _connectWithLayerClient = ({data}) => this.connectWithLayerClient(popUpBasePath, data);

        return (
          <LoginModal
            {...this.props}
            isOpen={!!currentStep}
            onCodeSend={credentials => this.onHttpEvent(sendVerificationCode, credentials)}
            onCodeVerify={credentials => this.onHttpEvent(codeVerify, credentials).then(_connectWithLayerClient)}
            onUpdatePhoneEmail={credentials => this.onHttpEvent(updatePhoneEmail, credentials)}
            onLogin={credentials => this.onHttpEvent(authenticate, credentials).then(_connectWithLayerClient)}
            onAuthenticate={credentials => this.onHttpEvent(authenticate, credentials)}
            onPasswordCheck={credentials => this.onHttpEvent(authenticate, credentials)}
            onLogout={() => logout(this.props.userId).then(this.props.onLoginAnonymously)}
            onDocCheckLogin={credentials => this.onHttpEvent(authenticateDocCheck, credentials)}
            onUpdateUser={credentials => this.onHttpEvent(updateUser, credentials)}
            onUserCheck={credentials => this.onHttpEvent(checkUserStatus, credentials)}
            onUpsertPassword={credentials => this.onHttpEvent(upsertPassword, credentials)}
            onToggle={(_e, prevLocation, loginType) => {
              this.setState({ accountErrors: {}, loginType });
              if (prevLocation.pathname && prevLocation.pathname.includes('/welcome')) {
                window.dispatchEvent(new CustomEvent('close-welcome-modal'));
              } else {
                this.handleGoBack(history, this.normalizePopUpBasePath(popUpBasePath));
              }
            }}
            onGoBack={canGoBack ? () => history.goBack() : null}
            error={this.state.loginError}
            resetError={this.resetError}
            resetAccountErrors={this.resetAccountErrors}
            loginState={this.state.loginState}
            validatePhoneNumberOrEmail={phoneNumberOrEmail => this.validatePhoneNumberOrEmail(phoneNumberOrEmail)}
            validateEmail={email => this.validateEmail(email)}
            validateUser={(user, isEmailChangeEnabled, isPhoneNumberChangeEnabled) => this.validateUser(user, isEmailChangeEnabled, isPhoneNumberChangeEnabled)}
            accountErrors={this.state.accountErrors}
            onAccountErrors={this.handleAccountErrors}
            validatePhoneNumber={phoneNumber => this.validatePhoneNumber(phoneNumber)}
            comparePasswords={(password, passwordRepeat) => this.comparePasswords(password, passwordRepeat)}
            validatePassword={(password, modalWindowType) => this.validatePassword(password, modalWindowType)}
            validateSecurityCode={securityCode => this.validateSecurityCode(securityCode)}
          />
        );
      }}/>
    );
  }
}

const LoginModalContainerWithConversationManager = conversationManager(LoginModalContainer);
export default withTranslation()(withRouter(LoginModalContainerWithConversationManager));
