export const FILE_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.rtf',
  '.txt',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
];