import Layer from '@layerhq/web-xdk';

import './disclaimer-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;

registerComponent('xircles-disclaimer-message-view', {
	mixins: [MessageViewMixin],
	methods: {
		onAfterCreate() {
			this.innerHTML = `<p>${this.model.text}</p`;
		},
		onRerender() {
			this.innerHTML = `<p>${this.model.text}</p`;
		}
	}
});