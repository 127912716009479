import Layer from '@layerhq/web-xdk';
import i18next from 'i18next';

import {bytesToSize, downloadFileFromRequest, isDocumentsRoute, isStoryblokUrl} from '../utils/common';
import {isHcpOrOperator} from '../utils/user';
import config from '../config.json';
import {fileCardClass} from '../utils/check-extension';
import {fileDownload} from '../utils/api';
import {layerClient} from '../get-layer';

const LayerFileMessageViewMixin = {
  methods: {
    _updateContent: function () {
      this.needHcpCheck = this.model.onlyForHcp && !isHcpOrOperator(Layer.client.user);

      const view = this;
      const downloadText = view.querySelector('.download-text');
      const description = view.properties.model.customData.description;
      const size = view.properties.model.size;
      downloadText.classList.toggle('only-for-hcp', this.needHcpCheck);
      downloadText.innerText = this.needHcpCheck
        ? `${i18next.t('ANSWER_FOR_HCP_ONLY')}`
        : `${(description && description.length) ? description : `${i18next.t('COMMON_DOWNLOAD_TO_OPEN')} ${size && `(${bytesToSize(size)})`}`}`;
    },
    _normalizeText: function(text) {
      return text.replace(new RegExp('<em>|</em>', 'gi'), ''); // eslint-disable-line
    },
    _openHcpCheckModal() {
      window.dispatchEvent(new CustomEvent('change-route', {
        detail: {
            url: window.location.pathname + '/doccheck',
        }
      }));
    },
    onAfterCreate: function () {
      if (this.model.actionEvent === 'open-file') {
        const view = this;
        const { sessionToken: session_token } = layerClient;
        const url = view.model.url ||
          view.model.sourceUrl ||
          (view.model.source && view.model.source.url) ||
          (view.querySelector('[layer-id="link"]') && view.querySelector('[layer-id="link"]').innerText);

        view.messageViewer.removeClickHandler('card-click', view.messageViewer);

        view.messageViewer.addClickHandler('card-click', view.messageViewer, () => {
                this.needHcpCheck = this.model.onlyForHcp && !isHcpOrOperator(Layer.client.user);
                if(this.needHcpCheck) {
                  this._openHcpCheckModal()
                } else if (isDocumentsRoute(url) || isStoryblokUrl(url) || view.model.actionEvent === 'open-url' || view.model.customData.type === 'link_card') {
                  if (url) {
                    dispatchEvent(
                      new CustomEvent('change-route', {
                        detail: {
                          url: view.model.externalDisclaimer ? window.location.pathname + '/external-disclaimer' : url,
                          state: { redirectUrl: url, description: view.model.description },
                        },
                      })
                    );
                  }
                } else {
                  fileDownload(url, session_token)
                  .then((res) => {
                    downloadFileFromRequest(res, view.model.title)
                  });
                }
        });
      }

      const view = this;
      const titleText = view.properties.model.title
      view.messageViewer.querySelector('.layer-card-body-outer').style.display = 'none';
      view.classList.add('layer-message-type-view__operator');

      const fileExtensionPattern = /\.([0-9a-z]+)(?:[?#]|$)/i; // eslint-disable-line
      const fileExtension = view.model.sourceUrl && view.model.sourceUrl.match(fileExtensionPattern)[1];
      fileExtension && view.classList.add(fileCardClass[fileExtension]);

      let dbg = this.model.score;
      if (this.model.matchedTags &&  this.model.matchedTags.length) {
        dbg = this.model.matchedTags.join(', ');
      }

      const title = document.createElement('h3');
      title.classList.add('file-message__title');

      let innerText = this._normalizeText(titleText);

      title.innerHTML = innerText;
      view.appendChild(title);

      this.title = `${config.Debug ? `${dbg}` : ''}`;

      const downloadIcon = document.createElement('i')
      downloadIcon.classList.add('material-icons')
      downloadIcon.innerText = 'cloud_download'
      view.appendChild(downloadIcon)

      const descriptionContainer = document.createElement('div')
      descriptionContainer.classList.add('description-container')


      const downloadText = document.createElement('span')
      downloadText.classList.add('download-text')

      descriptionContainer.appendChild(downloadText)
      view.appendChild(descriptionContainer)

      this._updateContent()

      Layer.client.user.on('identities:change', () => view.onRerender());
    },
    onRerender: function () {
      this._updateContent()
    }
  }
};

export default LayerFileMessageViewMixin;
