// @flow

/**
 *     Creates stateless UI component for Suggestions
 *
 *     mouseDownHandler - callback function for handling onMouseDown (text: string) => {}, NOT required
 *     text             - text that will be rendered and will be argument for mouseDownFunction, NOT required
 */

import * as React from 'react';
import lineClamp from 'line-clamp';
import striptags from 'striptags';

import './styles.less';

type Props = {
    id: number,
    onClick?: Function,
    text: string,
    question: string,
};

class Suggestion extends React.Component {

    constructor(props: Props){
        super(props);
        this.textRef = React.createRef();
    }

    componentDidMount(): void {
        if (this.textRef.current) {
            lineClamp(this.textRef.current, 3);
        }
    }

    handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.question);
    };

    preventBubblingEvent = (e) => e.stopPropagation();

    render(){
        return (
            <li
                onClick={this.handleMouseDown}
                onMouseDown={this.preventBubblingEvent}
                onMouseUp={this.preventBubblingEvent}
                onTouchStart={this.preventBubblingEvent}
                onTouchEnd={this.preventBubblingEvent}
            >
                {this.props.text && <div>
                    <p ref={this.textRef}>{striptags(this.props.text)}</p>
                    <button id={`select-suggestion-${this.props.id}`}>
                        <i className="xircles-icons icon-select-suggestion" />
                    </button>
                </div>}
            </li>
        )
    }
}

Suggestion.defaultProps = {
  text: '',
  question: '',
  onClick: null,
};

export default Suggestion;
