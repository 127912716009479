// @flow

import React from 'react';
import camelCase from 'lodash/camelCase';
import { Route } from 'react-router-dom';
import ScanEFNModal from '../components/Modal/ScanEFNModal';

type Props = {};

type State = {};

export default class ScanEFNModalContainer extends React.Component<Props, State> {
  render() {
    return (
      <Route path="/questions/:conversationId/scan-efn" render={({ match, history }) => {
        const regexMatch = /\/questions\/.{36}\/(scan-efn)/.exec(match.url);
        const currentStep = regexMatch ? camelCase(regexMatch[1]) : null;

        return (
          <ScanEFNModal
            {...this.props}
            isOpen={!!currentStep}
            conversationId={match.params.conversationId}
            onToggle={() => history.push(`/questions/${match.params.conversationId}`)}
          />
        );
      }}/>
    );
  }
}
