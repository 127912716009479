export const ENTER_KEYSTROKE_PATHS = [
  'code',
  'create-password',
  'doccheck',
  'doccheck-welcome',
  'login',
  'login-unregister',
  'logout',
  'password',
  'password-code',
  'password-submit',
  'scan-efn',
  'send-code',
  'share',
  'shared',
  'welcome',
];
