import { layerClient } from '../../../get-layer';
import Core from '@layerhq/web-xdk/core/namespace';
import Root from '@layerhq/web-xdk/core/root';
import ConversationsQuery from '@layerhq/web-xdk/core/queries/conversations-query';

import { QUERY_TYPES } from '../../../const'; 
class XirclesConversationsQuery extends ConversationsQuery {
  queryType = null;
    
  constructor(options) {
    super(options);
    this.queryType = options.type || 0;
  }

  _fetchData(pageSize) {
      const i = setInterval(() => {
          if (this.queryType >= 0) {
              clearInterval(i);
              switch (this.queryType) {
                  case QUERY_TYPES.PUBLIC_QUESTIONS:
                      super._fetchData(`${pageSize}&mode=public`);
                      break;
                  case QUERY_TYPES.PRODUCT_QUESTIONS:
                      {
                        const j = setInterval(() => {
                            if (layerClient.product || layerClient.product === false) {
                                clearInterval(j);
                                super._fetchData(`${pageSize}${layerClient.product ? `&mode=product&product=${layerClient.product.id}` : ''}`);
                            }
                        }, 100);
                        break;
                    }
                  default:
                      super._fetchData(pageSize);
                      break;
              }
          }
      }, 100)
  }
}

Root.initClass.apply(XirclesConversationsQuery, [XirclesConversationsQuery, 'ConversationsQuery', Core.Query]);

export default XirclesConversationsQuery;