import PUBLIC_STATUS from '../../const/status/public-status';

export default (status) => {
  switch (status) {
    case PUBLIC_STATUS.OPEN:
      return ['STATUS_OPEN', 'open'];
    case PUBLIC_STATUS.IN_PROGRESS:
      return ['STATUS_IN_PROGRESS_DASH', 'in-progress'];
    case PUBLIC_STATUS.ANSWER_DELIVERED:
      return ['STATUS_ANSWER_DELIVERED_DASH', 'answer-delivered'];
    case PUBLIC_STATUS.RESOLVED:
      return ['STATUS_SOLVED', 'solved'];
    case PUBLIC_STATUS.CLOSED:
      return ['STATUS_CLOSED', 'closed'];
    case PUBLIC_STATUS.DELETED:
      return ['STATUS_DELETED', 'deleted'];
    default:
      return ['', ''];
      //TODO throw an error
  }
};