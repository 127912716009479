// @flow

import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import { CSSTransition } from 'react-transition-group';

import './styles.less';

type Props = {
  error: string | null,
  errorMessage: string,
  resetError: Function,
  onEnterErrorMessage: Function,
  onExitedErrorMessage: Function,
};

const ErrorMessage = ({
  error,
  errorMessage,
  resetError,
  onEnterErrorMessage,
  onExitedErrorMessage,
}: Props) => (
  <div className={'errorContainer'}>
    <CSSTransition
      in={Boolean(error)}
      key='loginError'
      timeout={200}
      style={{ position: 'relative' }}
      classNames='error-message'
      unmountOnExit
      onEnter={onEnterErrorMessage}
      onExited={onExitedErrorMessage}
    >
      <div className="error">
        <div className="errorText">{errorMessage}</div>
        <MaterialIcon className="closeBtn-error" icon="clear" onClick={resetError} />
      </ div>
    </CSSTransition>
  </div>
);

export default ErrorMessage;