// @flow

/**
 *      Creates stateless UI component for BigButtonBar
 *
 *      svg                 - svg component, NOT required
 *      text                - text on button, NOT required
 *      mouseDownHandler    - onMouseDown callback ()=>{}, NOT required
 *      className           - className for button, NOT required
 */

import * as React from 'react';

type Props = {
	id: string,
	text?: string,
	mouseDownHandler?: Function,
	className?: string,
};

const BigButton = (props: Props) => {
	return (
		<button
			id={props.id}
			data-tr-event
			className={props.className}
			onClick={(e) => {
				e.preventDefault();
				props.mouseDownHandler();
			}}
		>
			{props.text}
		</button>
	)
};

BigButton.defaultProps = {
	text: '',
	mouseDownHandler: null,
	className: '',
};

export default BigButton;
