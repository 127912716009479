// @flow
import React from 'react';
import {hydrate, render} from 'react-dom';
import * as Sentry from '@sentry/browser';
import {BrowserRouter} from 'react-router-dom';
import moment from 'moment';
import deMomentLocalization from 'moment/locale/de'

import './css/index.less';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import mobileFixes from './mobile-fixes';
import './polyfills';
import LayerProvider from './components/LayerProvider';
import {layerClient} from './get-layer';
import CommonProvider from './components/CommonProvider';
import ErrorBoundary from './components/ErrorBoundary';
import './styles/material.scss';
import {I18nextProvider} from 'react-i18next';
import config from './config.json';
import i18n from './i18n';

import initReactFastclick from 'react-fastclick';
import {CookiesProvider} from 'react-cookie';
import {isInsideFrame, isPortraitMode, isReactSnap} from './utils/common';
import TagManager from 'react-gtm-module';

import './utils/inactivity';

if (!isInsideFrame() && config.GtmContainerId.match(/[A-Z0-9]{7}/) && !isReactSnap()) {
  const tagManagerArgs = {
    gtmId: `GTM-${config.GtmContainerId}`
  };

  TagManager.initialize(tagManagerArgs);
}

let isPortait = undefined;
let initialViewport, currentViewport;

const getViewportInfo = () => {
  const w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0];
  const width = w.innerWidth || e.clientWidth || g.clientWidth;
  const height = w.innerHeight || e.clientHeight || g.clientHeight;

  return {width, height, ratio: width / height, revRatio: height / width};
};

const handleOverlay = (value, isIframeTriggered = false) => {
  const minSizeThreshold = 512;
  const maxSizeThreshold = 1200;
  const ratioThreshold = 1.3;

  if (document.getElementsByClassName('landscape-overlay-wrap')[0]) {
    if (isInsideFrame()) {
      if (isIframeTriggered) {
        document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'none';
        return;
      }
    } else {
      if (Math.min(currentViewport.width, currentViewport.height) >= minSizeThreshold || Math.max(currentViewport.width, currentViewport.height) >= maxSizeThreshold) {
        document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'none';
        return;
      }

      if (currentViewport.ratio <= ratioThreshold && currentViewport.revRatio <= ratioThreshold) {
        document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'none';
        return;
      }
    }
  }

  if (!isInsideFrame()) {
    isPortait = currentViewport.ratio <= 1 || isPortraitMode();
    if (document.getElementsByClassName('landscape-overlay-wrap')[0]) {
      if (isPortait || (window.screen.width !== window.outerWidth && window.screen.width !== window.outerHeight)) {
        document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'none';
      } else {
        document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'block';
      }
    }
  }
};

window.addEventListener('load', () => {
  initialViewport = getViewportInfo();
  currentViewport = initialViewport;
  handleOverlay();
});

window.addEventListener('resize', () => {
  currentViewport = getViewportInfo();
  handleOverlay();
});

initReactFastclick();
//reauthenticateLastUser();
const rootEl = document.getElementById('root')
if (!(rootEl instanceof Element)) {
  throw new Error('invalid type')
}

if (config.IsSentryEnabled) {
  Sentry.init({ dsn: config.SentryUrl });
}

const AppWrapper = () => (
  <I18nextProvider i18n={i18n}>
    <CommonProvider>
      <LayerProvider layerClient={layerClient}>
        <BrowserRouter>
          <CookiesProvider>
            <ErrorBoundary>
              <App onOverlay={handleOverlay} />
            </ErrorBoundary>
          </CookiesProvider>
        </BrowserRouter>
      </LayerProvider>
    </CommonProvider>
  </I18nextProvider>
);

if (rootEl.hasChildNodes()) {
  hydrate(<AppWrapper />, rootEl);
} else {
  render(<AppWrapper />, rootEl);
}

if (global.location.protocol === 'https:') registerServiceWorker();
mobileFixes();

// apply moment de-locale strings
moment.updateLocale('de', deMomentLocalization);