import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

const UnavailableContentModalFragment = ({
  buttonTitle,
  onRetry,
  t,
}) => (
  <Fragment>
		<Box textAlign="center" mt={2} mb={4}>
			<Box fontSize="h5.fontSize">{t('MODAL_UNAVAILABLE_CONTENT_MESSAGE')}</Box>
		</Box>
    <Box mt={2}>
      <Button id="unavailable-content-retry" outlined style={{ width: '100%' }} onClick={onRetry}>{t(buttonTitle)}</Button>
    </Box>
  </Fragment>
);

export default withTranslation()(UnavailableContentModalFragment);
