import {
  getAppUrl,
  slugify,
} from '../utils/common';
import { layerClient } from '../get-layer';

const ConversationLastMessageMixin = {
  methods: {
    _createConversationLink({ id: layerItemId, metadata }) {
      const appUrl = getAppUrl();
      const conversationId = layerItemId.replace('layer:///conversations/', '');
      const conversationName = slugify(metadata.conversationName);

      const conversationPath = metadata.isSeo && layerClient.product && metadata.product === layerClient.product.id ? `/medikamente/${slugify(layerClient.product.brand_name)}` : 'questions';
      return `${appUrl}/${conversationPath}/${conversationPath === 'questions' ? conversationId : conversationName}`;
    },
    _updateConversationName() {
      if (this.parentNode) {
        const a = document.createElement('a');
        a.setAttribute('rel', 'canonical');
        a.classList.add('conversation-name');
        a.href = this._createConversationLink(this.item);
        a.innerHTML = this.item.metadata.conversationName;
        this.parentNode.appendChild(a);
      }
    },
    onAfterCreate: function () {
      this._updateConversationName();
    }
  }
};

export default ConversationLastMessageMixin;
