import React from 'react';
import { withRouter } from 'react-router-dom';
import AboutPage from '../components/AboutPage';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';
import { normalizePathname } from '../utils/common';
import logout from '../common/logout';
import config from '../config.json';

const AboutPageContainer = (props) => (
	<LayerContext.Consumer>
		{({ displayName, isLoggedIn, layerClient, onLoginAnonymously }) => (
			<CommonContext.Consumer>
				{({ onCurrentActionChange }) => (
					<AboutPage
						{...props}
						isLoggedIn={isLoggedIn}
						displayName={displayName}
						layerClient={layerClient}
						onLogin={(e) => {
							e.preventDefault();
							props.history.push(normalizePathname(props.history.location.pathname) + '/login');
						}}
						onDisplayName={(e) => {
							e.preventDefault();
							const redirectPath = config.UserManagementEnabled ? '/my-account' : '/logout';
							props.history.push(normalizePathname(props.history.location.pathname) + redirectPath);
						}}
						onToggleMenu={() => onCurrentActionChange('menu')}
						onLogout={() => logout().then(() => onCurrentActionChange(null)).then(onLoginAnonymously)}
					/>
				)}
			</CommonContext.Consumer>
		)}
	</LayerContext.Consumer>
);

export default withRouter(AboutPageContainer);