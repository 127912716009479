import Layer from '@layerhq/web-xdk';
const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesReferenceMessageModel extends MessageTypeModel {
  generateParts(callback) {
    const body = this.initBodyWithMetadata([
      'type',
      'title',
      'url',
      'tags',
      'matchedTags',
      'score',
      'breadcrumbs',
      'externalDisclaimer',
      'onlyForHcp'
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }
}

XirclesReferenceMessageModel.prototype.type = '';
XirclesReferenceMessageModel.prototype.title = '';
XirclesReferenceMessageModel.prototype.url = '';
XirclesReferenceMessageModel.prototype.tags = '';
XirclesReferenceMessageModel.prototype.matchedTags = '';
XirclesReferenceMessageModel.prototype.breadcrumbs = [];
XirclesReferenceMessageModel.prototype.externalDisclaimer = undefined;
XirclesReferenceMessageModel.prototype.onlyForHcp = false;

XirclesReferenceMessageModel.MIMEType = 'application/xircles.reference+json';
XirclesReferenceMessageModel.LabelSingular = 'Reference';
XirclesReferenceMessageModel.messageRenderer = 'xircles-reference-message-view';

Root.initClass.apply(XirclesReferenceMessageModel, [XirclesReferenceMessageModel, 'XirclesReferenceMessageModel']);
Client.registerMessageTypeModelClass(XirclesReferenceMessageModel, 'XirclesReferenceMessageModel');

export default XirclesReferenceMessageModel;
