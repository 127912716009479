import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import './styles.less';
import robotImage from '../../../images/robot.svg';

const TooManyRequestsFragment = ({
  t,
}) => (
  <Fragment>
		<p className="too-many-requests__message">{t('MODAL_TOO_MANY_REQUESTS')}</p>
    <img className="too-many-requests__robot-image" alt="robot" src={robotImage} />
  </Fragment>
);

export default withTranslation()(TooManyRequestsFragment);
