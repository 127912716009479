// @flow

import React from 'react';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';
import { withTranslation } from 'react-i18next';
import MaterialIcon from '@material/react-material-icon';
import Box from '../../../material-components/Box';
import Button from '../../../material-components/Button';
import TextField, { Input } from '../../../material-components/TextField';
import Select, { Option } from '../../../material-components/Select';
import IconButton from '../../../material-components/IconButton';

import './styles.less';

type Props = {
	account: Object,
	layerUser: Object,
	errors: Object,
	isSaveAccountChangesEnabled: Boolean,
	onNotificationsChannelChange: Function,
	isEmailChangeEnabled: Boolean,
	onEnableEmailChange: Function,
	onEmailChange: Function,
	isPhoneNumberChangeEnabled: Boolean,
	onEnablePhoneNumberChange: Function,
	onPhoneNumberChange: Function,
	onAccountChange: Function,
	onUpdateAcccount: Function,
	onResetPassword: Function,
	onLogout: Function,
	onCancel: Function,
	t: Function
};

function MyAccountModalFragment({
	account,
	layerUser,
	errors,
	isSaveAccountChangesEnabled,
	isEmailChangeEnabled,
	onEnableEmailChange,
	onEmailChange,
	isPhoneNumberChangeEnabled,
	onEnablePhoneNumberChange,
	onPhoneNumberChange,
	onAccountChange,
	onUpdateAcccount,
	onResetPassword,
	onLogout,
	onCancel,
	t,
}: Props) {
	const normalizeValue = (value) => _isNull(value) ? '' : value;

	const getClassName = (account, field, isEnabled) => {
		const value = _get(account, field, '');
		return value.length === 0 && isEnabled ? 'disabled-icon' : value.length > 0 && isEnabled ? 'enabled-icon' : '';
	};

	const isValidFieldValue = (field) => (field && typeof field !== 'string');

	const getFieldError = (error, field) => {
		if (error === false) {
			return <p>{t(`ERROR_ENTER_VALID_${field}`)}</p>;
		} else if (error === 'remove-error') {
			return <p>{t(`ERROR_ENTER_EMPTY_${field}`)}</p>;
		} else if (error === 'empty-error') {
			return <p>{t('ERROR_CANT_REMOVE_NOTIFICATION_CHANNEL')}</p>;
		} else if (error === 'empty-field-error') {
			return <p>{t('ERROR_EMPTY_FIELD')}</p>;
		} else if (error === 'max-length-error') {
			return <p>{t('ERROR_MAX_LENGTH')}</p>;
		} else {
			return undefined;
		}
	};

	const isFieldDisabled = isEmailChangeEnabled || isPhoneNumberChangeEnabled;

	return (
		<React.Fragment>
			<form onSubmit={e => e.preventDefault()}>
				<Box mt={1} display="flex" flexWrap="wrap">
					<div className="text-field-with-helper-wrapper">
						<TextField
							outlined
							label={t('LOGIN_MY_ACCOUNT_TITLE')}
							helperText={getFieldError(errors.title, 'TITLE')}
						>
							<Input
								id="title"
								name="title"
								type="text"
								isValid={isValidFieldValue(errors.title)}
								disabled={isFieldDisabled}
								value={_get(account, 'title', '')}
								onChange={(e) => onAccountChange('title', e.target.value)}
							/>
						</TextField>
					</div>
					<div className="text-field-with-helper-wrapper">
						<TextField
							outlined
							label={t('LOGIN_SPECIALTIES')}
							helperText={getFieldError(errors.disease_areas, 'SPECIALTIES')}
						>
							<Input
								id="diseaseAreas"
								name="diseaseAreas"
								type="text"
								isValid={isValidFieldValue(errors.disease_areas)}
								disabled={isFieldDisabled}
								value={normalizeValue(_get(account, 'disease_areas', ''))}
								onChange={(e) => onAccountChange('disease_areas', e.target.value)}
							/>
						</TextField>
					</div>
				</Box>
				<Box mt={2.9} display="flex" flexWrap="wrap">
					<div className="text-field-with-helper-wrapper">
						<TextField
							outlined
							id="text-field-firstName"
							label={t('LOGIN_FIRST_NAME')}
							helperText={getFieldError(errors.firstName, 'FIRST_NAME')}
						>
							<Input
								id="firstName"
								name="firstName"
								type="text"
								isValid={isValidFieldValue(errors.firstName)}
								disabled={isFieldDisabled}
								value={_get(account, 'firstName', '')}
								onChange={(e) => onAccountChange('firstName', e.target.value)}
							/>
						</TextField>
					</div>
					<div className="text-field-with-helper-wrapper">
						<TextField
							outlined
							id="text-field-lastName"
							label={t('LOGIN_FAMILY_NAME')}
							helperText={getFieldError(errors.lastName, 'FAMILY_NAME')}
						>
							<Input
								id="lastName"
								name="lastName"
								type="text"
								isValid={isValidFieldValue(errors.lastName)}
								disabled={isFieldDisabled}
								value={_get(account, 'lastName', '')}
								onChange={(e) => onAccountChange('lastName', e.target.value)}
							/>
						</TextField>
					</div>
				</Box>
				<Box mt={2.9} display="flex" flexWrap="wrap">
					<div className="text-field-with-helper-wrapper">
						<TextField
							outlined
							label={t('LOGIN_INSTITUTION')}
							helperText={getFieldError(errors.institution, 'INSTITUTION')}
						>
							<Input
								id="institution"
								name="institution"
								type="text"
								isValid={isValidFieldValue(errors.institution)}
								disabled={isFieldDisabled}
								value={normalizeValue(_get(account, 'institution', ''))}
								onChange={(e) => onAccountChange('institution', e.target.value)}
							/>
						</TextField>
					</div>
				</Box>
				<Box mt={2.9} display="flex" flexWrap="wrap">
					<div className="text-field-with-helper-wrapper">
						<TextField
							outlined
							id="text-field-zip_code"
							label={t('LOGIN_POSTCODE')}
							helperText={getFieldError(errors.zip_code, 'POSTCODE')}
						>
							<Input
								id="zipCode"
								name="zipCode"
								type="text"
								isValid={isValidFieldValue(errors.zip_code)}
								disabled={isFieldDisabled}
								value={normalizeValue(_get(account, 'zip_code', ''))}
								onChange={(e) => onAccountChange('zip_code', e.target.value)}
							/>
						</TextField>
					</div>
					<div className="text-field-with-helper-wrapper">
						<TextField
							outlined
							id="text-field-city"
							label={t('LOGIN_CITY')}
							helperText={getFieldError(errors.city, 'CITY')}
						>
							<Input
								id="city"
								name="city"
								type="text"
								isValid={isValidFieldValue(errors.city)}
								disabled={isFieldDisabled}
								value={_get(account, 'city', '')}
								onChange={(e) => onAccountChange('city', e.target.value)}
							/>
						</TextField>
					</div>
				</Box>
				<Box mt={5.5} display="flex" flexWrap="wrap">
					<div id="text-field-email" className="text-field-wrapper text-field-with-helper-wrapper">
						<TextField
							outlined
							label={t('LOGIN_EMAIL_PLACEHOLDER')}
							helperText={getFieldError(errors.emailAddress, 'EMAIL')}
							onTrailingIconSelect={onEmailChange}
						>
							<Input
								id="email"
								name="email"
								isValid={isValidFieldValue(errors.emailAddress)}
								value={_get(account, 'emailAddress', '')}
								onChange={(e) => onAccountChange('emailAddress', e.target.value)}
								disabled={!isEmailChangeEnabled}
							/>
						</TextField>
						<IconButton onClick={onEmailChange} disabled={_get(account, 'emailAddress', '').length === 0 && isEmailChangeEnabled}>
							<MaterialIcon className={getClassName(account, 'emailAddress', isEmailChangeEnabled)} />
						</IconButton>
						<span
							className={isFieldDisabled ? 'link--disabled' : ''}
							onClick={(e) => {
								e.preventDefault();
								onEnableEmailChange();
							}}
						>
							{t('LOGIN_CHANGE_EMAIL')}
						</span>
					</div>
					<div id="text-field-phone-number" className="text-field-wrapper text-field-with-helper-wrapper">
						<TextField
							outlined
							label={t('LOGIN_MOBILE_NUMBER')}
							helperText={getFieldError(errors.phoneNumber, 'NUMBER')}
							onTrailingIconSelect={onPhoneNumberChange}
						>
							<Input
								id="phoneNumber"
								name="phoneNumber"
								type="tel"
								isValid={errors.phoneNumber && errors.phoneNumber !== 'remove-error' && errors.phoneNumber !== 'empty-error'}
								value={_get(account, 'phoneNumber', '')}
								onChange={(e) => onAccountChange('phoneNumber', e.target.value)}
								disabled={!isPhoneNumberChangeEnabled}
							/>
						</TextField>
						<IconButton onClick={onPhoneNumberChange} disabled={_get(account, 'phoneNumber', '').length === 0 && isPhoneNumberChangeEnabled}>
							<MaterialIcon className={getClassName(account, 'phoneNumber', isPhoneNumberChangeEnabled)} />
						</IconButton>
						<span
							className={isFieldDisabled ? 'link--disabled' : ''}
							onClick={(e) => {
								e.preventDefault();
								onEnablePhoneNumberChange();
							}}
						>
							{t('LOGIN_CHANGE_MOBILE_NUMBER')}
						</span>
					</div>
				</Box>
				<Box mt={4.5} display="flex" flexWrap="wrap">
					<div className="text-field-wrapper">
						<TextField
							label={t('LOGIN_PASSWORD_PLACEHOLDER')}
							outlined
						>
							<Input
								id="password"
								name="password"
								type="password"
								disabled
							/>
						</TextField>
						<IconButton>
							<MaterialIcon icon="https" />
						</IconButton>
						<span
							className={isFieldDisabled ? 'link--disabled' : ''}
							onClick={(e) => {
								e.preventDefault();
								onResetPassword();
							}}
						>
							{t('LOGIN_RESET_PASSWORD_TITLE')}
						</span>
					</div>
					<div className="select-wrapper">
						<Select
							enhanced
							outlined
							disabled={isFieldDisabled}
							label={t('LOGIN_NOTIFICATIONS_CHANNEL')}
							value={_get(account, 'notification_target')}
						>
							<Option value="phone_number" disabled={!layerUser.phoneNumber}>{t('LOGIN_NOTIFICATIONS_CHANNEL_PHONE_NUMBER')}</Option>
							<Option value="email_address" disabled={!layerUser.emailAddress}>{t('LOGIN_NOTIFICATIONS_CHANNEL_EMAIL')}</Option>
						</Select>
					</div>
				</Box>
				<Box className="controls-wrapper" flexWrap="wrap">
					<Box mt={1}>
						<Button outlined style={{ width: '100%' }} color="secondary" onClick={onCancel}>{t('LOGOUT_CANCEL_BUTTON')}</Button>
					</Box>
					<Box mt={1}>
						<Button outlined disabled={!isSaveAccountChangesEnabled} style={{ width: '100%' }} onClick={onUpdateAcccount}>{t('LOGIN_SAVE_CHANGES')}</Button>
					</Box>
					<Box mt={2}>
						<Button outlined style={{ width: '100%' }} onClick={onLogout}>{t('LOGOUT_BUTTON')}</Button>
					</Box>
				</Box>
			</form>
		</React.Fragment>
	);
}

export default withTranslation()(MyAccountModalFragment);
