import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

const ExternalDisclaimerModalFragment = ({
  onContinue,
  onCancel,
  t,
}) => (
  <Fragment>
    <Box textAlign="center">
      {t('MODAL_EXTERNAL_DISCLAIMER_MESSAGE')}
    </Box>
    <Box mt={2}>
      <Button id="continue-external-disclaimer" outlined style={{ width: '100%' }} onClick={onContinue}>{t('COMMON_CONTINUE')}</Button>
    </Box>
    <Box mt={1}>
      <Button id="cancel-external-disclaimer" outlined style={{ width: '100%' }} color="secondary" onClick={onCancel}>{t('COMMON_CANCEL')}</Button>
    </Box>  
  </Fragment>
);

export default withTranslation()(ExternalDisclaimerModalFragment);
