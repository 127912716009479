import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../../material-components/Button';

import './styles.less';

const PrivacyNoticeFragment = ({
    t,
    onRetry,
}) => (
    <Fragment>
        <p className="privacy-notice__message">{t('MODAL_PRIVACY_NOTICE_1')}</p>
        <p className="privacy-notice__message">{t('MODAL_PRIVACY_NOTICE_2')}</p>
        <p className="privacy-notice__message">
            {t('MODAL_PRIVACY_NOTICE_3')}
            <span>{` ${t('MODAL_PRIVACY_NOTICE_4')}`}</span>
        </p>
        <p className="privacy-notice__message">{t('MODAL_PRIVACY_NOTICE_5')}</p>
        <Button outlined onClick={onRetry}>
          {t('COMMON_CLOSE')}
        </Button>
    </Fragment>
);

export default withTranslation()(PrivacyNoticeFragment);
