import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _get from 'lodash/get';
import './styles.less';

import AboutCard from '../AboutCard';
import BrandHeader from '../BrandHeader';
import BrandFooter from '../BrandFooter';
import MedicationCard from '../MedicationCard';
import MenuContainer from '../../containers/MenuContainer';
import conversationManager from '../../hocs/conversationManager';

class AboutPage extends Component<Props, State> {

    t = this.props.t;

    componentDidUpdate(prevProps) {
        if (this.props.newConversationId !== prevProps.newConversationId) {
            this.props.history.push({
                pathname: `/questions/${this.props.newConversationId}`,
                state: {
                    detail: {
                        corrections: this.props.corrections,
                        originalText: this.props.originalText,
                        prevLocation: this.props.history.location.pathname
                    }
                }
            });
        }
    }

    handleLogoClick = () => this.props.history.push('/');

    handlePostQuestion = (question, corrections, originalText) => {
        document.dispatchEvent(new CustomEvent('tr-custom-event', {
            detail: {
                question,
                name: 'search-submit',
                location: window.location.href,
                conversationId: '-',
                elementId: '-',
                suggestion: '-',
                drug: '-',
                text: '-',
                id: '-',
            }
        }));
        this.props.createConversation(question, { goBackPath: this.props.history.location.pathname, stepBackNumber: -2 }, corrections, originalText);
    };

    render() {
        return (
            <div className="about-page">
                <BrandHeader
                    {...this.props}
                    className="about-page__header"
                    onPostQuestion={this.handlePostQuestion}
                    onLogoClick={this.handleLogoClick}
                >
                    <h1>{this.t('ABOUT_TITLE')}</h1>
                </BrandHeader>
                <div className="about-page__content">
                    <h2>{this.t('ABOUT_SUBTITLE')}</h2>
                    <p className="about-page__content-paragraph">{this.t('ABOUT_PRODUCT_PURPOSE')}</p>
                    <div className="about-page__about-cards">
                        <AboutCard
                            title={this.t('ABOUT_CARD_TITLE_1')}
                            content={this.t('ABOUT_CARD_CONTENT_1')}
                            listTitle={this.t('ABOUT_CARD_LIST_TITLE_1')}
                            listItems={this.t('ABOUT_CARD_LIST_ITEMS_1')}
                            additionalContent={this.t('ABOUT_CARD_ADDITIONAL_CONTENT_1')}
                        />
                        <AboutCard
                            isReversed
                            title={this.t('ABOUT_CARD_TITLE_2')}
                            content={this.t('ABOUT_CARD_CONTENT_2')}
                            additionalContent={this.t('ABOUT_CARD_ADDITIONAL_CONTENT_2')}
                        />
                        <AboutCard
                            title={this.t('ABOUT_CARD_TITLE_3')}
                            content={this.t('ABOUT_CARD_CONTENT_3')}
                            additionalContent={this.t('ABOUT_CARD_ADDITIONAL_CONTENT_3')}
                        />
                    </div>
                    <div className="about-page__medication-cards">
                        {[...Array(3).keys()].map((key) => (
                            <MedicationCard
                                key={`medication-card-${key}`}
                                title={this.t(`ABOUT_MEDICATION_CARD_TITLE_${key + 1}`)}
                                link={this.t(`ABOUT_MEDICATION_CARD_LINK_${key + 1}`, { origin: window.location.origin })}
                            />
                        ))}
                    </div>
                    <div className="about-page__image-wrapper">
                        <img alt="ai" className="about-page__image" />
                        <span className="about-page__image-caption">{this.t('ABOUT_AI_IMAGE_CAPTION')}</span>
                    </div>
                </div>
                {_get(this.props.themeSettings, 'isMenuVisible', false) && <MenuContainer />}
                {_get(this.props.themeSettings, 'isFooterVisible', false) && <BrandFooter />}
            </div>
        )
    }
}

const AboutPageWithConversationManager = conversationManager(AboutPage);

export default withTranslation()(AboutPageWithConversationManager);