/* eslint-disable no-unused-vars */
import i18next from 'i18next';
import Layer from '@layerhq/web-xdk';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _set from 'lodash/set';
import moment from 'moment';

import './result-message-model';
import { isImageThresholdPassed, isReactSnap, prepareProductUrl, slugify } from '../../utils/common';
import config from '../../config.json';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;

const MAX_SNIPPET_LENGTH = 580;
const MAX_SNIPPET_LENGTH_FTS = 280;

const SOURCE_HEADLINE_MATCHING = 'headline-matching';
const SOURCE_FULL_TEXT_SEARCH = 'full-text-search';

const TABLE_TITLE_DETECTION = {
	// text or <b> nodes only
	titleNodes: ['#text', 'B'],
	maxDeepLength: 2
};

let highlightedLists = {};

registerComponent('xircles-blocks-result-message-view', {
	mixins: [MessageViewMixin],
	style: `
		xircles-results-message-view xircles-blocks-result-message-view.layer-message-type-view {
			padding: 0 !important;
			border: unset !important;
			background-color: unset !important;
		}

		.block-result-message {
			border-top: 1px solid #ccced3;
			position: relative;
			cursor: pointer;
			max-width: 640px;
			margin: 0 auto;
		}

		.block-result-message img {
			max-width: 100%;
		}

		.block-result-message--closed > .block-result-message__snippet, .block-result-message--closed > .block-result-message__breadcrumbs {
			display: none;
		}

		.block-result-message--closed > .block-result-message__toggle-button i {
			transform: none;
		}

		.block-result-message--formatted {
			padding-bottom: 15px;
		}

		.block-result-message__title {
			min-height: 48px;
			display: flex;
			align-items: center;
			font-weight: bold;
		}

		.block-result-message__title > * {
			font-family: Roboto;
  			font-size: 16px;
  			font-weight: bold;
  			font-stretch: normal;
  			font-style: normal;
  			line-height: 1.31;
  			letter-spacing: normal;

			padding: 14px 35px 14px 0;
			margin: 0;
		}

		.block-result-message__title > em {
			padding-right: 5px;
			top: 1px;
			position: relative;
		}

		.block-result-message__title > em:not(:first-of-type) {
			padding-left: 5px;
		}

		.block-result-message__toggle-button {
			position: absolute;
			top: 10px;
			right: 0px;

			cursor: pointer;
		}

		.block-result-message__toggle-button i {
			color: #494d5b;
			transform: rotate(180deg);
		}

		.block-result-message__snippet {
			margin: 0 32px 0 0;
			line-height: 21px;
			font-size: 16px;
		}

		.block-result-message__snippet > * {
			line-height: 21px;
			font-size: 16px;
			margin: 0;
		}

		.block-result-message__child-blocks .block-result-message:not(.block-result-message--closed) .block-result-message__snippet + .block-result-message__collapse-item-label {
			margin-top: 10px;
		}

		.block-result-message__child-blocks {
			margin-left: 15px;
		}

		.block-result-message__child-blocks--hidden {
			display: none;
		}

		.block-result-message__breadcrumbs {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			margin: 11px 0 15px -10px;
			padding: 0;
		}

		.block-result-message__breadcrumbs li {
			list-style: none;
			margin: 0;
			padding: 0 0 0 10px;

			display: flex;
    		flex-direction: row;
    		align-items: center;
		}

		.block-result-message__breadcrumbs li > * {
			margin: 0;
			max-width: 188px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.block-result-message__breadcrumbs li, .block-result-message__breadcrumbs li h3, .block-result-message__breadcrumbs li h4, .block-result-message__breadcrumbs li h2, .block-result-message__breadcrumbs li h5 {
			font-family: Roboto;
			font-size: 11px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.block-result-message__breadcrumbs li:before {
			padding: 0 5px 0 5px;
			color: #494d5b;
		}

		.block-result-message__table {
			margin: 10px 0;
			border-color: #1f2022;
		}

		.block-result-message__table td {
			padding: 5px;
		}

		.block-result-message__table tr:first-of-type td b, .block-result-message__table tr:first-of-type td {
			font-weight: bold !important;
			font-size: 16px !important;
		}

		.block-result-message__table tr:not(:first-of-type) td b, .block-result-message__table tr:not(:first-of-type) td {
			font-size: 16px !important;
			font-weight: 300 !important;
		}

		.block-result-message__table tr:not(:first-of-type) td:first-of-type b, .block-result-message__table tr:not(:first-of-type) td:first-of-type {
			font-style: italic !important;
		}

		.block-result-message__collapse-table-title {
			font-weight: bold;
			margin: 0;
		}

		.block-result-message__collapse-table-title--collapsed, .block-result-message__table--collapsed {
			display: none;
		}

		.block-result-message__collapse-item-label {
			user-select: none;
			box-sizing: border-box;

			width: 48px;
			height: 18px;

			margin-top: 10px;
			margin-bottom: 10px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			padding: 3px 6px 2px;
			border-radius: 3px;
			color: #ffffff;

			font-family: Roboto;
			font-size: 11px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		br + .block-result-message__show-more {
			margin-left: 0;
		}

		.block-result-message__collapse-item-label + .block-result-message__collapse-table-title {
			margin: 10px 0 0 0;
		}

		.block-result-message__table .scroll-table-right {
			display: none;
			position: absolute;
            cursor: pointer;

            bottom: 17px;
            right: 0px;

			width: 24px;
			height: 24px;
			border-radius: 3px;

			color: white;
		}

		.block-result-message__table .scroll-table-right--visible {
			display: block;
		}

		.scroll-table-right.scroll-table--hide {
            display: none;
        }

		.flex-table-wrapper {
			position: relative;
		}

		.flex-table-content {
			overflow-x: auto;
		}
	`,
	methods: {
		goToUrl(url, isBreadcrumbUrl = false, matchedHighlightedListItem) {
			window.dispatchEvent(new CustomEvent('change-route', {
				detail: {
					url,
					state: {
						isBreadcrumbUrl,
						listItemContent: matchedHighlightedListItem
							? matchedHighlightedListItem.innerHTML
							: undefined,
					}
				}
			}));
		},
		isQuestion(block) {
			return block.type.includes('questions-novartis');
		},
		isBreadcrumb(element) {
			return element.classList.contains('block-result-message__breadcrumb') || element.parentNode.classList.contains('block-result-message__breadcrumb');
		},
		isSnippet(element) {
			if (element.tagName === 'EM' || element.tagName === 'LI' || element.classList.length === 0) {
				return this.isSnippet(element.parentElement);
			}
			return element.classList.contains('block-result-message__snippet');
		},
		isShowMore(element) {
			return element.classList.contains('block-result-message__show-more');
		},
		isCollapseItemLabel(element) {
			return element.classList.contains('block-result-message__collapse-item-label');
		},
		isCollapseTableTitle(element) {
			return element.classList.contains('block-result-message__collapse-table-title');
		},
		isCollapseTable(element) {
			return element.classList.contains('block-result-message__table');
		},
		isChildBlocks(element) {
			return element.classList.contains('block-result-message__child-blocks');
		},
		filterBreadcrumbs(breadcrumbs = []) {
			// First filter out those titles we don't want
			let filtered = breadcrumbs.filter((brc) => brc.title && this.isBreadcrumbTitleValid(brc.title));
			// If they're still deeper than 2nd level (e.g. 4.2 Dosierung), add only last one after it
			return filtered.length > 2 ? filtered.slice(0, 2).concat(filtered.slice(-1)) : filtered;
			// return breadcrumbs.length > 1 ?
			// 	breadcrumbs
			// 	.filter((breadcrumb) => breadcrumb.title && this.isBreadcrumbTitleValid(breadcrumb.title))
			// 	.slice(0, 2) :
			// 	breadcrumbs;
		},
		getTitleContent(title) {
			const paragraphNumberMatch = new RegExp(/>([0-9]\.?[0-9]? )/).exec(title);

			title = title.replace('<br>', '');

			if (paragraphNumberMatch) {
				const [_paragraphNumberMatch, paragraphNumberContent] = paragraphNumberMatch;
				return title.replace(paragraphNumberContent, '');
			}

			return title;
		},
		getSnippetContent(snippet) {
			try {
				const [_snippetMatch, snippetContent] = new RegExp(/<p>(.*?)<\/p>/g).exec(snippet) || new RegExp(/<p>(.*?\.\.\.)/g).exec(snippet);
				return snippetContent;
			} catch (e) {
				return snippet;
			}
		},
		normalizeSnippetContent(snippetContent) {
			const MIN_MATCH_OFFSET = 30;
			const maxlength = this.model.customData.source === SOURCE_FULL_TEXT_SEARCH 
				? MAX_SNIPPET_LENGTH_FTS 
				: MAX_SNIPPET_LENGTH;
			const imgMatches = snippetContent.match(/<img.*?>/g);
			const noImgSnippetContent = snippetContent

				.replace(/<img.*?>/g, '<>')
				.replace(/ class="hlt1"/g, '')
				.replace(/^<br><br>/g, '');
			const isShowMore = noImgSnippetContent.length > maxlength;
			let snippetContentWithMaxLength = '';

			if (isShowMore) { // only adjust highlight position when content is too long
				const startMatch = noImgSnippetContent.match('<em');
				const endMatch = noImgSnippetContent.match('</em');
				if (startMatch) { // highlight present
					let start = startMatch.index;
					const end = endMatch.index;
					let length = end - start;
					if (length < maxlength) { // highlight short, so center it
						start -= (maxlength - length) / 2;
					}
					if (start < MIN_MATCH_OFFSET) { // don't '...' to short offsets 
						start = 0;
					}
					if (length < maxlength) { // show full highlight if longer then snippet length
						length = maxlength;
					} else {
						length += MIN_MATCH_OFFSET; // little offset at the end
					}
					snippetContentWithMaxLength = noImgSnippetContent.substr(start, length);
					if (start > 0) {
						snippetContentWithMaxLength = ' ... '.concat(snippetContentWithMaxLength);
					}
				} else {
					snippetContentWithMaxLength = noImgSnippetContent.substr(0, maxlength);
				}
			} else {
				snippetContentWithMaxLength = noImgSnippetContent.substr(0, maxlength);
			}

			let normalizedSnippetContent = isShowMore
				? snippetContentWithMaxLength.concat(' ... ')
				: snippetContentWithMaxLength;
			const imgPlaceholderMatches = normalizedSnippetContent.match(/<>/g);

			for (let i = 0; imgPlaceholderMatches && i < imgPlaceholderMatches.length; i++) {
				normalizedSnippetContent = normalizedSnippetContent.replace(/<>/, imgMatches[i]);
			}

			return {
				snippetContent: normalizedSnippetContent,
				isShowMore,
			};
		},
		prepareBreadcrumbTitle(title) {
			return title.includes('</h') ? title : `<span>${title}</span>`;
		},
		isBreadcrumbTitleValid(title = '') {
			// This filters out empty or first level block ones (e.g. 4. Klinische Angabem, etc.)
			return title && !title.match(/\b(\d\.){1}\s+/);
		},
		createChildBlocksWrapper() {
			const childsWrapper = document.createElement('div');
			childsWrapper.classList.add('block-result-message__child-blocks');

			return childsWrapper;
		},
		findTableTitleNode(table, deep = 0) {
			const { previousSibling } = table;
			const { nodeName } = previousSibling;

			if (TABLE_TITLE_DETECTION.titleNodes.includes(nodeName)) {
				return previousSibling;
			}

			return deep < TABLE_TITLE_DETECTION.maxDeepLength && this.findTableTitleNode(previousSibling, deep + 1);
		},
		addLabelToItem( newBlock, index, item ) {
			const collapseItemLabel = document.createElement('span');
			collapseItemLabel.classList.add('block-result-message__collapse-item-label');
			if (item === 'IMAGE') {
				collapseItemLabel.style.width = '60px'
			}
			collapseItemLabel.innerHTML = `${i18next.t(`COMMON_${item}`)}`;
			collapseItemLabel.setAttribute('data-item-id', `data-item-id-${item}-${index}`);
			newBlock.appendChild(collapseItemLabel);
		},
		addCollapseModeToItem(item, index, newBlock) {
			const itemName = item.tagName === 'IMG' ? 'IMAGE' : 'TABLE';
			item.classList.add('block-result-message__table', 'block-result-message__table--collapsed');
			item.setAttribute('data-item-id', `data-item-id-${itemName}-${index}`);

			if (itemName === 'IMAGE') {
				this.addLabelToItem( newBlock, index, itemName);
				newBlock.appendChild(item);

				return
			}
			
			this.addLabelToItem(newBlock, index, itemName);

			const tableTitleContent = this.findTableTitleNode(item);

			if (tableTitleContent) {
				const collapseTableTitle = document.createElement('p');

				collapseTableTitle.classList.add('block-result-message__collapse-table-title', 'block-result-message__collapse-table-title--collapsed');
				collapseTableTitle.innerText = tableTitleContent.textContent;
				collapseTableTitle.setAttribute('data-item-id', `data-item-id-${index}`);

				tableTitleContent.parentNode.removeChild(tableTitleContent);
				newBlock.appendChild(collapseTableTitle);
			}

			const collapseTableContent = item.parentNode.removeChild(item);
			newBlock.appendChild(collapseTableContent);
		},
		toggleScrollControl(action, direction, tableIndex, className) {
			const scrollControl = document.getElementById(`scroll-table-${direction}-${tableIndex}`);
			return scrollControl && scrollControl.classList[action](className);
		},
		sideScroll(element, maxScrollLeft, tableIndex, speed, distance, step) {
			let scrollAmount = 0;
			const slideTimer = setInterval(() => {
				element.scrollLeft += step;
				this.toggleScrollControl('remove', 'right', tableIndex, 'scroll-table--hide');

				if (element.scrollLeft >= maxScrollLeft) {
					element.scrollLeft = maxScrollLeft;
					this.toggleScrollControl('add', 'right', tableIndex, 'scroll-table--hide');
				}

				scrollAmount += step;

				if (scrollAmount >= distance) {
					window.clearInterval(slideTimer);
				}
			}, speed);
		},
		handleScrollRightClick(e) {
			const splittedId = e.target.id.split('-');
			const tableIndex = splittedId.pop();

			const scrollTable = document.getElementById(`flex-table-${tableIndex}`);

			const [headerRow] = scrollTable.getElementsByClassName('flex-row h').length
				? scrollTable.getElementsByClassName('flex-row h')
				: scrollTable.getElementsByTagName('tr');

			const headerRowWidth = headerRow.innerWidth || headerRow.clientWidth;
			const maxScrollLeft = headerRowWidth - scrollTable.parentElement.clientWidth;

			this.sideScroll(scrollTable.parentElement, maxScrollLeft, tableIndex, 25, 100, 10);
		},
		handleTableScroll(e) {
			const splittedId = e.target.id.split('-');
			const tableIndex = splittedId.pop();

			const scrollTable = document.getElementById(`flex-table-${tableIndex}`);
			const maxScrollLeft = scrollTable.scrollWidth - scrollTable.clientWidth;

			if (scrollTable.scrollLeft >= maxScrollLeft) {
				this.toggleScrollControl('add', 'right', tableIndex, 'scroll-table--hide');
			} else {
				this.toggleScrollControl('remove', 'right', tableIndex, 'scroll-table--hide');
			}
		},
		addHorizontalScrollTables(tables) {
			Array
				.from(tables)
				.forEach((table, index) => {
					table.id = `flex-table-${index}`;
					table.classList.add('flex-table');

					const wrapper = document.createElement('div');
					wrapper.classList.add('flex-table-wrapper');

					const tableContent = document.createElement('div');
					tableContent.classList.add('flex-table-content');

					table.parentNode.insertBefore(wrapper, table);
					tableContent.appendChild(table);
					wrapper.appendChild(tableContent);

					const scrollRightControl = document.createElement('span');
					scrollRightControl.classList.add('scroll-table-right');
					scrollRightControl.setAttribute('id', `scroll-table-right-${index}`);
					scrollRightControl.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', (e) => this.handleScrollRightClick(e));

					const arrowIcon = document.createElement('i');
					arrowIcon.classList.add('material-icons');
					arrowIcon.innerHTML = 'keyboard_arrow_right';
					arrowIcon.setAttribute('id', `scroll-arrow-icon-right-${index}`);
					scrollRightControl.appendChild(arrowIcon);

					table.addEventListener('scroll', this.handleTableScroll, { passive: true });
					table.appendChild(scrollRightControl);
				});
		},
		addImages(images) {
			Array
				.from(images)
				.forEach((image, index) => {
					image.id = `image-${index}`;

					const wrapper = document.createElement('div');
					wrapper.classList.add('flex-image-wrapper');
					image.parentNode.insertBefore(wrapper, image);
					wrapper.appendChild(image);
				});
		},
		updateScrollTableVisibility(table) {
			if (table) {
				const tableWidth = table.innerWidth || table.clientWidth;

				const [headerRow] = table.getElementsByClassName('flex-row h').length
					? table.getElementsByClassName('flex-row h')
					: table.getElementsByTagName('tr');

				const headerRowWidth = headerRow.innerWidth || headerRow.clientWidth;

				if (tableWidth > headerRowWidth + 5) {
					const [scrollRight] = table.getElementsByClassName('scroll-table-right');
					scrollRight && scrollRight.classList.add('scroll-table-right--visible');
				}
			} else {
				const tables = document.getElementsByClassName('flex-table');

				Array
					.from(tables)
					.forEach((table, index) => {
						const tableWidth = table.innerWidth || table.clientWidth;

						const [headerRow] = table.getElementsByClassName('flex-row h').length
							? table.getElementsByClassName('flex-row h')
							: table.getElementsByTagName('tr');

						const headerRowWidth = headerRow.innerWidth || headerRow.clientWidth;

						if (tableWidth > headerRowWidth) {
							this.toggleScrollControl('add', 'right', index, 'scroll-table--hide');
						} else {
							this.toggleScrollControl('remove', 'right', index, 'scroll-table--hide');
						}
					});
			}
		},
		createBlockResultMessage(block, isRoot = false, snippetIndex, rootBlock) {
			if (block.text && this.model.customData.source === SOURCE_FULL_TEXT_SEARCH) {
				// re-compute snippet now
				const withoutHighlight = block.snippet.replace(/<em class="hlt1">/g, '').replace(/<\/em>/g, '');
				block.snippet = block.text.replace(withoutHighlight, block.snippet);
			}

			const newBlock = document.createElement('div');
			newBlock.classList.add('block-result-message');

			const dataPath = `/documents/${block._uid ? `${block.full_slug}#${block._uid}` : block.url}`;
			newBlock.setAttribute('data-path', dataPath);

			if (!isRoot) {
				newBlock.classList.add('block-result-message--closed');
				if (block.parent._uid !== rootBlock._uid) {
					newBlock.classList.add('block-result-message--marked');
				}
			}

			const title = this.createTitleBlockResultMessage(block.title);
			newBlock.appendChild(title);

			const snippet = this.createSnippetBlockResultMessage(block);
			const highlightedContent = this.getSnippetListHighlightedContent(snippet);

			if (highlightedContent.length > 0) {
				highlightedContent.forEach((content) => _set(highlightedLists, dataPath, content));
			}

			newBlock.appendChild(snippet);

			const tables = snippet.getElementsByTagName('table');
			const imagesFromSnippet = snippet.getElementsByTagName('img'); 

			if (imagesFromSnippet) {
				Array.from(imagesFromSnippet).forEach((image, index) => {
					if (isImageThresholdPassed(image)) {
						this.addCollapseModeToItem(image, `${snippetIndex}-${index}`, newBlock);
					}
				});

				if (!isReactSnap()) {
					this.addImages(imagesFromSnippet);
				}
			}
		
			if (tables) {
				const originalTables = [...tables];

				Array
					.from(tables)
					.forEach((table, index) => this.addCollapseModeToItem(table, `${snippetIndex}-${index}`, newBlock));

				if (!isReactSnap()) {
					this.addHorizontalScrollTables(originalTables);
				}
			}

			const { isShowMore, snippetContent: normalizedSnippetContent } = this.normalizeSnippetContent(snippet.innerHTML);
			snippet.innerHTML = normalizedSnippetContent;

			if (normalizedSnippetContent.length > 0) {
				const toggleButton = this.createToggleButtonBlockResultMessage();
				newBlock.appendChild(toggleButton);
			}

			if (isShowMore) {
				const showMore = document.createElement('span');
				showMore.classList.add('block-result-message__show-more');
				showMore.innerHTML = `${i18next.t('COMMON_MORE_DETAILS')} »`;
				snippet.appendChild(showMore);
			}

			if (!snippet.hasChildNodes()) {
				snippet.parentNode.removeChild(snippet);
			}

			if (normalizedSnippetContent.length > 0) {
				const blockBreadcrumbs = this.filterBreadcrumbs((block.parent || block).breadcrumbs)
				if (blockBreadcrumbs.length > 0) {
					const breadcrumbs = this.createBreadcrumbsResultMessage(block, blockBreadcrumbs);
					newBlock.appendChild(breadcrumbs);
				} else if (!block.parent) {
					newBlock.classList.add('block-result-message--formatted');
				}
			}
			return newBlock;
		},
		createTitleBlockResultMessage(blockTitle) {
			const title = document.createElement('div');
			title.classList.add('block-result-message__title');
			title.innerHTML = this.getTitleContent(blockTitle);
			return title;
		},
		createToggleButtonBlockResultMessage() {
			const toggleButton = document.createElement('button');
			toggleButton.classList.add('block-result-message__toggle-button');

			const buttonIcon = document.createElement('i');
			buttonIcon.classList.add('material-icons');
			buttonIcon.innerText = 'keyboard_arrow_down';

			toggleButton.appendChild(buttonIcon);
			return toggleButton;
		},
		createSnippetBlockResultMessage(block) {
			const snippet = document.createElement('p');
			snippet.classList.add('block-result-message__snippet');

			const snippetContent = this.getSnippetContent(block.snippet);
			snippet.innerHTML = snippetContent;
			return snippet;
		},
		getSnippetListHighlightedContent(snippet) {
			const highlightedContent = snippet.getElementsByTagName('em');

			return Array
				.from(highlightedContent)
				.filter((content) => content.parentElement.localName === 'li');
		},
		createBreadcrumbsResultMessage(block, blockBreadcrumbs) {
			const breadcrumbs = document.createElement('ul');
			breadcrumbs.classList.add('block-result-message__breadcrumbs');

			const technicalInformation = document.createElement('li');
			technicalInformation.classList.add('block-result-message__breadcrumb');
			technicalInformation.innerHTML = i18next.t('COMMON_TECHNICAL_INFORMATION');
			technicalInformation.setAttribute('data-path', `/documents/${block.full_slug}`);
			if (block.datetextValidFrom) {
				const dateText = moment(block.datetextValidFrom).locale(config.Language).format('MMMM YYYY');
				technicalInformation.innerHTML += ` (${dateText})`;
			}
			breadcrumbs.appendChild(technicalInformation);

			blockBreadcrumbs.forEach((blockBreadcrumb) => {
				const breadcrumb = document.createElement('li');
				breadcrumb.classList.add('block-result-message__breadcrumb')
				breadcrumb.innerHTML = this.prepareBreadcrumbTitle(blockBreadcrumb.title);
				const breadcrumbDataPath = blockBreadcrumb.id ? `/documents/${block.full_slug}#${blockBreadcrumb.id}` : `/documents/${blockBreadcrumb.url}`;
				breadcrumb.setAttribute('data-path', breadcrumbDataPath);
				breadcrumbs.appendChild(breadcrumb);
			});

			return breadcrumbs;
		},
		createIsolatedBlockResultMessage(block) {
			if (block.text && this.model.customData.source === SOURCE_FULL_TEXT_SEARCH) {
				// re-compute snippet now
				const withoutHighlight = block.snippet.replace(/<em class="hlt1">/g, '').replace(/<\/em>/g, '');
				block.snippet = block.text.replace(withoutHighlight, block.snippet);
			}

			const newBlock = document.createElement('div');
			newBlock.classList.add('block-result-message');
			newBlock.setAttribute('data-path', `/documents/${block.url}`);
		
			const title = this.createTitleBlockResultMessage(block.title);
			newBlock.appendChild(title);

			const snippet = this.createSnippetBlockResultMessage(block);
			newBlock.appendChild(snippet);

			const { isShowMore, snippetContent: normalizedSnippetContent } = this.normalizeSnippetContent(snippet.innerHTML);
			snippet.innerHTML = normalizedSnippetContent;

			if (normalizedSnippetContent.length > 0) {
				const toggleButton = this.createToggleButtonBlockResultMessage();
				newBlock.appendChild(toggleButton);
			}

			if (isShowMore) {
				const showMore = document.createElement('span');
				showMore.classList.add('block-result-message__show-more');
				showMore.innerHTML = `${i18next.t('COMMON_MORE_DETAILS')} »`;
				snippet.appendChild(showMore);
			}

			if (normalizedSnippetContent.length > 0) {
				const blockBreadcrumbs = this.filterBreadcrumbs(block.breadcrumbs);
				if (blockBreadcrumbs.length > 0) {
					const breadcrumbs = this.createBreadcrumbsResultMessage(block, blockBreadcrumbs);
					newBlock.appendChild(breadcrumbs);
				} else {
					newBlock.classList.add('block-result-message--formatted');
				}
			}

			return newBlock;
		},
		appendChildBlocks(blocks, rootBlock) {
			const regex = new RegExp('<h[0-9]></h[0-9]>'); // filter out blocks with invalid title like <h5></h5>
			const blocksToAppend = blocks
				.filter((block) => block._uid !== rootBlock._uid)
				.filter((block) => block.title != null)
				.filter((block) => !regex.test(block.title));

			return {
				...rootBlock,
				childBlocks: blocksToAppend
			};
		},
		getRootBlock(blocks) {
			return blocks.find((block) => blocks.indexOf(block) === blocks.lastIndexOf(block));
		},
		getClickedBlock(target) {
			if (target.classList.contains('block-result-message')) {
				return target;
			}
			return this.getClickedBlock(target.parentNode);
		},
		getClickedTable(target) {
			if (target.hasAttribute('data-item-id')) {
				return target;
			}
			return this.getClickedTable(target.parentNode);
		},
		getClickedBreadcrumb(target) {
			if (target.classList.contains('block-result-message__breadcrumb')) {
				return target;
			}
			return this.getClickedBreadcrumb(target.parentNode);
		},
		triggerCollapsedItem(id) {
			const elementsToTrigger = document.querySelectorAll(`[data-item-id=${id}]`);

			if (elementsToTrigger) {
				const elementsToTriggerArray = Array.from(elementsToTrigger);

				const collapseItemLabel = elementsToTriggerArray.find((element) => this.isCollapseItemLabel(element));
				const collapseTableTitle = elementsToTriggerArray.find((element) => this.isCollapseTableTitle(element));
				const collapseTable = elementsToTriggerArray.find((element) => this.isCollapseTable(element));

				if (collapseItemLabel) {
					collapseItemLabel.classList.toggle('block-result-message__collapse-item-label--selected');
				}

				if (collapseTableTitle) {
					collapseTableTitle.classList.toggle('block-result-message__collapse-table-title--collapsed');
				}

				if (collapseTable) {
					collapseTable.classList.toggle('block-result-message__table--collapsed');
				}

				!!collapseTable.length && this.updateScrollTableVisibility(collapseTable);
			}
		},
		onTouchStart(e) {
			this.pageYTouchStart = e.pageY || e.touches[0].pageY;
		},
		onTouchEnd(e) {
			this.pageYTouchEnd = e.changedTouches[0].pageY;

			if (Math.abs(this.pageYTouchStart - this.pageYTouchEnd) < 10) {
				this.handleResultBlockClick(e);
			}
		},
		handleResultBlockClick(e) {
			if (this.isChildBlocks(e.target)) {
				return;
			}

			const clickedBlock = this.getClickedBlock(e.target);
			const isCollapseItemClicked = this.isCollapseItemLabel(e.target);
			const isInsideTableClicked = e.target.tagName === 'TD' || e.target.tagName === 'TR' || e.target.tagName === 'IMG';

			if (isInsideTableClicked) {
				this.goToUrl(clickedBlock.getAttribute('data-path'));
			} else if (isCollapseItemClicked) {
				const collapseItemId = e.target.getAttribute('data-item-id');
				this.triggerCollapsedItem(collapseItemId);
			} else if (clickedBlock.classList.contains('block-result-message--closed')) {
				clickedBlock.classList.remove('block-result-message--closed');
			} else {
				const isOpenDocumentEvent = [
					this.isSnippet(e.target),
					this.isShowMore(e.target)
				].some((condition) => !!condition);

				const isBreadcrumbClicked = this.isBreadcrumb(e.target);
				const isQuestionBlock = this.isQuestion(this.model);

				if (isBreadcrumbClicked) {
					const clickedBreadcrumb = this.getClickedBreadcrumb(e.target);
					const breadcrumbUrl = clickedBreadcrumb.getAttribute('data-path');
					document.dispatchEvent(new CustomEvent('tr-custom-event', { detail: { url: breadcrumbUrl, name: 'professional-info-click' } }));
					this.goToUrl(breadcrumbUrl, true);
				} else if (isOpenDocumentEvent) {
					const dataPath = clickedBlock.getAttribute('data-path');
					const productUrl = prepareProductUrl(dataPath, slugify(_get(this.model, 'blocks[0].product', '')));
					document.dispatchEvent(new CustomEvent('tr-custom-event', { detail: { url: productUrl, name: 'professional-info-click' } }));

					if (!_isEmpty(highlightedLists)) {
						const matchedHighlightedListItem = _get(highlightedLists, dataPath);
						if (matchedHighlightedListItem) {
							this.goToUrl(productUrl, false, matchedHighlightedListItem);
						} else {
							this.goToUrl(productUrl);
						}
					} else {
						this.goToUrl(productUrl);
					}
				} else if (isQuestionBlock) {
					const text = this.model.title.replace(/<\/?[^>]+(>|$)/g, '');
					this.trigger('xircles-question-send', { conversationId: this.conversationId, text, id: text });
				} else {
					clickedBlock.classList.add('block-result-message--closed');
				}
			}
		},
		onAfterCreate() {
			const { blocks } = this.model;

			if (blocks.length > 0) {
				const rootBlock = this.getRootBlock(blocks);
				const rootBlockWithChilds = this.appendChildBlocks(blocks, rootBlock);
				const rootResultMessage = this.createBlockResultMessage(rootBlockWithChilds, true, rootBlockWithChilds.id);
				
				if (rootBlockWithChilds.childBlocks && rootBlockWithChilds.childBlocks.length > 0) {
					const blockChildsWrapper = this.createChildBlocksWrapper();

					rootBlockWithChilds.childBlocks.forEach((childBlock, index) => blockChildsWrapper.appendChild(this.createBlockResultMessage(childBlock, false, index, rootBlock)));
					rootResultMessage.appendChild(blockChildsWrapper);
				}

				this.appendChild(rootResultMessage);
			} else {
				const blockResultMessage = this.createIsolatedBlockResultMessage(this.model);
				this.appendChild(blockResultMessage);
			}

			this.addEventListener('click', this.handleResultBlockClick, { passive: true });
			this.addEventListener('touchstart', this.onTouchStart, { passive: true });
			this.addEventListener('touchend', this.onTouchEnd, { passive: true });
			window.addEventListener('resize', () => this.updateScrollTableVisibility());

			const hasOpenSnippet = !!this.parentElement.querySelector('.block-result-message:not(.block-result-message--closed) > .block-result-message__snippet');
			if (!hasOpenSnippet) {
				// Open first closed snippet when no other snippet is already open
				const elem = this.parentElement.querySelector('.block-result-message.block-result-message--closed > .block-result-message__snippet');
				if (elem) {
					elem.parentElement.classList.toggle('block-result-message--closed');
				}
			}
		},
		onDestroy() {
			window.removeEventListener('resize', () => this.updateScrollTableVisibility());
		}
	}
});