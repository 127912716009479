import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import './styles.less';
import pillImage from '../../../images/pill.svg';

const NoProductFragment = ({
  t,
}) => (
  <Fragment>
		<p className="no-product__message">{t('MODAL_NO_PRODUCT')}</p>
    <img className="no-product__pill-image" alt="robot" src={pillImage} />
  </Fragment>
);

export default withTranslation()(NoProductFragment);  
