import Layer from '@layerhq/web-xdk';
import {isBot, isOperator, isUser} from '../utils/user';

Layer.client.on('messages:sent', (evt) => {
  const bot = evt.target.getConversation().participants.find((p) => isBot(p) && p.id !== Layer.client.user.id);
  const isOperatorSender = isOperator(Layer.client.user);
  const isUserSender = isUser(Layer.client.user)
  const isOperatorEditMode = evt.target.getConversation().participants.length > 2 ;
  // Message sent by user to bot
  if (!(evt.target.getModelName() === 'ResponseModel' || evt.target._messageTypeModel.part.__mimeType === 'application/vnd.layer.response-v2+json') &&
    bot && !isBot(Layer.client.user)) {
    if ( !isOperatorEditMode || (isOperatorEditMode && !isOperatorSender && !isUserSender) ) {
      Layer.client.trigger('typing-indicator-change', {
        conversationId: evt.target.conversationId,
        typing: [bot],
        paused: []
      });
    }
  }
});

Layer.client.on('conversations:change', (evt) => {
  const change = evt.changes.find((c) =>
    c.property === 'lastMessage' &&
    c.newValue &&
    c.newValue.getModelName() !== 'XirclesControlModel' &&
    c.newValue.sender.id !== Layer.client.user.id &&
    isBot(c.newValue.sender)
  );
  // Message received from bot
  if (change) {
    Layer.client.trigger('typing-indicator-change', {
      conversationId: evt.target.id,
      typing: [],
      paused: []
    });
  }
});

const TypingIndicatorMixin = {
  properties: {
    value: {
      set: function (text) {
        let indi = document.querySelector('.xircles-typing-indicator');
        if (!indi) {
          const list = this.parentComponent.querySelector('layer-message-list');
          const item = document.createElement('div');
          item.classList.add('layer-message-item');
          indi = document.createElement('div');
          indi.classList.add('xircles-typing-indicator');
          item.appendChild(indi);
          list.appendChild(item);
          indi.innerHTML = '<span></span><span></span><span></span>';
        }
        if (text) {
          indi.classList.add('layer-typing-occuring');
        } else {
          indi.classList.remove('layer-typing-occuring');
        }
      }
    }
  }
};

export default TypingIndicatorMixin;
