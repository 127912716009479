// @flow

import React from 'react';
import './styles.less';

type Props = {
	item: Object
}

const PredefinedAnswer = ({ item }: Props) => (
	<li
		key={item.id}
		className="predefined-answers__item"
	>
		<a
			rel="canonical"
			target={item.target}
			href={item.createLink(item.url)}
			onClick={(e) => item.onClick(e, item.text)}
		>
			<i className={item.iconClassName}>
				{item.iconName}
			</i>
			{item.text}
		</a>
	</li>
);

export default PredefinedAnswer;
