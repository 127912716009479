// @flow
import React, { Fragment } from 'react';
import TextField from '../../../material-components/TextField';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import AutoFocusInput from '../../AutoFocusInput';
import { withTranslation } from 'react-i18next';

function PasswordConfirmModalFragment({
	password,
	onPasswordChange,
	onPasswordConfirm,
	onPasswordReset,
	t
}: Props) {
	return (
		<Fragment>
			<Box fontSize="htmlFontSize" textAlign="center" mt={0.75} mb={1.75}>{t('ERROR_ENTER_PASSWORD_PLEASE')}</Box>
			<TextField dense outlined style={{ width: '100%' }}>
				<AutoFocusInput
					id="password"
					name="password"
					type="password"
					style={{ textAlign: 'center' }}
					placeholder={`${t('LOGIN_PASSWORD_PLACEHOLDER')}`}
					value={password}
					onChange={e => onPasswordChange(e.target.value)}
				/>
			</TextField>
			<Box mt={2}>
				<Button id="password-login" data-keystroke outlined style={{ width: '100%' }} onClick={(e) => {
					e.preventDefault();
					return onPasswordConfirm(e);
				}}>
					{t('MODAL_SEND')}
				</Button>
			</Box>
			<Box
				mt={6}
				textAlign="center"
				onClick={onPasswordReset}
				dangerouslySetInnerHTML={{ __html: t('LOGIN_PASSWORD_FORGOTTEN') }}
			/>
		</Fragment>
	);
}

export default withTranslation()(PasswordConfirmModalFragment);