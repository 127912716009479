// @flow

import React from 'react';
import PredefinedAnswer from './PredefinedAnswer';

import './styles.less';

type Props = {
	title: string,
	items: Array<Object>
}

const PredefinedAnswers = (props: Props) => (
	<div className="predefined-answers">
		<p className="predefined-answers__title">{props.title}</p>
		<ul>
			{props.items.map((item, index) => (
				<PredefinedAnswer
					key={index}
					item={item}
				/>
			))}
		</ul>
	</div>
);

export default PredefinedAnswers;
