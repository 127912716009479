import React from 'react';
import { withRouter } from 'react-router-dom';
import CommonContext from '../components/CommonContext';
import logout from '../common/logout';
import LayerContext from '../components/LayerContext';
import config from '../config.json';
import { normalizePathname } from '../utils/common';
import Menu from '../components/Menu';

const MenuContainer = (props) => (
  <LayerContext.Consumer>
    {({ displayName, isLoggedIn, onLoginAnonymously }) => (
      <CommonContext.Consumer>
        {({ currentAction, onCurrentActionChange }) => (
          <Menu
            {...props}
            isLoggedIn={isLoggedIn}
            displayName={displayName}
            isMenuOpen={currentAction === 'menu'}
            onToggleMenu={() => onCurrentActionChange(null)}
            onLogin={(e) => {
              e.preventDefault();
              props.history.push(normalizePathname(props.history.location.pathname) + '/login');
            }}
            onDisplayName={(e) => {
              e.preventDefault();
              const redirectPath = config.UserManagementEnabled ? '/my-account' : '/logout';
              props.history.push(normalizePathname(props.history.location.pathname) + redirectPath);
            }}
            onLogout={() => logout().then(() => onCurrentActionChange(null)).then(onLoginAnonymously)}
          />
        )}
      </CommonContext.Consumer>
    )}
  </LayerContext.Consumer>
);

export default withRouter(MenuContainer);