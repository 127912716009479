// @flow

import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {Layer} from '../../../get-layer';
import '@layerhq/web-xdk/ui/components/layer-file-upload-button';
import {LayerReactComponents} from '../../../get-layer';
import {customFileSelector} from '../../../custom-message-types';
import Button from '../../../material-components/Button';
import closeBox from '../../../images/close-box.svg';
import INTERNAL_STATUS from '../../../const/status/internal-status';
import {FILE_EXTENSIONS} from '../../../const/file-extensions';
import {updateTicket} from '../../../utils/api';

const TextModel = Layer.Core.Client.getMessageTypeModelClass('TextModel')
const CarouselModel = Layer.Core.Client.getMessageTypeModelClass('CarouselModel')
const FileModel = Layer.Core.Client.getMessageTypeModelClass('FileModel');

const {
  FileUploadButton,
} = LayerReactComponents;

type Props = {
  t: Function,
  onCancel: Function
};

type State = {
  uploadedFiles: Array,
  text: string
};

class DeliverAnswerModalFragment extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.textArea = React.createRef();
    this.fileUploadButtonRef = React.createRef();

    this.state = {
      uploadedFiles: [],
      text: ''
    }
  }

  handleTextAreaChange(value) {
    this.setState({text: value})
  }

  handleClick = () => {
    this.fileRef.current.click()
  };

  handleDeleteClick(name) {
    this.setState({uploadedFiles: this.state.uploadedFiles.filter((file) => file.name !== name)})
    this.fileUploadButtonRef.current.node.nodes.input.value = null
  }

  handleSubmit = (event, value = '') => {
    event.preventDefault();

    let isFeedbackButtonPressed = value === 'feedback';
    const { conversation, conversationId } = this.props;

    document.body.dispatchEvent(new CustomEvent('xircles-control-message-send', {
      detail: {
        conversationId: conversationId,
        eventName: 'BOT_SUSPEND',
        data: {}
      }
    }));

    setTimeout(() => {
      this.setState({ isAnswerSending: true});
    }, 300)

    if (this.state.text.length) {
      const textModel = new TextModel({
        text: `${this.state.text}`
      });
      textModel.send({ conversation });
    }
    /*
     * defer to prevent rce condition in Dialog flow
     * TODO: remove after https://github.com/medicinisto/xircles-integration-platform/issues/547
     */
    if (this.state.uploadedFiles.length >= 2) {
      const carouselModel = new CarouselModel({
        items: this.state.uploadedFiles.map(file => {
          return new FileModel({
            source: file,
            mimeType: `${file.type}`,
            title: `${file.name}`,
            author: Layer.client.user.displayName
          })
        })
      });
      carouselModel.send({conversation})
    } else {
      this.state.uploadedFiles.length && this.state.uploadedFiles.forEach(file => {
        const fileModel = new FileModel({
          source: file,
          mimeType: `${file.type.replace('application/vnd.openxmlformats-officedocument.', '').split('.')[1] || file.type}`,
          title: `${file.name}`,
          author: Layer.client.user.displayName
        });
        fileModel.send({conversation})
      })
    }
    updateTicket({
      external_id: conversationId,
      ticket: {xircles_status: INTERNAL_STATUS.ANSWER_DELIVERED},
    });

    // Text message should be sent prior to this. We can ignore files sending since they will be ignored by bot anyway
    document.body.dispatchEvent(new CustomEvent('xircles-control-message-send', {
      detail: {
        conversationId: conversationId,
        eventName: 'BOT_RESUME',
        data: {}
      }
    }));

    Layer.client.on('messages:sent', (evt) => {
      if (evt.target.__parts) {
        if (isFeedbackButtonPressed) {
          document.body.dispatchEvent(new CustomEvent('xircles-control-message-send', {
            detail: {
              conversationId: conversationId,
              eventName: 'BOT_FLOW',
              data: {
                flow: 'operatorFeedback',
                state: 'initiate'
              }
            }
          }));
        }
        isFeedbackButtonPressed = false;
      }
    });
    this.props.onCancel()
    this.setState({ isAnswerSending: false});
  };

  handleUploadFiles = (files) => {
    this.setState({uploadedFiles: files})
  };

  // Support use of the PDF Custom Message Type by detecting when the File Upload Widget receives a PDF file.
  filesSelected(evt: CustomEvent) {
    customFileSelector(evt, this.state.conversation, this.handleUploadFiles);
  }

  render() {
    const {t} = this.props;
    const {uploadedFiles} = this.state;
    const isButtonDisabled = !(uploadedFiles.length || this.state.text) || this.state.isAnswerSending;
    return (
      <form className={'deliver-answer__wrapper'} onSubmit={this.handleSubmit}>
        <textarea
          ref={this.textArea}
          onChange={e => this.handleTextAreaChange(e.target.value)}
        >
        </textarea>
        <div className={'deliver-answer__footer-wrapper'}>
          <FileUploadButton
            ref={this.fileUploadButtonRef}
            className={'mdc-button mdc-ripple-upgraded mdc-button--secondary mdc-button--outlined button button--upload'}
            accept={FILE_EXTENSIONS.join(',')}
            multiple="true"
            onFilesSelected={this.filesSelected.bind(this)}
          >
            {t('MODAL_UPLOAD_FILE')}
          </FileUploadButton>
          <div className={'deliver-answer__submit-container'}>
            <Button
              outlined className={'button button--submit button--submit__with-feedback'}
              color="secondary"
              type={'button'}
              onClick={(ev) => this.handleSubmit(ev, 'feedback')}
              disabled={isButtonDisabled}
            >
              {t('MODAL_SUBMIT_WITH_FEEDBACK')}
            </Button>
            <Button
              outlined className={'button button--submit'}
              color="secondary"
              type={'submit'}
              disabled={isButtonDisabled}
            >
              {t('MODAL_SUBMIT')}
            </Button>
          </div>
          <input
            onChange={this.handleChange}
            ref={this.fileRef}
            type="file"
            multiple
            style={{display: 'none'}}
          />
          {
            !!uploadedFiles.length &&
            uploadedFiles.map((item, i) => {
                const name = item.name
                return (
                  <div className={'uploaded-item'} key={name + i}>
                    <span>{`${name.length < 15 ? name : name.split('.')[0].slice(0, 7) +'...'+ name.split('.')[0].slice(-5) + '.' +name.split('.')[1]}`}</span>
                    <button
                      data-tr-event
                      onClick={() => this.handleDeleteClick(name)}
                    >
                      <img src={closeBox} alt={'Close'}/></button>
                  </div>
                )
              }
            )
          }
        </div>
      </form>
    );
  }
}

export default withTranslation()(DeliverAnswerModalFragment);
