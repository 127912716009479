export const phoneModels = [
  'iPhone',
  'BlackBerry',
  'HTC',
  'Nexus',
  'Dell',
  'Motorola',
  'Samsung',
  'LG',
  'Sony',
  'Asus',
  'NokiaLumia',
  'Micromax',
  'Palm',
  'Vertu',
  'Pantech',
  'Fly',
  'Wiko',
  'iMobile',
  'SimValley',
  'Wolfgang',
  'Alcatel',
  'Nintendo',
  'Amoi',
  'INQ',
  'OnePlus',
  'GenericPhone'
];