// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './styles.less';

const MedicationCard = (props: Props) => (
	<div
		className="medication-card"
		onClick={() => {
			if (props.link.includes(window.location.origin)) {
				props.history.push(props.link.replace(window.location.origin, ''));
			}
		}}
	>
		<a
			href={props.link}
			className="medication-card__title"
			onClick={(e) => {
				if (props.link.includes(window.location.origin)) {
					e.preventDefault();
				}
			}}
		>
			{props.title}
		</a>
	</div>
);

export default withTranslation()(withRouter(MedicationCard));
