import Layer from '@layerhq/web-xdk';
import _get from 'lodash/get';
import i18next from 'i18next';

import { getDrugInfos } from '../../utils/api';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;

registerComponent('xircles-select-drug-view', {
  mixins: [MessageViewMixin],
  style: `
        .select-drug {
            margin-bottom: 30px;
            padding: 0 20px;
            min-height: 39px;
            border-radius: 0 0 10px 10px;
            position: relative;
        }

        .select-drug button#expand-button {
            position: absolute;
            bottom: -15px;
            left: calc(50% - 25px);
            border-radius: 0 0 5px 5px;

            height: 24px;
            width: 50px;
            padding: 0;

            cursor: pointer;
        }

        .select-drug layer-loading-indicator, .select-drug layer-loading-indicator .loading-spinner {
            width: 20px;
            height: 20px;
            background-color: unset;
        }

        .select-drug layer-loading-indicator .spinner {
            top: unset;
            margin: 0;
            display: block;
        }

        .select-drug layer-loading-indicator {
            margin: 0;
            position: absolute;
            left: calc(50% - 10px);
        }

        .select-drug--visible layer-loading-indicator {
            display: none;
        }

        .select-drug--visible #selected-drug, .select-drug--visible .match-counter {
            opacity: 1;
        }

        .select-drug--expanded {
            padding-bottom: 23px;
            min-height: 115px;
            flex-direction: column !important;
            align-items: flex-start !important;
        }

        .select-drug--expanded span#selected-drug {
            margin-left: 0px !important;
            width: 98%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .select-drug--expanded {
            display: none;
        }

        .select-drug--expanded .select-drug__compound-component {
            padding-top: 7px;
            line-height: 26px;

            max-width: 620px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .select-drug--expanded #expand-button i {
            transform: rotate(180deg);
        }

        .select-drug--expanded .select-drug__reply-source {
            display: block;
        }

        .select-drug--expanded .available-documents {
            display: block;
        }

        .select-drug {
            display: flex;
            flex-direction: row;
            align-items: center;

            font-family: Roboto;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
        }

        .select-drug__compound-component, #selected-drug {
            line-height: 21px;
        }

        .select-drug__compound-component {
          max-width: 185px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .select-drug__reply-source {
            display: none;
            margin: 11px 0 5px 0;
            font-size: 11px;
            font-weight: bold;
        }

        span#selected-drug {
            margin-left: 10px;
            color: #494d5b;
            font-weight: bold;

            cursor: pointer;
            opacity: 0;

            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        #compound-component {
            color: #777a84;
            white-space: nowrap;
        }

        .match-counter {
            margin-left: 3px;
            opacity: 0;
            display: none;
            align-items: center;
        }

        .match-counter__circle {
            margin-left: 8px;
            min-width: 12px;
            height: 12px;
            border-radius: 6px;
            display: inline-block;
            align-self: center;
        }

        .match-counter__number {
            font-weight: bold;
            margin: 0 5px 0 5px;
        }

        .match-counter__hide {
            display: none !important;
        }

        .available-documents {
            list-style: none;
            display: none;
            padding: 0;
            color: #9e94a1;
            width: 100%;
            margin: 5px 0 0 0;
        }

        .available-documents--expanded .available-documents__item {
            display: flex !important;
        }

        .available-documents__item {
            padding-top: 5px;
            cursor: pointer;
            display: flex;
        }

        .available-documents__text {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .available-documents__item:first-of-type {
            border-top: solid 1px #ccced3;
        }

        .available-documents__item:nth-child(n+5) {
            display: none;
        }

        button#show-more-button {
            cursor: pointer;
            display: none;
            padding: 5px 0 0 0;
        }

        button.show-more-button--visible {
            display: block !important;
        }
    `,
  template: `
        <div class="select-drug">
            <layer-loading-indicator></layer-loading-indicator>
            <span id="compound-component" class="select-drug__compound-component"></span>
            <span id="select-drug-title" class="select-drug__reply-source"></span>
            <span id="selected-drug"></span>
            <div class="match-counter">
                <div class="match-counter__circle" ></div>
                <span class="match-counter__number"></span>
            </div>
            <ul id="available-documents" class="available-documents"></ul>
            <button id="show-more-button"></button>
            <button id="expand-button">
                <i class="material-icons">keyboard_arrow_down</i>
                <label id="select-drug-label"></label>
            </button>
        </div>
    `,
     methods: {
        handleAvailableDocumentClick(e) {
            const selectedProduct = this.model.documents.find((drug) => drug.name === e.target.dataset.name);

            if (selectedProduct) {
                window.dispatchEvent(new CustomEvent('set-mmi-data', {
                    detail: {
                        publishedDate: _get(selectedProduct, 'published_date', ''),
                        externalUrl: _get(selectedProduct, 'external_url', ''),
                    }
                }));
                window.dispatchEvent(new CustomEvent('select-drug', { detail: { selectedProduct } }));
                this.handleSelectDrug(selectedProduct);
            }
            const [selectDrug] = document.getElementsByClassName('select-drug');
            selectDrug.classList.toggle('select-drug--expanded');
            const [matchCounter] = document.getElementsByClassName('match-counter');
            matchCounter.classList.toggle('match-counter__hide');

            const showMoreButton = document.getElementById('show-more-button');
            if (showMoreButton.classList.contains('show-more-button--visible')) {
                showMoreButton.classList.toggle('show-more-button--visible');
            }
            // trigger possible resize adjustments in outer components
            window.dispatchEvent(new Event('resize'));
        },
        createAvailableDocument(documentItem, availableDocumentsList, showMatchDot = false) {
            const availableDocumentItem = document.createElement('li');
            availableDocumentItem.classList.add('available-documents__item');

            const text = document.createElement('span');
            text.classList.add('available-documents__text');
            text.innerHTML = documentItem.title;
            text.dataset.name = documentItem.name;
            availableDocumentItem.appendChild(text);

            if (showMatchDot) {
                const matchDot = document.createElement('div');
                matchDot.classList.add('match-counter__circle');
                availableDocumentItem.appendChild(matchDot);
            }

            availableDocumentsList.appendChild(availableDocumentItem);
        },
        handleSelectDrug(product) {
            const selectedDrug = document.getElementById('selected-drug');

            if (selectedDrug) {
                selectedDrug.innerHTML = product.title;
                selectedDrug.dataset.name = product.name;

                document.dispatchEvent(
                    new CustomEvent('set-conversation-metadata', {
                        detail: {
                            documentId: product.id,
                            documentTitle: product.title
                        }
                    })
                );
            }
        },
        async onAfterCreate() {
            const SHOW_MORE_BUTTON_DOCUMENTS = 4;

            const view = this;
            const { data: { documents, molecules = [] } } = await getDrugInfos(this.model.product);
            this.model.documents = documents;

            const selectedDocument = documents.find((document) => document.id.toString() === this.model.documentId);

            if (selectedDocument) {
                window.dispatchEvent(new CustomEvent('set-mmi-data', {
                    detail: {
                        publishedDate: _get(selectedDocument, 'published_date', ''),
                        externalUrl: _get(selectedDocument, 'external_url', ''),
                    }
                }));

                const compoundComponent = view.querySelector('#compound-component');

                if (compoundComponent) {
                    compoundComponent.innerHTML = (molecules || []).join(', ');
                }

                const selectedDrug = view.querySelector('#selected-drug');

                if (selectedDrug) {
                    selectedDrug.innerHTML = selectedDocument.title;

                    document.dispatchEvent(
                        new CustomEvent('set-conversation-metadata', {
                            detail: {
                                documentId: selectedDocument.id,
                                documentTitle: selectedDocument.title
                            }
                        })
                    );
                }

                const selectDrugButton = view.querySelector('#select-drug');
                if (selectDrugButton) {
                    selectDrugButton.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', () => {
                        window.dispatchEvent(new CustomEvent('change-route', {
                            detail: {
                                url: window.location.pathname + '/select-drug',
                                state: { selectedProduct: selectedDrug.innerHTML, drugs: documents.map(({ id, name, title }) => ({ id, name, title })) }
                            }
                        }));
                    });
                }

                const [selectDrug] = view.getElementsByClassName('select-drug');
                selectDrug && selectDrug.classList.add('select-drug--visible');

                const availableDocuments = documents.filter((document) => document.id.toString() !== this.model.documentId);

                if (availableDocuments.length > 0) {
                    const availableDocumentsList = view.querySelector('#available-documents');

                    if (availableDocumentsList) {
                        this.createAvailableDocument(selectedDocument, availableDocumentsList, this.model.docIdsMapWithMatch[selectedDocument.id.toString()]);

                        availableDocuments.forEach((document) => this.createAvailableDocument(document, availableDocumentsList, this.model.docIdsMapWithMatch[document.id.toString()]));
                        availableDocumentsList.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', (e) => this.handleAvailableDocumentClick(e));
                    }
                }

                // docIdsMapWithMatch might have outdated document matches in it so we filter them out
                const matchNum = Object.keys(this.model.docIdsMapWithMatch)
                    .filter(docId => documents.find(document => document.id.toString() === docId))
                    .length;
                if (matchNum) {
                    const [matchCounter] = view.getElementsByClassName('match-counter');
                    matchCounter.style.display = 'flex';
                    const [matchCounterNumText] = view.getElementsByClassName('match-counter__number');
                    matchCounterNumText.textContent = matchNum.toString();
                }

                view.querySelector('#select-drug-title').innerHTML = i18next.t('COMMON_REPLY_SOURCE');
                view.querySelector('#select-drug-label').innerHTML = i18next.t('SELECT_DRUG_LABEL');

                const showMoreButton = view.querySelector('#show-more-button');
                showMoreButton.innerHTML = i18next.t('SELECT_DRUG_SHOW_ALL_DOCUMENTS');

                if (showMoreButton) {
                    const availableDocumentsList = view.querySelector('#available-documents');

                    showMoreButton.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', () => {
                        showMoreButton.classList.toggle('show-more-button--expanded');
                        availableDocumentsList.classList.toggle('available-documents--expanded');

                        if (showMoreButton.classList.contains('show-more-button--expanded')) {
                            showMoreButton.innerHTML = i18next.t('SELECT_DRUG_SHOW_LESS_DOCUMENTS');
                        } else {
                            showMoreButton.innerHTML = i18next.t('SELECT_DRUG_SHOW_ALL_DOCUMENTS');
                        }
                        // trigger possible resize adjustments in outer components
                        window.dispatchEvent(new Event('resize'));
                    });
                }

                const toggleExpand = () => {
                    selectDrug.classList.toggle('select-drug--expanded');

                    if (availableDocuments.length + 1 > SHOW_MORE_BUTTON_DOCUMENTS) {
                        showMoreButton.classList.toggle('show-more-button--visible');
                    }
                    // trigger possible resize adjustments in outer components
                    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);

                    const [matchCounter] = view.getElementsByClassName('match-counter');
                    matchCounter.classList.toggle('match-counter__hide');
                };
                const expandButton = view.querySelector('#expand-button');
                if (expandButton) {
                    expandButton.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', toggleExpand);
                }
                if (this.model.expandOnCreate) {
                    toggleExpand();
                }
            }
        }
    }
});