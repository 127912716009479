import Layer from '@layerhq/web-xdk';
import _isEmpty from 'lodash/isEmpty';
import {isIE} from '../utils/common';

const AvatarMixin = {
  methods: {
    _renderUser: function () {
      if (isIE()) {
        const img = document.createElement('img');
        this.appendChild(img);
      }
    },
    onRender: function () {
      if (navigator.userAgent !== 'ReactSnap') {
        const [img] = this.getElementsByTagName('img');
        if (img && img.parentNode && img.parentNode.removeChild === 'function') {
          img.parentNode.removeChild(img)
        }
      }
    },
    onRerender: function () {
      if (this.users) {
        this.users = this.users
          .filter(user => (
            user !== Layer.client.user &&
            user.displayName &&
            !_isEmpty(user.displayName.trim())
          ));
      }
    },
  }
};

export default AvatarMixin;
