// @flow

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import './styles.less';
import { createElementFromHTML, getSafeHtml } from '../../utils/common';


class ProductOverview extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isOneparagraphSnippet: false,
		};
	}

	componentDidMount() {
		const htmlSnippet = createElementFromHTML(this.props.productSnippet);
		this.setState({ isOneparagraphSnippet: Array.from(htmlSnippet).length === 1 });
	}

	render() {
		const {
			onProductOverviewLinkClick,
			productCompoundComponent,
			productManufacturer,
			productRelativeUrl,
			isMultiProduct,
			productSnippet,
			productTitle,
			productUrl,
			t
		} = this.props;

		return (
			<div className={`product-overview__application-areas ${isMultiProduct ? 'product-overview__multi-product' : ''}`}>
				<h3 className="product-overview__title" dangerouslySetInnerHTML={{ __html: getSafeHtml(productTitle) }}></h3>
				{isMultiProduct && <p className="product-overview__compound-component">{productCompoundComponent}</p>}
				{!isMultiProduct && <p className="product-overview__manufacturer">{productManufacturer}</p>}
				{!isMultiProduct && <h3 className="product-overview__application-areas-title">{t('COMMON_APPLICATION_AREAS')}</h3>}
				{!isMultiProduct && (this.state.isOneparagraphSnippet
					? <div className="product-overview__description product-overview__description-oneparagraph">{Parser(productSnippet)}</div>
					: <div className="product-overview__description product-overview__description-multipleparagraphs">{Parser(productSnippet)}</div>)}
				<div className="product-overview__link">
					<a
						rel="canonical"
						href={productUrl}
						onClick={(e) => {
							e.preventDefault();
							onProductOverviewLinkClick(productRelativeUrl)
						}}
					>
						{`${t('COMMON_SHORT_SPECIALIST_INFORMATION')} »`}
					</a>
				</div>
			</div>
		)
	}
}

export default withTranslation()(ProductOverview);
