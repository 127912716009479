// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import './styles.less';

const Footer = (props) => (
	<footer>
		<ul>
			<li className={'name'}>
				{props.t('COMMON_FOOTER')}
			</li>
			<li>{props.t('COMMON_FOOTER_CONTACT')}</li>
			<li>{props.t('COMMON_FOOTER_USAGE_AGREEMENT')}</li>
			<li>{props.t('COMMON_FOOTER_DATA_PRIVACY')}</li>
			<li>{props.t('COMMON_FOOTER_IMPRINT')}</li>
		</ul>
	</footer>
);

export default withTranslation()(Footer);