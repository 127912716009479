import axios from 'axios';
import LayerUtils from '@layerhq/web-xdk/utils';
import config from '../config.json';
import { layerClient } from '../get-layer';
import { HTTP_METHODS, HTTP_STATUSES } from '../const';
import { isModalWindowRoute, isAutocompleteOrSpellcheckRequest } from './common';
import Cookies from 'universal-cookie';
import { deleteFromSession, saveInSession } from '../common/persistSession';
import { loadFromSession } from '../common/loadSession';

const cookies = new Cookies();

const LAYER_API_URL = config.LAYER_API;
const REQUEST_TIME_OUT = 10000;

const authInterceptor = (config) => {
  const configWithAuth = {
    ...config,
    headers: {
      Authorization: `Layer session-token="${layerClient.sessionToken || 'anonymous'}"`,
      ...config.headers,
    },
    timeout: REQUEST_TIME_OUT,
  };

  return configWithAuth;
};

const responseInterceptor = (response) => {
  checkSession(response);
  return response;
}

const errorCallback = (error) => {
  if (!checkSession(error.response)) return;
  if (!error.status && !error.response && !window.location.pathname.match(/network-error/)) {
    window.dispatchEvent(new CustomEvent('change-route', {
      detail: { url: window.location.pathname === '/' ? '/network-error' : window.location.pathname + '/network-error' },
    }));
  } else if (error.response.status === HTTP_STATUSES.TOO_MANY_REQUESTS.code && !window.location.pathname.match(/too-many-requests/)) {
    window.dispatchEvent(new CustomEvent('change-route', {
      detail: { url: window.location.pathname === '/' ? '/too-many-requests' : window.location.pathname + '/too-many-requests' },
    }));
  } else if (!isModalWindowRoute(window.location.pathname) && !window.location.pathname.match(/documents/) && !window.location.pathname.includes('doccheck')) {
    if (isAutocompleteOrSpellcheckRequest(error.config.url)) {
      throw new Error(error)
    } else {
      window.dispatchEvent(new CustomEvent('change-route', {
        detail: { url: window.location.pathname === '/' ? '/something-went-wrong' : window.location.pathname + '/something-went-wrong' },
      }));
    }
  }
  return Promise.reject(error);
};

const httpRecorderRequestInterceptor = (config) => ({ ...config, datetime: new Date().toISOString() });

const httpRecorderResponseInterceptor = (response) => {
  if (cookies.get('xircles-stream')) {
    const { data, status, headers, config } = response;
    recordHttpEvent({
      connectionType: 'HTTP',
      http: {
        serverType: 'XIP',
        path: config.url.replace(config.baseURL, ''),
        method: config.method
      },
      request: {
        data: config.data && JSON.parse(config.data),
        headers: config.headers,
        datetime: config.datetime,
      },
      response: {
        data,
        status,
        headers,
        datetime: new Date().toISOString(),
      }
    });
  }
  return response;
};

export const api = axios.create({ baseURL: config.XipApiUrl });
export const apiCDN = axios.create({ baseURL: config.CDNUrl });
export const apiLayer = axios.create({ baseURL: config.LAYER_API });

if (config.TestRecorderEnabled) {
  api.interceptors.response.use(httpRecorderResponseInterceptor, error => Promise.reject(error));
  api.interceptors.request.use(httpRecorderRequestInterceptor, error => Promise.reject(error));
}
// request interceptors order is reversed: https://github.com/axios/axios/issues/1663
api.interceptors.request.use(authInterceptor, error => Promise.reject(error));
apiLayer.interceptors.request.use(authInterceptor, error => Promise.reject(error));

api.interceptors.response.use(responseInterceptor, errorCallback);
apiCDN.interceptors.response.use(responseInterceptor, errorCallback);
apiLayer.interceptors.response.use(responseInterceptor, errorCallback);

export const getAllDictionaries = () => apiCDN.get(`${releasePath()}/dict.json`);
export const releasePath = () => (config.Stage === 'dev' ? '' : `/releases/${loadFromSession('release_id') || 0}`);

export const getSuggestionsByAutocomplete = (title, product, documentId) => api.post('/suggestions/autocomplete', { title, product, documentId });
export const getSuggestionsByTags = (tags, product, documentId) => api.post('/suggestions', { tags, product, documentId });

export const sendVerificationCode = (credentials) => api.post('/code/send', credentials);
export const codeVerify = (credentials) => api.post('/code/verify', credentials);

export const upsertPassword = (credentials) => api.post('password/upsert', credentials);

export const authenticate = (credentials) => api.post('/users/authenticate', credentials);
export const authenticateAnonymous = (credentials = {}) => api.post('/users/authenticate_anonymous', credentials);
export const authenticateDocCheck = (credentials) => api.post('/doccheck/auth', credentials);

export const checkUserStatus = (credentials) => api.post('/check', credentials);

export const retrieveDocument = (slug) => api.get(`/documents/${slug}`);

export const shareContent = (details) => api.post('/share_content', details);

export const forwardMedinfo = (details) => api.post('/forward-medinfo', details);

export const reportPV = (details) => api.post('/report-pv', details);

export const updateTicket = ({ external_id, ticket }) => api.post(`/ticket/${external_id}`, ticket);

export const addParticipantConversation = (conversation_id, user_id) => api.post(`conversations/${conversation_id}/participants`, { user_id });

export const transferConversations = (oldUserID, user_id, session_token) => axios({
  url: `/users/${oldUserID}/conversations/merge/${user_id}`,
  baseURL: LAYER_API_URL,
  method: HTTP_METHODS.POST,
  headers: {
    Accept: 'application/vnd.layer+json; version=3.0',
    Authorization: `Layer session-token="${session_token}"`,
  },
});

export const getIdentityById = ({ user_id, session_token }) => axios({
  url: `/identities/${user_id}`,
  baseURL: LAYER_API_URL,
  method: HTTP_METHODS.GET,
  headers: {
    Accept: 'application/vnd.layer+json; version=3.0',
    Authorization: `Layer session-token="${session_token}"`,
  },
});

export const fileDownload = (url_download, session_token) => axios({
  url: url_download,
  method: HTTP_METHODS.GET,
  responseType: 'blob',
  headers: {
    Authorization: `Layer session-token="${session_token}"`,
  },
});

export const universalSearch = (search_term) => api.post('/users/universal_search', { search_term });

export const spellCheck = (source) => api.post('/spellcheck', source);

export const getProductFaqs = (product, pageSize = 4) => apiLayer.get(`/conversations?sort_by=last_message&page_size=${pageSize}&product=${product}`);

export const startStream = (stream) => apiLayer.post('/streams', stream);

export const recordHttpEvent = (event) => apiLayer.post('/streams/http', event);

export const finishStream = (stream) => apiLayer.put('/streams', stream);

export const replayStream = (stream) => apiLayer.get(`/streams/replay/${stream.streamID}`);

export const updateUser = ({ userId, data }) => apiLayer.patch('/users/' + userId + '/identity', data);

export const getConversations = ({ mode, product }) => apiLayer.get(`/conversations?mode=${mode}&product=${product}`);

export const getConversationsWithRestParams = (filterStatus = '', cursor, pageSize) => apiLayer.get(`/conversations?${filterStatus ? `filter={"metadata.xircles_status":"${filterStatus}"}&` : ''}range=[${cursor},${cursor + pageSize - 1}]`);

export const getConversationsCount = async (filterStatus = '') => {
  let isLastPage = false;
  let count = 0;
  const pageSize = 1000;
  while (!isLastPage) {
    const response = await getConversationsWithRestParams(filterStatus, count, pageSize);
    count += response.data.length;
    isLastPage = response.data.length < pageSize;
  }
  return count;
}

export const updatePhoneEmail = (details) => api.post('/phone-email/update', details);

export const updateSession = ({ release_id }) => (
  apiLayer
    .put('/sessions/' + layerClient.sessionToken, { release_id: release_id || null })
    .then(() => {
      release_id ? saveInSession({ release_id }) : deleteFromSession('release_id');
      setTimeout(() => window.location.reload(), 0);
    })
);

export const getThemeSettings = () => axios(`${config.StoryblokThemeUrl}?token=${config.StoryblokAccessToken}&from_release=${config.ReleaseId}`);

export const verifyCaptcha = (details) => apiLayer.post('/captcha', details);

export const getDrugInfos = (brand_slug) => api.get(`/drug-info?brand_slug=${brand_slug}`);

export const getDrugsCatalogue = (letter, page) => {
  const searchParams = new URLSearchParams();
  if (letter != null) searchParams.set('letter', letter);
  if (page != null) searchParams.set('page', page);
  return api.get('/drugs-catalogue?' + searchParams.toString());
};

export const checkSession = (response) => {
  if (response.status === 401 && layerClient._wantsToBeAuthenticated) {
    if (layerClient.isAuthenticated) {
      var hasOldSessionToken = response.request.headers && response.request.headers.authorization;
      var oldSessionToken = hasOldSessionToken ? response.request.headers.authorization.replace(/^.*"(.*)".*$/, '$1') : '';

      // Ignore auth errors if in response to a no longer in use sessionToken
      if (oldSessionToken && layerClient.isReady && layerClient.sessionToken && oldSessionToken !== layerClient.sessionToken) return;

      LayerUtils.logger.warn('SESSION EXPIRED!');
      layerClient.trigger('challenge');
      document.location.href = '/';
      return false;
    }
  }
  return true;
}