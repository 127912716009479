import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesSimpleAnswerMessageModel extends MessageTypeModel {
  generateParts(callback) {
    const that = this;

    const body = this.initBodyWithMetadata([]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });

    if (this.items.length === 0) {
      callback([this.part]);
    } else {
      const parts = [this.part];
      this.items.forEach((item, i) => {
        that.addChildModel(item, 'items', (newParts) => {
          newParts.forEach((p) => parts.push(p));
        });
      });
      callback(parts);
    }
  }

  parseModelChildParts({ changes, isEdit }) {
    super.parseModelChildParts({ changes, isEdit });

    this.items = this.getModelsByRole('results');
  }
}

XirclesSimpleAnswerMessageModel.prototype.items = null;

XirclesSimpleAnswerMessageModel.MIMEType = 'application/xircles.simple-answer+json';
XirclesSimpleAnswerMessageModel.SummaryTemplate = '${textFound}'; // eslint-disable-line no-template-curly-in-string
XirclesSimpleAnswerMessageModel.LabelSingular = 'Simple Answer';
XirclesSimpleAnswerMessageModel.messageRenderer = 'xircles-simple-answer-message-view';

Root.initClass.apply(XirclesSimpleAnswerMessageModel, [XirclesSimpleAnswerMessageModel, 'XirclesSimpleAnswerMessageModel']);
Client.registerMessageTypeModelClass(XirclesSimpleAnswerMessageModel, 'XirclesSimpleAnswerMessageModel');

export default XirclesSimpleAnswerMessageModel;
