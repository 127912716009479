import Layer from '@layerhq/web-xdk';

import './question-message-model';

import config from '../../config.json';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const processText = Layer.UI.handlers.text.processText;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-question-message-view', {
  mixins: [MessageViewMixin],
  style: `
    xircles-question-message-view {
      display: block;
      clear: both;
      padding-left: 10px;
    }
    .layer-root-viewer.xircles-question-message-view > * > .layer-card-top {
      display: block;
    }
    ${config.Debug ? '.layer-list-meta+.layer-message-item-xircles-question-message-view { display: block }' : ''}
  `,
  template: `
    <div>
      <span layer-id="text"></span>
    </div>
  `,
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
      }
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container'
    }
  },
  methods: {
    onAfterCreate() {
      this.nodes.text.innerHTML = processText(this.model.text);
    },
    onRerender() {
      this.nodes.text.innerHTML = processText(this.model.text);
    }
  }
});