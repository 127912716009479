import Layer from '@layerhq/web-xdk';

const { Root, Client } = Layer.Core;

const TextModel = Client.getMessageTypeModelClass('TextModel');

class XirclesDisclaimerModel extends TextModel {
}

XirclesDisclaimerModel.MIMEType = 'application/xircles.disclaimer+json';
XirclesDisclaimerModel.messageRenderer = 'xircles-disclaimer-message-view';

Root.initClass.apply(XirclesDisclaimerModel, [XirclesDisclaimerModel, 'XirclesDisclaimerModel']);
Client.registerMessageTypeModelClass(XirclesDisclaimerModel, 'XirclesDisclaimerModel');

export default XirclesDisclaimerModel;