import React from 'react';
import {withRouter} from 'react-router-dom';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';
import Header from '../components/Header';
import config from '../config.json';

function HeaderContainer({
                           history,
                           normalizePathname,
                           ...props
                         }) {
  return (
    <LayerContext.Consumer>
      {({displayName, isLoading, isLoggedIn}) => (
        <CommonContext.Consumer>
          {({onCurrentActionChange}) => (
            <Header
              {...props}
              isLoggedIn={isLoggedIn}
              isLoading={isLoading}
              displayName={displayName}
              onLogin={(e) => {
                e.preventDefault();
                history.push(normalizePathname(history.location.pathname) + '/login');
              }}
              onDisplayName={(e) => {
                e.preventDefault();
                const redirectPath = config.UserManagementEnabled ? '/my-account' : '/logout';
                history.push(normalizePathname(history.location.pathname) + redirectPath);
              }}
              onToggleMenu={() => {
                onCurrentActionChange('menu');
              }}
            />
          )}
        </CommonContext.Consumer>
      )}
    </LayerContext.Consumer>
  )
}

const HeaderContainerWithRouter = withRouter(HeaderContainer);

export default React.forwardRef((props, ref) => {
  return <HeaderContainerWithRouter {...props} forwardedRef={ref} />;
});
