// @flow

/**
 *      Creates UI component with menu panel
 *
 *      isMenuOpen          - I guess It no need to explain. Required
 *      onToggleMenu        - function for opening/closing Menu. Should change isMenuOpen inside. Required
 *      buttonAccountList   - Array with props for MenuButton, placed at the top, NOT required.
 *      buttonContentList   - Array with props for MenuButton, placed at the bottom, NOT required. - not showing
 *      className           - className for parent <div>, default value is "menu-panel",
 *                            use for customizing <ul> and inner MenuButtons(with MenuButton className)
 */

import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';
import MenuButton from './MenuButton/index';
import PredefinedAnswers from './PredefinedAnswers';
import config from '../../config.json';
import { Layer } from '../../get-layer';
import { startStream, finishStream, replayStream } from '../../utils/api';
import { isInsideFrame } from '../../utils/common';

import './styles.less'

type Props = {
  isLoggedIn: boolean,
  isMenuOpen: boolean,
  onToggleMenu: Function,
  buttonAccountList?: Array,
  buttonContentList?: Array,
  className: string,
  t: Function
}

const { generateUUID } = Layer.Utils;

const filterButtonAccountList = (props) => {
  const { isLoggedIn, buttonAccountList } = props;

  const menuButtons = buttonAccountList.length > 0
    ? buttonAccountList
    : getDefaultMenuOptions(props);

  return menuButtons.filter((button) => {
    const isButtonInvisible = (!isLoggedIn && button.id === 'account-logout-button' && !config.Debug) || !button.id;
    return !isButtonInvisible;
  });
};

const cookieParams = {
  path: '/',
  maxAge: 60 * 30 * 24,
};

const handleStreamButtons = (props) => {

  const handleStreamStart = (props) => {

    props.cookies.remove('xircles-stream-recorded');

    const streamID = generateUUID();
    const description = window.prompt('Please enter stream description:', '');

    startStream({ streamID, description })
      .then((res) => {
        if (res.status === 201) {
          props.cookies.set('xircles-stream', streamID, cookieParams);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          const shouldFinishCurrentStream = window.confirm(`${err.response.data}, to start a new stream you need to stop existing one`, '');
          shouldFinishCurrentStream && finishStream()
        }
      });
  };

  const handleStreamFinish = (props) => {
    const streamID = props.cookies.get('xircles-stream');
    finishStream({ streamID }).then(() => {
      props.cookies.remove('xircles-stream', cookieParams);
      props.cookies.set('xircles-stream-recorded', streamID, cookieParams);
    });
  };

  const streamControls = {
    [true]: {
      icon: 'pause_circle_outline',
      text: 'Finish Stream',
      handler: handleStreamFinish,
    },
    [false]: {
      icon: 'play_circle_outline',
      text: 'Start Stream',
      handler: handleStreamStart,
    },
  };

  const streamControl = streamControls[!!props.cookies.get('xircles-stream')];

  return (
    <li className="menu-account-button">
      <button onClick={streamControl.handler.bind(this, props)}>
        {streamControl.text}
      </button>
    </li>
  );
};

const handleReplayStreamButton = (props) => {

  const streamRecordedCookie = props.cookies.get('xircles-stream-recorded');

  const handleStreamReplay = (props) => {
    const streamID = props.cookies.get('xircles-stream-recorded');
    replayStream({ streamID });
  };

  return (
    streamRecordedCookie && <li className="menu-account-button">
      <button onClick={handleStreamReplay.bind(this, props)}>
        Replay Stream
      </button>
    </li>
  );
};

const getDefaultMenuOptions = ({ t, history, onToggleMenu }) => [
  {
    id: 'menu-about-xircle',
    text: `${t('MENU_ABOUT_XIRCLE')}`,
    className: 'menu-account-button',
    onClick: (e) => {
      e.preventDefault();
      history.push('/about');
      onToggleMenu();
    },
    target: '_top'
  },
  {
    id: 'menu-the-xircle-ia',
    text: `${t('MENU_THE_XIRCLE_IA')}`,
    href: `${t('MENU_THE_XIRCLE_IA_LINK')}`,
    className: 'menu-account-button'
  },
  {
    id: 'menu-jobs',
    text: `${t('MENU_JOBS')}`,
    href: `${t('MENU_JOBS_LINK')}`,
    className: 'menu-account-button'
  },
  {
    id: 'menu-team',
    text: `${t('MENU_TEAM')}`,
    href: `${t('MENU_TEAM_LINK')}`,
    className: 'menu-account-button'
  }
];

const handleLoginClick = (e, { isLoggedIn, onDisplayName, onLogin }) => isLoggedIn ? onDisplayName(e) : onLogin(e);
const handleLogoutClick = (e, { onLogout }) => {
  e.preventDefault();
  onLogout();
};

const Menu = (props: Props) => {
  const t = props.t;
  const filteredButtonAccountList = filterButtonAccountList(props);

  return (
    <Fragment>
      <div className={`menu-panel-overlay ${props.isMenuOpen ? 'menu-panel-overlay__shown' : ''}`} onClick={props.onToggleMenu} />
      <div className={`${props.className} ${isInsideFrame() ? 'menu-fixed' : ''} ${props.isMenuOpen ? 'open-menu' : 'close-menu'}`}>
        <div className="menu-button">
          <button id="close-menu-button" onClick={props.onToggleMenu}><i className="material-icons close">close</i></button>
        </div>
        <ul>
          {config.RegistrationEnabled && <li className="menu-account-button menu-login-button">
            <button id="menu-login" onClick={(e) => handleLoginClick(e, props)}>{props.isLoggedIn ? props.displayName : t('COMMON_MENU_LOGIN')}</button>
          </li>}
          {props.isLoggedIn && <li className="menu-account-button menu-logout-button">
            <button id="menu-logout" onClick={(e) => handleLogoutClick(e, props)}>{t('MENU_LOGOUT')}</button>
          </li>}
          {
            filteredButtonAccountList.map((button, number) => (
              button.id === 'account-predefined-answers'
                ?
                <PredefinedAnswers
                  {...button}
                  key={number}
                />
                :
                <MenuButton
                  {...button}
                  key={number}
                />
            ))
          }
          {config.TestRecorderEnabled && handleStreamButtons(props)}
          {config.TestRecorderEnabled && handleReplayStreamButton(props)}
        </ul>
        <footer>
          <div dangerouslySetInnerHTML={{ __html: t('MENU_FOOTER_DESCRIPTION', { year: new Date().getFullYear() }) }} />
        </footer>
      </div>
    </Fragment>
  );
};

Menu.defaultProps = {
  buttonAccountList: [],
  buttonContentList: [],
  className: 'menu-panel',
};

export default withCookies(withTranslation()(Menu));