// @flow

import React from 'react';
import './style.less';

type Props = {
	title: string,
};

const Tag = (props: Props) => (
	<div className="tag">{props.title}</div>
);

export default Tag;
