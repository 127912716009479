// @flow

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import Layer from '@layerhq/web-xdk';
import { debounce, isDotSlash, isFirefox, isUtf8 } from '../../utils/common';
import { getSuggestionsByAutocomplete, getSuggestionsByTags, spellCheck } from '../../utils/api';
import { SUGGEST_MODES } from '../../const/suggest-modes';
import { SPELL_CHECK_MODES } from '../../const/spell-check-modes';
import LoadingSpinner from '../LoadingSpinner';
import config from '../../config.json';

import './styles.less';

import AutoSuggest from '../AutoSuggest';
import { KEY_CODES } from '../../const';

import {isHcpOrOperator} from '../../utils/user';

const IsDisabledAutoSuggestForNonHCP = config.IsDisabledAutoSuggestForNonHCP;

type Props = {};

type State = {};

class SearchArea extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            isSearchInputFocused: false,
            isSearchInputVisible: false,
            searchInput: '',
            suggestionList: [],
        };

        this.search = React.createRef();
        this.searchInput = React.createRef();

        this.autoCompleteSearchLength = 0;
        this.minLengthStartSearch = 3;
        this.recognizedTags = [];
    }

    t = this.props.t;

    componentDidMount() {
        const conversationName = _get(this.props.location, 'state.detail.conversationName');
        const { action } = this.props.history;

        if ((action === 'REPLACE' || action === 'PUSH') && conversationName) {
            this.setState({ searchInput: conversationName, isSearchInputFocused: true }, () => setTimeout(() => {
                this.searchInput.current && this.searchInput.current.focus();
                this.handleSuggestionsByAutocomplete(conversationName);
                }, 0)
            );
        }

        window.addEventListener('resize', this.handleResize);
        document.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'mousedown', this.handleMousedown);

        if (this.search && this.search.current) {
            this.search.current.addEventListener('click', this.handleSearchAreaClick);
        }
    }

    componentDidUpdate(_prevProps, prevState) {
        if (!_isEqual(prevState.isSearchInputFocused, this.state.isSearchInputFocused) && document.body.clientWidth <= 767) {
            this.handleDisableBodyScroll(this.state.isSearchInputFocused);
        }
    }

    componentWillUnmount() {
        document.body.removeAttribute('style');
        clearAllBodyScrollLocks();
        window.removeEventListener('resize', this.handleResize);
        this.search.current.removeEventListener('click', this.handleSearchAreaClick);
        document.removeEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'mousedown', this.handleMousedown);
    }

    handleSearchAreaClick = () => {
        const { isDataPrivacyMode, isDataPrivacyAgreed, isTermsOfUseAgreed } = this.props;
        if (isDataPrivacyMode && (!isDataPrivacyAgreed || !isTermsOfUseAgreed)) {
            const basePath = this.props.history.location.pathname;
            const normalizedBasePath = basePath === '/' ? '' : basePath;
            this.props.history.push(normalizedBasePath + '/data-privacy');
        }
    };

    handleDisableBodyScroll = (isSearchInputFocused) => {
        if (isSearchInputFocused) {
            setTimeout(() => window.scrollTo(0, 0), 0);
            setTimeout(() => this.setState({ isSearchInputVisible: true }), 0);
            document.body.style.overflowY = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.left = 0;
            document.body.style.right = 0;
        } else {
            this.setState({ isSearchInputVisible: false })
            document.body.style.overflowY = 'auto';
            document.body.style.position = 'initial';
            document.body.style.left = 'unset';
            document.body.style.right = 'unset';
        }
    };

    validateSubmit = (input) => {
        const lineWithoutSpaces = input.replace(/\s/g, '');

        const conditions = [
            isUtf8(lineWithoutSpaces),
            lineWithoutSpaces.length > 2,
            isDotSlash(lineWithoutSpaces),
            // isEmptyBytes(lineWithoutSpaces),
        ];

        const isInputValid = conditions.every((condition) => condition === true);
        return isInputValid;
    };

    handleResize = (e) => {
        if (this.state.isSearchInputFocused && document.body.clientWidth <= 767 && !this.state.isSearchInputVisible) {
            this.setState({ isSearchInputVisible: true });
        }
    };

    handleMousedown = (e) => {
        this.handleSearchInputOutsideClick(e);
    };

    handleSearchInputKeyDown = (e, question) => {
        if (this.validateSubmit(question) && e.keyCode === KEY_CODES.ENTER) {
            this.postQuestion(question);
        }
    };

    handleSearchButtonClick = (question) => {
        if (this.validateSubmit(question)) {
            this.postQuestion(question);
        }
    };

    handleClearSearchInput = () => {
        this.setState({ searchInput: '', isSearchInputFocused: true }, () => this.searchInput.current.focus());
    };

    handleSearchInputOutsideClick = (e) => {
        if (!this.search.current.contains(e.target)) {
            this.setState({ isSearchInputFocused: false })
            this.searchInput.current.blur();
        }
    };

    handleBackButtonClick = () => {
        this.setState({ isSearchInputFocused: false, isSearchInputVisible: false });
        this.searchInput.current.blur();
    };

    setSuggestionsByAutocomplete = ({ data: { by_title, by_tags } }) => (
        this.setState({
            suggestionList: [
                ...by_title.map((current) => ({ title: current })),
                ...by_tags,
            ],
            suggestionsLoaded: true
        })
    );

    setSuggestionsByTags = ({ data: { suggestions } }) => {
        const preparedSuggestions = suggestions.reduce(
            (acc, title) => acc.indexOf(title) === -1 ? acc.concat(title) : acc,
            [],
        );

        if (!_isEmpty(this.recognizedTags)) {
            this.setState({ suggestionList: preparedSuggestions });
        }
    };

    handleSuggestionsByAutocomplete(title) {
        const titleLength = title ? title.trim().length : 0;
        const isSearch = title && title.length >= this.minLengthStartSearch && this.autoCompleteSearchLength !== titleLength;
        
        this.autoCompleteSearchLength = titleLength;
        return isSearch && getSuggestionsByAutocomplete(title, this.props.brandName, this.props.documentId)
        .then((data) => this.setSuggestionsByAutocomplete(data))
        .catch(error => console.error('Autocomplete API error', error.message));
    }

    handleSuggestionsByTag(title) {
        const tags = this.props.tags.filter(tag => title.toLowerCase().indexOf(tag.toLowerCase()) >= 0);
        const isSearchSuggestionsByTag = !_isEmpty(tags) && this.recognizedTags.length !== tags.length;

        this.recognizedTags = [...tags];

        if (_isEmpty(this.recognizedTags)) {
            this.setState({ suggestionList: [] });
        }

        return isSearchSuggestionsByTag && getSuggestionsByTags(tags, this.props.brandName, this.props.documentId).then((data) => this.setSuggestionsByTags(data));
    }

    handleGetSuggestions = (title) => (
        config.SuggestMode === SUGGEST_MODES.AUTOCOMPLETE
            ? this.handleSuggestionsByAutocomplete(title)
            : this.handleSuggestionsByTag(title)
    );

    handleChange = (searchInput) => {

        this.setState({ searchInput });

        if (searchInput < this.minLengthStartSearch && this.state.suggestionList.length > 0) {
            this.setState({ suggestionList: [] });
        }

        if (config.FeatureAutosuggestEnabled) {
            debounce(this.handleGetSuggestions(searchInput), 1000);
        }
    };

    applyCorrectionsToSearchRequest = (text, corrections) => {
        return corrections.reduce((acc, cur) => acc.replace(cur.token, cur.suggestion), text);
    };

    mapCorrections = (source) => {
        return Object.keys(source).reduce((acc, key) => {
            const corrections = source[key];

            if (Array.isArray(corrections)) {
                const autocorrections = corrections
                    .map((current) => ({ ...current, token: key }));
                return [...acc, ...autocorrections];
            }

            return acc;
        }, []);
    };

    setIsLoading = () => this.setState({ isLoading: true });

    postQuestion = async (text) => {
        this.setIsLoading();

        try {
            const { data } = await spellCheck({
                text,
                mode: [SPELL_CHECK_MODES.AUTOCORRECT],
            });
    
            if (!_isEmpty(data.corrections)) {
                const mappedCorrections = this.mapCorrections(data.corrections)
                const requestWithCorrections = this.applyCorrectionsToSearchRequest(text, mappedCorrections);
                this.props.onPostQuestion(requestWithCorrections, mappedCorrections, this.state.searchInput);
            } else {
                this.props.onPostQuestion(text);
            }
        } catch(error) {
            console.error('Spellcheck API error', error.message)
        }
    }

    isAutoSuggestEnabled = () => !IsDisabledAutoSuggestForNonHCP || isHcpOrOperator(Layer.client.user);

    render() {
        const {
            isDataPrivacyMode,
            isDataPrivacyAgreed,
            isTermsOfUseAgreed,
            documentId
        } = this.props;
        const {searchInput, isLoading, isSearchInputFocused, suggestionList, isSearchInputVisible} = this.state;
        const isSearchInputDisabled = isLoading || (isDataPrivacyMode && (!isDataPrivacyAgreed || !isTermsOfUseAgreed));
        return (
            <div
                ref={this.search}
                id="search-area"
                className={`search-area ${isSearchInputFocused ? 'search-area--focused' : ''} ${isSearchInputVisible ? 'search-area--visible' : ''}`}
            >
                <div className="search-area__navigation">
                    <button className="search-area__back-button" onClick={this.handleBackButtonClick}>
                        <i className="material-icons">arrow_back</i>
                    </button>
                </div>
                <input
                    id="search-input"
                    className={`search-area__input ${isSearchInputFocused ? 'search-area__input--focused' : ''} ${isSearchInputDisabled ? 'search-area__input--disabled' : ''}`}
                    disabled={!isFirefox() && isSearchInputDisabled}
                    readOnly={isSearchInputDisabled}
                    type="text"
                    autoComplete="off"
                    ref={this.searchInput}
                    placeholder={this.t('QUESTION_PLACEHOLDER')}
                    value={searchInput}
                    onChange={(e) => this.handleChange(e.target.value)}
                    onClick={() => {
                        if (!isSearchInputFocused && !isSearchInputDisabled) {
                            this.setState({ isSearchInputFocused: true });
                        }
                    }}
                    onKeyDown={(e) => this.handleSearchInputKeyDown(e, searchInput)}>
                </input>
                {searchInput.length > 0 && <button className="search-area__clear-search-button" disabled={isSearchInputDisabled} onClick={this.handleClearSearchInput}>
                    <i className="material-icons">clear</i>
                </button>}
                <button className="search-area__button" disabled={isLoading || isSearchInputDisabled} onClick={() => this.handleSearchButtonClick(searchInput)}>
                    {isLoading && <LoadingSpinner className="search-area__button--loading" />}
                    {!isLoading && <i className="material-icons">search</i>}
                </button>
              
                {isSearchInputFocused && 
                      <AutoSuggest
                        {...this.props}
                        searchInput={searchInput}
                        suggestions={suggestionList}
                        minLengthStartSearch={this.minLengthStartSearch}
                        onLoading={this.setIsLoading}
                        documentId={documentId}
                        isAutoSuggestEnabled={this.isAutoSuggestEnabled()}
                        // documentIds={documents && this.getDocumentIds(documents)}
                      />                      
                }
            </div>
        );
    }

}

export default withTranslation()(SearchArea);