import Layer from '@layerhq/web-xdk';
import './simple-answer-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-simple-answer-message-view', {
  mixins: [MessageViewMixin],

  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
      }
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container'
    }
  },
  methods: {
    getPreferredMinWidth() {},
    onAfterCreate() {
      document.dispatchEvent(new CustomEvent('tr-custom-event', { detail: { conversationId: this.model.message.conversationId, name: 'simple-answer-result' } }));
    },
    onRerender() {
      const view = this;
      view.model.items.forEach((item) => {
        if (item.typeLabel.toLowerCase() === 'text') {
          view.createElement('layer-text-message-view', {
            model: item,
            messageViewContainerTagName: false,
            cardBorderStyle: 'none',
            parentNode: this
          });
        } else if (item.typeLabel.toLowerCase() === 'image') {
          view.createElement('layer-image-message-view', {
            model: item,
            messageViewContainerTagName: false,
            cardBorderStyle: 'none',
            parentNode: this
          });
        } else {
          view.createElement('xircles-reference-message-view', {
            model: item,
            messageViewContainerTagName: false,
            cardBorderStyle: 'none',
            parentNode: this
          });
        }
      });
    }
  }
});