// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import './styles.less';

const ProductCard = (props: Props) => (
	<div className="product-card" onClick={() => props.onProductCardClick(props.brand_slug)}>
		<p className="product-card__name">
			<a
				rel="canonical"
				href={`${props.appUrl}/medikamente/${props.brand_slug}`}
				onClick={(e) => {
					if (!props.isLinkClickAllowed) {
						e.preventDefault();
					}
				}}
			>
				{props.name}
			</a>
		</p>
		<p className="product-card__compound_name">{props.compoundName}</p>
		<div className="product-card__information">
			{props.isManufactureVisible && props.companiesNum && <span className="product-card__manufactures">{`${props.companiesNum} ${props.t('COMMON_MANUFACTURERS')}`}</span>}
			{props.documentsNum && <span className="product-card__technical-informations">{`${props.documentsNum} ${props.t('COMMON_TECHNICAL_INFORMATIONS')}`}</span>}
		</div>
	</div>
);

export default withTranslation()(ProductCard);
