// @flow

import React from 'react';
import TextField from '../../../material-components/TextField';
import Button from '../../../material-components/Button';
import AutoFocusInput from '../../AutoFocusInput';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  onPhoneNumberOrEmailChange: Function,
  onShare: Function,
  t: Function,
};

function ShareModalFragment({
  phoneNumberOrEmail,
  onPhoneNumberOrEmailChange,
  onShare,
  t
}: Props) {
  return (
    <div className={'share-conversation'}>
      <Box textAlign="center" className={'share-conversation--subtitle'}>
        <small>{t('MODAL_SHARE_CONVERSATION_SUBTITLE')}</small>
      </Box>
      <TextField dense outlined>
        <AutoFocusInput
          id="email"
          name="email"
          type="text"
          style={{ textAlign: 'center' }}
          placeholder={t('LOGIN_MOBILE_OR_EMAIL_PLACEHOLDER')}
          value={phoneNumberOrEmail}
          onChange={e => onPhoneNumberOrEmailChange(e.target.value)}
        />
      </TextField>
      <Box mt={2}>
        <Button id="share-modal" data-keystroke outlined onClick={onShare}>{t('COMMON_SEND')}</Button>
      </Box>
    </div>
  );
}

export default withTranslation()(ShareModalFragment);
