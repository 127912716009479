const ConversationListMixin = {
	methods: {
		onRerender: function () {
			var evt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

			if (this.query.isDestroyed) {
				this.isEmptyList = false;
			} else {
				setTimeout(() => {
					const filteredLayerItems = this.querySelectorAllArray('.layer-item-filtered').length;
					this.isEmptyList = evt.type !== 'reset' && (this.query.data && (this.query.data.length === 0 || filteredLayerItems === this.query.data.length));
				}, 0);
			}
		}
	}
};

export default ConversationListMixin;
