// @flow

import React from 'react';
import MobileDetect from 'mobile-detect/mobile-detect';
import { Input } from '../../material-components/TextField';
import { isInsideFrame, isMobileDevice } from '../../utils/common';

type Props = {}

type State = {}

const DELAY_TIME_FOR_ANIMATION = 400;

class AutoFocusInput extends React.Component<Props, State> {
  input = null;
  autocompleteDisabled = false;

  componentWillMount() {
    const tabletsWithoutAutocomplete = ['HuaweiTablet', 'SamsungTablet', 'UnknownTablet'];

    const md = new MobileDetect(window.navigator.userAgent);
    if (tabletsWithoutAutocomplete.includes(md.tablet())) {
      this.autocompleteDisabled = true;
    }
  }

  componentDidMount() {
    const inputElement = this.input.inputElement;

    if (isMobileDevice() && isInsideFrame()) {
      window.parentIFrame.autoResize(false);
    }

    if (inputElement && !isMobileDevice()) {
      setTimeout(() => inputElement.focus(), DELAY_TIME_FOR_ANIMATION);
    }

    if (inputElement && this.autocompleteDisabled) {
      inputElement.setAttribute('autocomplete', 'off');
    }
  }

  render() {
    return (
      <Input
        ref={input => this.input = input}
        {...this.props}
      />
    );
  }
}

export default AutoFocusInput;
