// @flow

/* global DSScanner */
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom'
import MaterialIcon from '@material/react-material-icon';

import { CHOICE_ACTION_EVENTS } from '../../../const/choice-actions';
import IconButton from '../../../material-components/IconButton';
import Dialog, { DialogTitle, DialogContent } from '../../../material-components/Dialog';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import config from '../../../config.json';

import efnLarge from '../../../images/efn@3x.png';

type Props = {
  isOpen: boolean,
  onToggle: Function,
  onDetected: Function,
  conversationId: string;
};

type State = {
  isError: boolean;
  stream: any;
};

class ScanEFNModal extends React.Component<Props, State> {
  state = {
    isError: false,
  };

  successEvent: string = CHOICE_ACTION_EVENTS.scanefn_success;
  canceledEvent: string = CHOICE_ACTION_EVENTS.scanefn_cancel;

  handleOnCloseMobile = () => {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener('click', (ev) => this.props.isOpen && this.onClose(ev));
  };

  renderCloseButton() {
    return (
      <div style={{position: 'absolute', top: '-11px', right: '0px', color: 'xSlateLighter' }}>
        <IconButton
          id="scan-efn-close"
          size="small"
          className="closeBtn"
          onClick={this.onClose}
        >
          <MaterialIcon icon="clear" />
        </IconButton>
      </div>
    );
  }

  onBarcodeReady = (barcodeResult) => {
    const [codedData] = barcodeResult;

    const bytesView = new Uint8Array(codedData.data);
    const decodedData = new TextDecoder().decode(bytesView);

    const validDecodedDataLength = 15;
    if (decodedData.length >= validDecodedDataLength) {
      this.props.history.push(`/questions/${this.props.conversationId}`);
      window.dispatchEvent(new CustomEvent(this.successEvent, { detail: decodedData }));
    }
  };

  showLicenseInfo = () => {
    // const li = DSScanner.getLicenseInfo();
    // console.log(JSON.stringify(li));
  };

  createScanner = () => {
    const scannerSettings = {
      scanner: {
        key: config.BarcodeScannerKey,
        beep: false,
      },
      viewport: {
        id: 'datasymbol-barcode-viewport',
        width: 640,
        height: 360,
      },
      camera: {
        id: null,
        label: null,
        facingMode: 'environment',
        resx: 640,
        resy: 360,
      },
      barcode: {
        barcodeTypes: ['Code128', 'Code39'],
      },
    };

    DSScanner.addEventListener('onBarcode', this.onBarcodeReady);
    DSScanner.addEventListener('onScannerReady', () => {
      DSScanner.StartScanner();
      this.showLicenseInfo();
    });

    DSScanner.Create(scannerSettings);
  };

  componentDidMount() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        this.setState({ stream });
        this.createScanner();
      })
      .catch(() => this.setState({ isError: true }));

    this.handleOnCloseMobile();
  }

  componentWillUnmount() {
    const { stream } = this.state;

    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }

    DSScanner.Destroy();
  }

  onClose = (ev) => {
    const { onToggle } = this.props;

    window.dispatchEvent(new Event(this.canceledEvent));
    onToggle(ev);
  }

  render() {
    const { isOpen, t } = this.props;
    const { isError } = this.state;

    return (
      <Dialog className={`xircles-scan-efn-dialog ${isError ? 'xircles-scan-efn-dialog--error' : ''}`} open={isOpen} onClose={this.onClose}>
        {!isError ? (
          <Fragment>
            <div id="datasymbol-barcode-viewport" tabIndex={0} />
            <Box textAlign="center">
              {t('MODAL_PLACE_EFN_BARCODE')} <br />
              {t('MODAL_PHYSICIAN_ID_FRONT_CAMERA')}
            </Box>
            <img src={efnLarge} width="230" height="144" alt="efn" />
          </Fragment>
        ) : (
          <Fragment>
            <DialogTitle style={{textAlign: 'center'}}>
              {t('COMMON_INFORMATION')}
              {this.renderCloseButton()}
            </DialogTitle>
            <DialogContent>
              <Box textAlign="center" mt={4} mb={4}>
                <Box fontSize="h5.fontSize">{t('MODAL_USE_DEVICE_WITH_CAMERA')}</Box>
              </Box>
              <Box textAlign="center">
                <Button id="scan-efn-ok" data-keystroke outlined onClick={this.onClose}>{t('COMMON_OK')}</Button>
              </Box>
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(ScanEFNModal));