// @flow

import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import _values from 'lodash/values';

import { isSelectDrugRoute } from '../utils/common';
import SelectModal from '../components/Modal/SelectModal';

type Props = {};

type State = {};

export const SELECT_MODAL_PATHS = {
    SELECT_DRUG: '*/select-drug*'
};

const SELECT_MODAL_PATHS_ARRAY = _values(SELECT_MODAL_PATHS);

class SelectModalContainer extends React.Component<Props, State> {

    isSelectModalUrl = (url) => (
        [
            isSelectDrugRoute(url)
        ].some((route) => !!route)
    );

    handleToggle = (history, match) => {
        // handle direct link to the page
        if (history.length <= 2) {
            const baseUrl = match.url.replace(/\/(select-drug)/, '');
            history.replace(baseUrl);
        } else {
            history.goBack();
        }
    };

    render() {
        return (
            <Route
                path={SELECT_MODAL_PATHS_ARRAY}
                render={({ match, history }) => {
                    const regexMatch = this.isSelectModalUrl(match.url);

                    return (
                        <SelectModal
                            isOpen={regexMatch}
                            matchPath={match.path}
                            onToggle={() => this.handleToggle(history, match)}
                        />
                    )
                }}
            />
        );
    }
}

export default withRouter(SelectModalContainer);
