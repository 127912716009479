import React from 'react';
import StartPage from '../components/StartPage';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';
import conversationManager from '../hocs/conversationManager';
import config from '../config.json';
import { normalizePathname } from '../utils/common';
import logout from '../common/logout';

const StartPageContainer = (props) => (
    <LayerContext.Consumer>
        {({ layerClient, displayName, isLoggedIn, onLoginAnonymously }) => (
            <CommonContext.Consumer>
                {({ onCurrentActionChange }) => (
                    <StartPage
                        {...props}
                        layerClient={layerClient}
                        isLoggedIn={isLoggedIn}
                        displayName={displayName}
                        onLogin={(e) => {
                            e.preventDefault();
                            props.history.push(normalizePathname(props.history.location.pathname) + '/login');
                        }}
                        onDisplayName={(e) => {
                            e.preventDefault();
                            const redirectPath = config.UserManagementEnabled ? '/my-account' : '/logout';
                            props.history.push(normalizePathname(props.history.location.pathname) + redirectPath);
                        }}
                        onToggleMenu={() => onCurrentActionChange('menu')}
                        onLogout={() => logout().then(() => onCurrentActionChange(null)).then(onLoginAnonymously)}
                    />
                )}
            </CommonContext.Consumer>
        )}
    </LayerContext.Consumer>
);

const StartPageContainerWithConversationManager = conversationManager(StartPageContainer);
export default StartPageContainerWithConversationManager;