import React from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import config from '../../config.json';
import openGraphImage from '../../images/openGraphImage.png';
import logo from '../../images/logo.png';
import { getSafeHtml } from '../../utils/common';

const getHelmetStaticSettings = (t) => ({
  title: t('COMMON_TITLE_CLAIM'),
  description: t('COMMON_DESCRIPTION'),
});

const mapDocumentParams = (documentTitle) => ({
  title: documentTitle, 
  description: documentTitle
});

const mapConversationParams = ({ metadata: { conversationName }}, product, helmetStaticSettings) => ({
  title: product ? `${product} • ${conversationName}` : conversationName,
  description: helmetStaticSettings.description,
});

const mapProductParams = (product, helmetStaticSettings, t) => ({
  title: `${product} • ${t('COMMON_TITLE_CLAIM')}`,
  description: `${product} • ${helmetStaticSettings.description}`,
});

const HelmetWrapper = ({
  location,
  product,
  documentTitle,
  conversation,
  t,
}) => {
  const helmetStaticSettings = getHelmetStaticSettings(t);
  const documentTitleSafe = getSafeHtml(documentTitle, true).replace(/&nbsp;/gi,'');
  const helmetSetting = documentTitleSafe
    ? mapDocumentParams(documentTitleSafe)
    : conversation
      ? mapConversationParams(conversation, product, helmetStaticSettings)
      : product
        ? mapProductParams(product, helmetStaticSettings, t)
        : helmetStaticSettings;

  const metaTags = [
    { name: 'description', content: helmetSetting.description },
    { name: 'og:title', content: helmetSetting.title },
    { name: 'og:description', content: helmetSetting.description },
    { name: 'og:image', content: openGraphImage }
  ];

  const disableIndex = { name: 'robots', content: 'none' };

  const metaTagsWithDisableCheck = config.ShowOnGoogle
    ? metaTags
    : [...metaTags, disableIndex];

  return (
    <Helmet
      defaultTitle='Xircle'
      titleTemplate='Xircle • %s'
      title={helmetSetting.title}
      meta={metaTagsWithDisableCheck}
      link={[
        { rel: 'apple-touch-icon', href: logo },
        { rel: 'canonical', href: location ? `${config.XirclesUrl}${location}` : config.XirclesUrl
        }
      ]}
    />
  );
};

export default withTranslation()(HelmetWrapper);
