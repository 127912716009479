import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel } = Layer.Core;

class OptimiseItTextMessageModel extends MessageTypeModel {
  parseModelChildParts({ changes, isEdit }) {
    super.parseModelChildParts({ changes, isEdit });
    this.items = this.getModelsByRole('results');
  }
}

OptimiseItTextMessageModel.prototype.items = null;

OptimiseItTextMessageModel.MIMEType = 'application/xircles.optimise-it-text+json';
OptimiseItTextMessageModel.SummaryTemplate = '${textFound}'; // eslint-disable-line no-template-curly-in-string
OptimiseItTextMessageModel.LabelSingular = 'Optimise IT Text';
OptimiseItTextMessageModel.messageRenderer = 'xircles-optimise-it-text-message-view';

Root.initClass.apply(OptimiseItTextMessageModel, [OptimiseItTextMessageModel, 'OptimiseItTextMessageModel']);
Client.registerMessageTypeModelClass(OptimiseItTextMessageModel, 'OptimiseItTextMessageModel');

export default OptimiseItTextMessageModel;
