// @flow

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ProductCard from '../ProductCard';

type Props = {};
type State = {};

const getCategorizedProducts = (products, t) => {
  const categoriesArr = products.reduce((acc, product) => {
    return [...acc, ...product.categories_translationkeys];
  }, []);
  const categories = Array.from(new Set(categoriesArr))
    .map(category => ({
      translationKey: category,
      title: t(category),
    }))
    .sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
    .map(({ translationKey, title }) => ({
      title,
      products: products.filter((product) => product.categories_translationkeys.includes(translationKey)),
    }));
  return categories;
}

class DrugsCategory extends Component<Props, State> {
  render () {
    const { products, t, appUrl, onProductCardClick } = this.props;
    const categories = getCategorizedProducts(products, t);
    return categories.map(({ title, products }) => (
      <div className="start-page__products" key={title}>
        <span className="start-page__products category-title">
          {title}
        </span>
        {products.map((product, index) => (
          <ProductCard
            size="small"
            id={`product-${index}`}
            key={`product-${index}`}
            name={product.brand_name}
            appUrl={appUrl}
            brand_slug={product.brand_slug}
            companiesNum={product.num_companies}
            documentsNum={product.num_documents}
            compoundName={product.molecule_names.join(', ')}
            isManufactureVisible={false}
            onProductCardClick={onProductCardClick}
          />
        ))}
      </div>
    ));
  }
}

export default withTranslation()(DrugsCategory);