import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../../material-components/Button';

import './styles.less';

const TermsOfUseFragment = ({
    t,
    onRetry,
}) => (
    <Fragment>
        <p className="terms-of-use__message">{t('MODAL_TERMS_OF_USE_1')}</p>
        <p className="terms-of-use__message">{t('MODAL_TERMS_OF_USE_2')}</p>
        <Button outlined onClick={onRetry}>
          {t('COMMON_CLOSE')}
        </Button>
    </Fragment>
);

export default withTranslation()(TermsOfUseFragment);
