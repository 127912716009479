export const browserValidation = (phonesArray, isValidAndroidVersion, isValidSamsungBrowser) => (
  {
    desktop: {
      chrome: '>=57',
      safari: '>=10',
      edge: '>=16',
      firefox: '>45'
    },
    tablet: (phonesArray[1] || phonesArray[3]) && isValidAndroidVersion && !isValidSamsungBrowser ?
      {
        chrome: '>=0'
      } :
      phonesArray[1] && isValidSamsungBrowser ?
        {
          'samsung_internet': '>=0'
        }
        :
        {
          iOS: {
            safari: '>=12.4',
            chrome: '>=75'
          },
          macOS: {
            safari: '>=12.4',
            chrome: '>=75'
          }
        },
    mobile: (phonesArray[0] || phonesArray[2]) && isValidAndroidVersion && !isValidSamsungBrowser ?
      {
        chrome: '>=0'
      } :
      phonesArray[0] && isValidSamsungBrowser ?
        {
          'samsung_internet': '>=0'
        }
        :
        {
          iOS: {
            safari: '>=12.4',
            chrome: '>=75'
          },
          macOS: {
            safari: '>=12.4',
            chrome: '>=75'
          },
        }
  }
)