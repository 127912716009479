// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';

import config from '../../../config.json';

type Props = {
  onLogout: Function,
  onCancel: Function,
  t: Function
};

function LogoutModalFragment({
  onLogout,
  onCancel,
  t,
}: Props) {
  return (
    <React.Fragment>
      <Box mt={0} textAlign="center">
        {config.RegistrationEnabled ? t('LOGOUT_TEXT') : t('LOGOUT_DOCCHECK_TEXT')}
      </Box>
      <Box mt={3}>
        <Button id="logout-success" data-keystroke outlined style={{ width: '100%' }} onClick={onLogout}>{t('LOGOUT_BUTTON')}</Button>
      </Box>
      <Box mt={1}>
        <Button id="logout-cancel" outlined style={{ width: '100%' }} onClick={onCancel} color="secondary" focused='false'>{t('LOGOUT_CANCEL_BUTTON')}</Button>
      </Box>
    </React.Fragment>
  );
}

export default withTranslation()(LogoutModalFragment);
