export default {
  NOT_FINISHED: 'not_finished',
  REFRAMED: 'reframed',
  FORWARD_REQUESTED: 'forward_requested',
  FORWARDED: 'forwarded',
  ANSWER_DELIVERED: 'answer_delivered',
  HELPFUL: 'helpful',
  CLOSED: 'closed',
  DELETED: 'deleted',
  TAKEOVER: 'takeover',
  ARCHIVED: 'archived',
  EXPERT_POSITIVE: 'expert_positive',
  EXPERT_NEGATIVE: 'expert_negative'
};
