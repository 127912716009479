import React from 'react';

const ScrollPaneWrapper = (props) => (
  <div className="scrollPane-wrapper">
    <div className="scrollPane-content">
      <button id="scroll-back" data-tr-event className="scroll-back" onClick={props.handleScrollBack}><i className="material-icons">keyboard_arrow_left</i></button>
      <button id="scroll-next" data-tr-event className="scroll-next" onClick={props.handleScrollNext}><i className="material-icons">keyboard_arrow_right</i></button>
      {props.children}
    </div>
  </div>  
);

export default ScrollPaneWrapper;
