export const CHOICE_ACTIONS = {
  LOGIN: 'login',
  CANCEL: 'cancel',
  DOCCHECK: 'doccheck',
  REFRAME: 'reframe',
  SCANEFN: 'scanefn',
  NEED_MORE_INFO: 'need-more-info',
  CONFIRM_REGISTER: 'confirm_register',
};

export const AUTOSTART_ACTIONS = [{
  actionId: CHOICE_ACTIONS.CONFIRM_REGISTER,
  disableMessage: 'Perfect!',
}];

export const CHOICE_ACTION_EVENTS = {
  cancel: 'xircles-choice-cancel',
  reframe: 'xircles-choice-reframe',
  login: 'xircles-auth-requested',
  login_cancel: 'xircles-auth-canceled',
  login_success: 'xircles-auth-succeeded',
  doccheck: 'xircles-doccheck-auth-requested',
  doccheck_cancel: 'xircles-doccheck-auth-canceled',
  doccheck_success: 'xircles-doccheck-auth-succeeded',
  scanefn: 'xircles-scanefn-auth-requested',
  scanefn_cancel: 'xircles-scanefn-auth-canceled',
  scanefn_success: 'xircles-scanefn-auth-succeeded',
  confirm_register: 'xircles-confirm_register-requested',
  confirm_register_cancel: 'xircles-confirm_register-canceled',
  confirm_register_success: 'xircles-confirm_register-succeeded',
};
