// TODO: unify use of polyfills across the whole app
// TODO: Only include for IE 11
import './array-find';
import './array-from';
import './array-includes';
import './element-closest';
import './element-remove';
import 'promise-polyfill/src/polyfill';
import './string-includes';
import 'es6-shim';
import 'core-js/fn/symbol/iterator.js';
import 'core-js/es6/symbol.js';
import './nodelist-foreach';
import './classList-add-multiple-arguments'
import { hexToHSL } from '../utils/common';

import cssVars from 'css-vars-ponyfill';
import assign from 'es6-object-assign';

const request = require('sync-request');
const appConfig = require('../config.json');
const spaceMe = 'spaces/me';

function getThemeSettingsWithoutRedirection() {
  const settingsObj = request('GET', `https://api.storyblok.com/v1/cdn/${spaceMe}?token=${appConfig.StoryblokAccessToken}&from_release=${appConfig.ReleaseId}`)
  const cacheVersion = settingsObj.body.match(/"version":(\d+)/)[1];
  const res = request('GET', `${appConfig.StoryblokThemeUrl}?cv=${cacheVersion}&token=${appConfig.StoryblokAccessToken}&from_release=${appConfig.ReleaseId}`)
  const { story: { content } } = JSON.parse(res.getBody('utf8'));

  return content
}

const themeSettings = getThemeSettingsWithoutRedirection();
const primaryColorInHSL = hexToHSL(themeSettings.primaryColor);

const variables = {
  '--mdc-theme-primary': themeSettings.primaryColor,
  '--x-first-lighter': `hsl(${primaryColorInHSL.h}, 67%, 92%)`,
  '--mdc-theme-secondary': '#494d5b',
  '--mdc-theme-background': '#fff',
  '--x-slate-light': '#aaacb2',
  '--x-slate-lighter': '#f0f1f3',
  '--dialog-content-color': '#494d5b',
  '--shadow-default': 'rgba(0, 0, 0, 0.21)',
  '--mdc-text-field-border-radius': '3px'
};

cssVars({
  rootElement: document,
  onlyLegacy: true,
  preserveStatic: true,
  variables,
})

assign.polyfill();

export default themeSettings;