// @flow
import React from 'react';
import TextField, { Input } from '../../../material-components/TextField';
import AutoFocusInput from '../../AutoFocusInput';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  passwordNew: string | null,
  passwordRepeat: string | null,
  onPasswordNewChange: Function,
  onPasswordRepeatChange: Function,
  onLogin: Function,
  t: Function
};

function CreatePasswordSubmitModalFragment({
  phoneNumberOrEmail,
  passwordNew,
  passwordRepeat,
  onPasswordNewChange,
  onPasswordRepeatChange,
  onLogin,
  t
}: Props) {
  return (
    <React.Fragment>
      <Box fontSize="htmlFontSize" textAlign="center" mt={0.75} mb={1.75}>{`${t('LOGIN_PASSWORD_SUBMIT_TEXT')}`}</Box>
      <TextField dense outlined style={{ width: '100%' }}>
        <AutoFocusInput
          id="passwordNew"
          name="password"
          type="password"
          style={{ textAlign: 'center' }}
          placeholder={`${t('LOGIN_NEW_PASSWORD_PLACEHOLDER')}`}
          value={passwordNew}
          onChange={e => onPasswordNewChange(e.target.value)}
        />
      </TextField>

      <Box mt={1}>
        <TextField dense outlined style={{ width: '100%' }}>
          <Input
            id="passwordRepeat"
            name="password"
            type="password"
            style={{ textAlign: 'center' }}
            placeholder={`${t('LOGIN_REPEAT_PASSWORD_PLACEHOLDER')}`}
            value={passwordRepeat}
            onChange={e => onPasswordRepeatChange(e.target.value)}
          />
        </TextField>
      </Box>
      <Box mt={2}>
        <Button id="login-password-submit" data-keystroke outlined style={{ width: '100%' }} onClick={(e) => {
          e.preventDefault();
          return onLogin(e);
        }}>
          {t('LOGIN_PASSWORD_SUBMIT_LOGIN')}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default withTranslation()(CreatePasswordSubmitModalFragment);