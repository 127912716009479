import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

const IEWarningModalFragment = ({
  onContinue,
  t,
}) => (
  <Fragment>
    <Box fontSize="h5.fontSize" textAlign="center">
      {t('MODAL_IE_WARNING_TITLE')}
    </Box>
    <Box mt={1} textAlign="center">
      {t('MODAL_IE_WARNING_CONTENT')}
    </Box>
    <Box mt={2}>
      <Button outlined style={{ width: '100%' }} onClick={onContinue}>{t('COMMON_OK_UNDERSTAND')}</Button>
    </Box>  
  </Fragment>
);

export default withTranslation()(IEWarningModalFragment);
