import { layerClient } from '../get-layer';
import { loadSessionStore } from './loadSession';

export const sessionKey = 'xircles-last-user';
export const sessionStoreKey = `${sessionKey}_store`;

export default function persistSession() {
  const userId = layerClient.userId;
  if (userId) {
    localStorage.setItem(sessionKey, JSON.stringify({
      userId: layerClient.userId,
      displayName: layerClient.user.displayName,
      isHcp: layerClient.user.metadata.is_hcp === 'true',
    }));
  } else {
    localStorage.removeItem(sessionKey);
    localStorage.removeItem(sessionStoreKey);
  }
}
  
export function saveInSession(valuesObj) {
  const sessionStore = { ...loadSessionStore() || {}, ...valuesObj } ;
  localStorage.setItem(sessionStoreKey, JSON.stringify(sessionStore));
}

export function deleteFromSession(name) {
  const sessionStore = loadSessionStore();
  if (sessionStore && sessionStore[name]) {
    delete sessionStore[name];
    localStorage.setItem(sessionStoreKey, JSON.stringify(sessionStore));
  }
}
