// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

type Props = {
  successMessage: string,
  welcomeBackMessage: string,
  acknowledgeButton: string,
  onAcknowledge: Function,
};

function SuccessModalFragment({
  successMessage,
  acknowledgeButton,
  onAcknowledge,
}: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center" mt={2} mb={4}>
        <Box fontSize="h5.fontSize">{successMessage}</Box>
      </Box>
      <Box textAlign="center">
        <Button outlined onClick={onAcknowledge}>{acknowledgeButton}</Button>
      </Box>
    </React.Fragment>
  );
}

export default SuccessModalFragment;