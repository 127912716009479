import React from 'react';
import { withTranslation } from 'react-i18next';

import conversationManager from '../../hocs/conversationManager';
import './styles.less'
class AutoSuggest extends React.Component<Props, State> {

    componentWillReceiveProps(nextProps) {
        if (this.props.newConversationId !== nextProps.newConversationId) {
            this.props.history.push({
                pathname: `/questions/${nextProps.newConversationId}`,
                state: {
					detail: {
						prevLocation: this.props.history.location.pathname
					}
				}
            });
        }
    }

    handleSuggestionTitleContent = (title) => title.replace(/<[^>]*>?/gm, '');

    // do not use this function if it is needed to display compound tag/s
    removeTaggedData = (data) => data.replace(/<tagged>[\s\S]*<\/tagged>/g, '');

    getSuggestionValue = (target) => {
        const suggestion = target && target.getAttribute('value');
        return suggestion || this.getSuggestionValue(target.parentNode);
    };

    handleSuggestionClick = (e) => {
        if (e.target.tagName === 'UL'
            || e.target.nodeName === 'PLACEHOLDER'
            || Array.from(e.target.childNodes).find(c => c.nodeName === 'PLACEHOLDER')
        ) {
            return
        }
        this.props.onLoading();

        const suggestion = this.getSuggestionValue(e.target);

        if (suggestion) {
            document.dispatchEvent(new CustomEvent('tr-custom-event', {
                detail: {
                    suggestion,
                    name: 'autosuggest-submit',
                    drug: this.props.brandName,
                    location: window.location.href,
                    conversationId: '-',
                    elementId: '-',
                    question: '-',
                    text: '-',
                    id: '-',
                }
            }));
            this.props.createConversation(suggestion, {
                goBackPath: this.props.history.location.pathname,
                product: this.props.productName,
                selectedProductPage: this.props.productName,
                documentId: this.props.documentId
            });
        }
    };

    handleOpenDocCheck = (e) => {
        e.preventDefault();
        let { pathname } = this.props.history.location;
        if (pathname.endsWith('/')) pathname = pathname.slice(0, -1);
        this.props.history.push(pathname + '/doccheck');
    }

    render() {
        const { t, brandName, searchInput, minLengthStartSearch, suggestions, isAutoSuggestEnabled} = this.props;
        const showSuggestions = searchInput.length >= minLengthStartSearch && suggestions.length > 0 && isAutoSuggestEnabled;
        const firstExampleCustomized = brandName ? t('COMMON_AUTOSUGGEST_EXAMPLE_1').replace('{{productName}}', brandName) : t('COMMON_AUTOSUGGEST_EXAMPLE_1').replace('{{productName}}', t('COMMON_DEMO_BRAND_1'));
        const secondExampleCustomized = brandName ? t('COMMON_AUTOSUGGEST_EXAMPLE_2').replace('{{productName}}', brandName) : t('COMMON_AUTOSUGGEST_EXAMPLE_2').replace('{{productName}}', t('COMMON_DEMO_BRAND_2'));
        
        return (
            <div className="auto-suggest">
                {!showSuggestions && (
                    <ul className="auto-suggest__example">
                        <li>{t('COMMON_SEARCH_SPECIFIC_INFORMATION')}</li>
                        <li className="auto-suggest__question-example">{firstExampleCustomized}</li>
                        <li className="auto-suggest__ask-question">{t('COMMON_YOU_MAY_ASK_A_QUESTION')}</li>
                        <li className="auto-suggest__question-example">{secondExampleCustomized}</li>
                        {!isAutoSuggestEnabled &&
                            <li className="auto-suggest__doc-check">
                                <span>{t('COMMON_AUTOSUGGEST_TO_ENABLE_TEXT')}</span>
                                <button onClick={this.handleOpenDocCheck}>{t('COMMON_AUTOSUGGEST_TO_ENABLE_BUTTON')}</button>
                            </li>
                        }
                    </ul>
                )}
                {showSuggestions && (
                    <ul id="auto-suggest-search-results" className="auto-suggest__search-results" onClick={this.handleSuggestionClick}>
                        {suggestions.map((suggestion, index) => {                                  
                            return (
                            <li
                                key={`suggestion-${index}`}
                                className={`auto-suggest__search-result ${suggestion.title.includes('<placeholder>') && 'disabled'}`}
                                value={this.handleSuggestionTitleContent(this.removeTaggedData(suggestion.title))}
                                dangerouslySetInnerHTML={{ __html: suggestion.title }}
                            >
                            </li>
                        )})}
                    </ul>
                )}
            </div>
        )
    }
}

const AutoSuggestWithConversationManager = conversationManager(AutoSuggest);
export default withTranslation()(AutoSuggestWithConversationManager);
