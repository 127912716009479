import Core from '@layerhq/web-xdk/core/namespace';

const mixin = {
  lifecycle: {
    constructor() {
      this.telemetryEnabled = false;
    }
  }
};

Core.mixins.Client.push(mixin);

