// @flow

/**
 * GTM - Triggers
 *
 * name: Click on Question Input
 * fires on: Click element matches CSS selector #editable
 *
 * name: Click onSubmit request
 * fires on: Click element matches CSS selector i.xircles-icons.icon-send
 * fires on: Click element matches CSS selector button.send
 *
 * name: Question input blur
 * fires on: gtm.blur with Click Id equals editable
 */

import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _groupBy from 'lodash/groupBy';
import _debounce from 'lodash/debounce';
import ContentEditable from 'react-contenteditable';
import {disableBodyScroll} from 'body-scroll-lock';
import PullDown from '../PullDown';
import IconButton from '../../material-components/IconButton';
import { findDiff, isChromeBrowser, isConversationRoute, isIE, isInsideFrame, isMobileDevice } from '../../utils/common';
import { spellCheck } from '../../utils/api';
import { SUGGEST_MODES } from '../../const/suggest-modes';
import { SPELL_CHECK_MODES } from '../../const/spell-check-modes';
import LoadingSpinner from '../LoadingSpinner';
import config from '../../config.json';
import './style.less';

type Props = {
  contentEditableRef: any,
  tags: Array<string>,
  dictionaries: Array<Object>,
  suggestions: Array<string>,
  value: string,
  send: Function,
  showSubmit: Boolean,
  onChange: Function,
  onFocus: Function,
  onClick: Function,
  onKeyDown: Function,
  onSuggestionSelect: Function,
  innerRef: any,
};

type State = {
  showSuggestions: boolean,
  showPlaceholder: boolean,
};

// known issue: when typing new line, the coursor stays on the same line (in case if the latest word is highlighted)
// this is caused by `findLastTextNode` in `react-contenteditable` looking for the latest text node when a new html is received
// when we highlight the tags, it sends a new html to the child, so this is the case
// adjusting `node.nodeType === Node.TEXT_NODE` won't help
export default class ContentEdit extends Component<Props, State> {
  state = {
    showSuggestions: true,
    showPlaceholder: true,
  };

  caretOffset = 0;
  caretPosition = 0;
  minLengthForSearch = 3;

  isBlurred = false;
  isPasted = false;

  leftPanel = undefined;

  componentDidMount() {
    const [leftPanel] = document.getElementsByClassName('left-panel');
    this.leftPanel = leftPanel;

    const { innerRef } = this.props;
    innerRef.current.addEventListener('paste', this.handlePaste);
    innerRef.current.addEventListener('input', this.handleCaretPosition);
    innerRef.current.addEventListener('keyup', this.handleKeyUp);

    innerRef.current.setAttribute('spellcheck', 'false');
    innerRef.current.setAttribute('autocomplete', 'off');
    innerRef.current.setAttribute('autocorrect', 'off');
  }

  componentWillReceiveProps(props: Props) {
    if (this.props.isInsideWidget !== props.isInsideWidget && props.isInsideWidget) {
      disableBodyScroll(document.getElementById('editable'), { reserveScrollBarGap: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.normalizeString(prevProps.value).length !== this.normalizeString(this.props.value).length &&
      this.caretPosition &&
      !this.isBlurred &&
      !this.isPasted
    ) {
      this.setCaretPosition(this.caretPosition);
    }
  }

  componentWillUnmount() {
    const {innerRef} = this.props;
    innerRef.current.removeEventListener('paste', this.handlePaste);
    innerRef.current.removeEventListener('input', this.handleCaretPosition);
    innerRef.current.removeEventListener('keyup', this.handleKeyUp);
  }

  handleTimeAfterLoad = (e) => {
    if (window.performance) {
      const time = Math.round(performance.now());

      document.dispatchEvent(new CustomEvent('time-measure', {
        detail: {
          id: e.target.id,
          pathname: window.location.pathname,
          time,
          description: 'Time after Load and User click on Question input'
        }
      }));
    }
  };

  normalizeString = (source) => source.trim();

  getCheckMethod = () => {
    const { suggest_mode } = this.props;

    return suggest_mode === SUGGEST_MODES.KEYWORDS
      ? this.hasMatchingKeywords
      : this.hasMatchingAutocomplete;
  };

  checkMethod = this.getCheckMethod();

  applyHighlight(textWithTags) {
    return this.props.tags.reduce(
      (html, tag) => (
        html.replace(new RegExp(`\\b${tag}\\b`, 'gi'), (match) => (
          `<span class="content-editable__highlight">${match}</span>`
        ))
      ),
      textWithTags,
    )
  }

  applyTags(textOriginal) {
    const { SuggestMode } = config;
    const suggest_mode = SuggestMode;

    if (textOriginal.length === 0) {
      return '';
    }

    if (isIE()) {
      return textOriginal;
    }

    const textWithTags = textOriginal.split(/\n/g).map(line => `<div>${line || '<br>'}</div>`).join('');

    return suggest_mode === SUGGEST_MODES.KEYWORDS
      ? this.applyHighlight(textWithTags)
      : textWithTags;
  }

  removeHighlight(html) {
    const div = document.createElement('div');
    // retaining new lines
    div.innerHTML = html.replace(/<\/div>(?!$)/g, '</div>\n');
    return div;
  }

  createRange(node, chars, range, offset) {
    if (!range) {
      range = document.createRange()
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count - offset);
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length;
        } else {
          range.setEnd(node, chars.count - offset);
          chars.count = 0;
        }
      } else {
        for (var lp = 0; lp < node.childNodes.length; lp++) {
          range = this.createRange(node.childNodes[lp], chars, range, offset);

          if (chars.count === 0) {
            break;
          }
        }
      }
    }

    return range;
  }

  setCaretPosition(position) {
    if (position >= 0) {
      const selection = window.getSelection();
      const editable = document.getElementById('editable');

      const range = this.createRange(editable.parentNode, { count: position + this.caretOffset }, undefined, this.caretOffset);

      if (range) {
        range.collapse(false);

        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }

  getCaretPosition() {
    const editable = document.getElementById('editable');
    var caretOffset = 0;

    if (typeof window.getSelection != 'undefined') {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(editable);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    } else if (typeof document.selection != 'undefined' && document.selection.type !== 'Control') {
      const textRange = document.selection.createRange();
      const preCaretTextRange = document.body.createTextRange();
      preCaretTextRange.moveToElementText(editable);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;
    }

    return caretOffset;
  }

  hasMatchingKeywords(value) {
    return this.props.tags.some((tag) => {
      const lowerCaseValue = value.toLowerCase();
      return lowerCaseValue.includes(tag);
    });
  }

  hasMatchingAutocomplete(value) {
    return value.length >= this.minLengthForSearch;
  }

  insertText(text, correctionOffset) {
    const { innerRef } = this.props;
    const insertText = text + ' ';

    window
      .getSelection()
      .getRangeAt(0)
      .insertNode(document.createTextNode(insertText));

    this.caretPosition = correctionOffset + insertText.length;
    this.handleChange({ target: { value: innerRef.current.innerHTML } });
  }

  isWhiteSpaceBeforeRequired(previousElementSibling, index) {
    const { isChrome } = isChromeBrowser();
    return previousElementSibling !== null || (isChrome && index !== 0);
  }

  replaceText(text, { index, input }) {
    setTimeout(() => {
      const range = window
        .getSelection()
        .getRangeAt(0);

      range.setStart(range.endContainer, index);
      range.deleteContents();

      if (isIE()) {
        this.insertText(text, index);
      } else {
        const isWhiteSpace = this.isWhiteSpaceBeforeRequired(range.endContainer.previousElementSibling, index);
        document.execCommand('insertHTML', false, `${isWhiteSpace ? '&nbsp;' : ''}${text}&nbsp;`);
      }
    }, 0);
  }

  getOffsetProperty(element, property) {
    return window
      .getComputedStyle(element)
      .getPropertyValue(property)
      .replace('px', '')
  }

  getContentEditableOffsets(editable) {
    const [rect] = editable.getClientRects();

    const paddingTop = this.getOffsetProperty(editable, 'padding-top');
    const paddingLeft = this.getOffsetProperty(editable, 'padding-left');

    return {
      top: rect.top + +paddingTop,
      left: rect.left + +paddingLeft,
    };
  }

  getCurrentLine() {
    const sel = document.getSelection();
    return sel.anchorNode && sel.anchorNode.textContent;
  }

  getCurrentColumn() {
    const sel = document.getSelection();
    const text = sel.anchorNode && sel.anchorNode.textContent.slice(0, sel.focusOffset);
    return text && text.split('\n').pop().length;
  }

  getRangeRect() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    if (!range.collapsed) {
      range.setStart(range.endContainer, range.endOffset);
    }

    const [rect] = range.getClientRects();
    return rect;
  }

  getCorrectionsWrapperPosition(rect, correctionWrapper) {
    const editable = document.getElementById('editable');
    const { left, top } = this.getContentEditableOffsets(editable);

    const contentEditableTopOffsetRounded = Math.round(top);
    const rectTopRounded = Math.round(rect.top);

    const isCorrectionBelow = contentEditableTopOffsetRounded === rectTopRounded ||
      contentEditableTopOffsetRounded === rectTopRounded + 1;

    const correctionBelowOffset = this.props.isMobileDevice && !this.props.isIOS12 ? 41 : 30;

    const offsetLeft = rect.left - left - correctionWrapper.clientWidth / 2;

    const rightPosition = offsetLeft + correctionWrapper.clientWidth;
    const correctionLeftOffset = rightPosition > editable.clientWidth ? offsetLeft - (rightPosition - editable.clientWidth) : offsetLeft;
    const updatedCorrectionLeftOffset = correctionLeftOffset > 0 ? correctionLeftOffset : 0;

    return {
      top: isCorrectionBelow ? correctionBelowOffset : rect.top - top - 20,
      left: updatedCorrectionLeftOffset,
    };
  }

  createCorrectionsWrapper() {
    const correctionsWrapper = document.createElement('div');
    correctionsWrapper.classList.add('corrections-wrapper');
    return correctionsWrapper;
  }

  removeCorrectionsWrapper() {
    const [correctionsWrapper] = document.getElementsByClassName('corrections-wrapper');

    if (correctionsWrapper) {
      correctionsWrapper.remove();
    }
  }

  handleCorrectionClick(suggestion) {
    const currentLine = this.getCurrentLine();
    const currentColumn = this.getCurrentColumn();

    const endPosition = currentLine.indexOf(' ', currentColumn);
    const updatedEndPosition = endPosition === -1 ? currentLine.length : endPosition;

    const wordToAutocomplete = /\S+$/.exec(currentLine.slice(0, updatedEndPosition).trim());

    if (wordToAutocomplete) {
      this.props.innerRef.current.focus();
      this.replaceText(suggestion, wordToAutocomplete);
    }

    this.removeCorrectionsWrapper();
  }

  showCorrectionTags(corrections) {
    const { contentEditableRef } = this.props;
    const rect = this.getRangeRect();

    if (rect) {
      this.removeCorrectionsWrapper();
    }

    const correctionsWrapper = this.createCorrectionsWrapper(rect);
    correctionsWrapper.setAttribute('style', 'opacity: 0');

    corrections.forEach(current => {
      const correction = document.createElement('button');
      correction.id = 'correction-item';
      correction.classList.add('correction-item')
      correction.innerText = current.suggestion;

      const doneIcon = document.createElement('i');
      doneIcon.classList.add('material-icons');
      doneIcon.innerHTML = 'done';

      correction.appendChild(doneIcon);
      correction.addEventListener('click', () => this.handleCorrectionClick(current.suggestion));
      correctionsWrapper.appendChild(correction);
    });

    if (contentEditableRef.current && rect) {
      contentEditableRef.current.appendChild(correctionsWrapper);

      const position = this.getCorrectionsWrapperPosition(rect, correctionsWrapper);
      correctionsWrapper.setAttribute('style', `top: ${position.top}px; left: ${position.left}px;`);
    }
  }

  checkTextElement(text, index) {
    return text[index] && !_isEmpty(text[index].trim());
  }

  isAutoCorrectionMode(text, currentColumn) {
    return text && currentColumn && this.checkTextElement(text, currentColumn - 1);
  }

  getWordForAutocorrect(text) {
    const currentColumn = this.getCurrentColumn();
    const isAutoCorrectionRequired = currentColumn && this.isAutoCorrectionMode(text, currentColumn);

    if (isAutoCorrectionRequired) {
      const endPosition = text.indexOf(' ', currentColumn);
      const updatedEndPosition = endPosition === -1 ? text.length : endPosition;

      const lastModifiedWord = /\S+$/.exec(text.slice(0, updatedEndPosition).trim());

      if (lastModifiedWord) {
        const [autocorrectWord] = lastModifiedWord;
        return autocorrectWord.length >= this.minLengthForSearch ? autocorrectWord : undefined;
      }
    }

    return undefined;
  }

  handleAutocorrectSuggestionsOnClick() {
    if (this.props.isMobileDevice && !this.props.isIpad && !this.props.isIOS12) {
      setTimeout(() => {
        this.handleAutocorrectSuggestions();
      }, 400);
    } else {
      this.handleAutocorrectSuggestions();
    }
  }

  searchAutocomplete(autocorrectWord, autocorrectWordLower, tagsSource, tags = []) {
    const nextSource = tagsSource.pop();

    if (tags.length < this.minLengthForSearch && nextSource) {
      const searchTags = nextSource
        .filter(({ value }) => (
          value
            .toLowerCase()
            .indexOf(autocorrectWordLower) === 0
          &&
          value !== autocorrectWord
        ))
        .map(tag => ({
          ...tag,
          value: tag.value
            .toLowerCase()
            .replace(
              autocorrectWordLower,
              autocorrectWord
            ),
        }));

      tags = [...tags, ...searchTags];

      return this.searchAutocomplete(autocorrectWord, autocorrectWordLower, tagsSource, tags);
    }

    return tags;
  }

  prepareCorrection(autocorrectWord, corrections) {
    const [searchCorrection] = _get(corrections, autocorrectWord, []);

    if (searchCorrection) {
      const { suggestion } = searchCorrection;
      const diffIndex = findDiff(autocorrectWord.toLowerCase(), searchCorrection.suggestion);

      const preparedSuggestion = diffIndex
        ? suggestion.replace(autocorrectWord.toLowerCase().slice(0, diffIndex), autocorrectWord.slice(0, diffIndex))
        : suggestion;

      return { suggestion: preparedSuggestion };
    }

    return undefined;
  }

  compareAutocompletes = (a, b) => a.value.length - b.value.length;

  sortAutocompletes = (groupedAutocompletes) => (
    Object.keys(groupedAutocompletes).reduce((acc, key) => {
      const autocompleteSource = groupedAutocompletes[key];
      const sortedAutocompletes = autocompleteSource.sort(this.compareAutocompletes);
      return [...acc, ...sortedAutocompletes];
    }, [])
  );

  async handleAutocorrectSuggestions() {
    const { dictionaries, innerRef } = this.props;

    const currentLine = this.getCurrentLine();
    const autocorrectWord = this.getWordForAutocorrect(currentLine);

    if (autocorrectWord) {
      const autocompletes = this.searchAutocomplete(autocorrectWord, autocorrectWord.toLowerCase(), [...dictionaries]);

      const uniqAutocompletes = _uniqBy(autocompletes, 'value');
      const groupedAutocompletes = _groupBy(uniqAutocompletes, 'lang');

      const sortedAutocompletes = this.sortAutocompletes(groupedAutocompletes);
      const mappedAutocompletes = sortedAutocompletes.map(autocomplete => ({ suggestion: autocomplete.value }));

      if (mappedAutocompletes.length < this.minLengthForSearch && innerRef.current.textContent) {
        const product = this.props.product ? this.props.product.id : undefined;
        const { data: { corrections } } = await spellCheck({
          text: innerRef.current.textContent,
          mode: [SPELL_CHECK_MODES.AUTOCORRECT],
          product,
        });

        if (!_isEmpty(corrections)) {
          const preparedCorrection = this.prepareCorrection(autocorrectWord, corrections);

          if (preparedCorrection) {
            mappedAutocompletes.push(preparedCorrection);
          }
        }
      }

      const searchAutocompletes = mappedAutocompletes.slice(0, this.minLengthForSearch);
      const searchUniqAutocompletes = _uniqBy(searchAutocompletes, 'suggestion');

      const isAutoCorrectionRequired = this.isAutoCorrectionMode(
        this.getCurrentLine(),
        this.getCurrentColumn(),
      );

      if (!_isEmpty(searchUniqAutocompletes) && this.leftPanel && isAutoCorrectionRequired) {
        this.showCorrectionTags(searchUniqAutocompletes);
      } else {
        this.removeCorrectionsWrapper();
      }
    } else {
      this.removeCorrectionsWrapper();
    }
  }

  handleChange = evt => {
    const { onChange } = this.props;
    const html = evt.target.value;
    const htmlWithoutHighlight = html && this.removeHighlight(html);

    const value = _get(htmlWithoutHighlight, 'textContent');

    const event = {
      target: { value },
    };

    if (value) {
      const delayedAutocorrect = _debounce(() => this.handleAutocorrectSuggestions(), 300);
      delayedAutocorrect();
    } else {
      this.removeCorrectionsWrapper();
    }

    // value is changed, we can show suggestions again
    this.setState({ showSuggestions: true });
    onChange(event);
  };

  handlePaste = evt => {
    if (window.clipboardData && window.clipboardData.getData) {
      const text = window.clipboardData.getData('Text');
      if (text.includes('\n')) {
        evt.preventDefault();
      } else return;
    } else {
      evt.preventDefault();
    }

    this.isPasted = true;

    if (evt.clipboardData && evt.clipboardData.getData) {
      const text = (evt.originalEvent || evt).clipboardData.getData('text/plain');
      document.execCommand('insertHTML', false, text);
    } else if (window.clipboardData && window.clipboardData.getData) {
      const text = window.clipboardData.getData('Text');
      if (window.getSelection) {
        window.getSelection().getRangeAt(0).insertNode(document.createTextNode(text));
     }
    }
  };

  handlePasteFlag = () => this.isPasted = false;

  handleOffsetFlag = (e) => {
    if (e.keyCode === 13) {
      this.caretOffset = 1;
    } else {
      this.caretOffset = 0;
    }
  }

  handleCaretPosition = () => this.caretPosition = this.getCaretPosition();

  isArrowKey = (code) => code >= 37 && code <= 40;

  handleKeyUp = (e) => {
    this.handleCaretPosition();

    if (this.isArrowKey(e.keyCode)) {
      this.handleAutocorrectSuggestions();
    }
  };

  hideSuggestions = (e) => {
    this.setState({ showSuggestions: false });

    if (e.target.className && e.target.className.includes('material-icons') && this.props.focusRequired) {
      setTimeout(() => this.props.innerRef.current.focus(), 10);
    } else {
      this.removeCorrectionsWrapper();
    }
  }

  handleFocus = (e) => {
    this.isBlurred = false;

    if (isMobileDevice() && isInsideFrame() && !this.props.isIOS12) {
      window.parentIFrame.autoResize(false);
      window.parentIFrame.sendMessage({ id: 'scrollTop' });
    }

    const { innerRef, onFocus, value } = this.props;
    innerRef.current.setAttribute('style', 'opacity: 1;');

    if (!isMobileDevice()) {
      innerRef.current.removeAttribute('style');
    } else {
      if (value.length > 0) {
        innerRef.current.removeAttribute('style');
      }
    }

    this.setState({ showPlaceholder: false });
    return onFocus(e);
  };

  handleBlur = (e) => {
    if (isMobileDevice()) {
      this.hideSuggestions(e);
    }

    document.dispatchEvent(new CustomEvent('tr-custom-event', { name: 'content-editable-blur', detail: e.target.id }));

    this.isBlurred = true;

    const { onBlur } = this.props;
    this.setState({ showPlaceholder: true });

    const isConversationNext = isConversationRoute(window.location);
    if (!isConversationNext) {
      onBlur(e);
    }
  };

  preventBubblingEvent = (e) => e.stopPropagation();

  render = () => {
    const { showPlaceholder, showSuggestions } = this.state;
    const { contentEditableRef, isLoading, value, onSuggestionSelect, onClick, onKeyDown, innerRef, placeholder, send, showSubmit } = this.props;
    const html = this.applyTags(value);
    return (
      <div ref={contentEditableRef} className="content-editable questionInput">
        {showPlaceholder && !value && (
          <div className="content-editable__placeholder">
            <div>{placeholder}</div>
          </div>
        )}
        <ContentEditable
          id="editable"
          html={html}
          disabled={false}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onClick={(e) => {
            this.handleTimeAfterLoad(e);
            this.handleCaretPosition();
            this.handleAutocorrectSuggestionsOnClick();
            onClick(e);
          }}
          onKeyDown={(e) => {
            this.handlePasteFlag();
            this.handleOffsetFlag(e);
            onKeyDown(e);
          }}
          tagName='div'
          className="content-editable__input"
          innerRef={innerRef}
        />
        {this.checkMethod(value) && showSuggestions && !_isEmpty(this.props.suggestions) && (
          <PullDown
            suggestions={this.props.suggestions}
            onItemClick={onSuggestionSelect}
            onClose={this.hideSuggestions}
            preventBubblingEvent={this.preventBubblingEvent}
          />
        )}
        {this.props.children}
        <IconButton
          id="send-request"
          data-tr-event
          className="send"
          color="primary"
          style={{ display: showSubmit ? '' : 'none' }}
          disabled={isLoading}
          onClick={send}
        >
          {isLoading && <LoadingSpinner className="send-button-spinner" />}
          {!isLoading && <i data-tr-event className="xircles-icons icon-send"/>}
        </IconButton>
      </div>
    );
  };
}
