// @flow

import React from 'react';
import camelCase from 'lodash/camelCase';
import { Route, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _isUndefined from 'lodash/isUndefined';
import _values from 'lodash/values';
import _get from 'lodash/get';

import ShareModal from '../components/Modal/ShareModal';
import { isEmail, isPhoneNumber, getCredentialType, isUtf8 } from '../utils/common';
import { shareContent } from '../utils/api';
import { layerClient } from '../get-layer';

type Props = {
  t: Function,
};

type State = {
  shareError: any,
  shareState: 'loading' | 'loaded' | 'error',
};

export const SHARE_MODAL_PATHS = {
  SHARE_CONVERSATION_FROM_QUESTIONS: '/:productName?/conversation/(shared|share)',
  SHARE_CONVERSATION: '/questions/:conversationId/(shared|share)',
  SHARE_VIDEO: '/video/:videoId/(shared|share)',
  SHARE_DOCUMENT: '/documents/:documentPath+/(shared|share)',
};

export const SHARE_MODAL_PATHS_ARRAY = _values(SHARE_MODAL_PATHS);

export const SHARE_CONTENT_SETTINGS = {
  [SHARE_MODAL_PATHS.SHARE_CONVERSATION_FROM_QUESTIONS]: {
    urlParam: undefined,
    dialogTitle: 'MODAL_SHARE_CONVERSATION',
    getBackUrl: () => '/questions',
    getSuccessUrl: () => '/questions/conversation/shared',
    getShareUrl: (conversationId) => `/questions/${conversationId}`,
  },
  [SHARE_MODAL_PATHS.SHARE_CONVERSATION]: {
    urlParam: 'conversationId',
    dialogTitle: 'MODAL_SHARE_CONVERSATION',
    getBackUrl: (conversationId) => `/questions/${conversationId}`,
    getSuccessUrl: (conversationId) => `/questions/${conversationId}/shared`,
    getShareUrl: (conversationId) => `/questions/${conversationId}`,
  },
  [SHARE_MODAL_PATHS.SHARE_VIDEO]: {
    urlParam: 'videoId',
    dialogTitle: 'MODAL_SHARE_CONTENT',
    getBackUrl: (videoId) => `/video/${videoId}`,
    getSuccessUrl: (videoId) => `/video/${videoId}/shared`,
    getShareUrl: (videoId) => `/video/${videoId}`,
  },
  [SHARE_MODAL_PATHS.SHARE_DOCUMENT]: {
    urlParam: 'documentPath',
    dialogTitle: 'MODAL_SHARE_CONTENT',
    getBackUrl: (documentPath) => `/documents/${documentPath}`,
    getSuccessUrl: (documentPath) => `/documents/${documentPath}/shared`,
    getShareUrl: (documentPath) => `/documents/${documentPath}`,
  },
};

class ShareModalContainer extends React.Component<Props, State> {
  state = {
    shareState: null,
    shareError: null,
  };

  getConversationTitle = () => {
    const conversationId = this.getConversationId();
    const conversation = layerClient.getConversation(conversationId);

    return conversation.metadata.conversationName;
  };

  onShareContent = (urlParam, phoneNumberOrEmail, shareModalSettings, hash = '') => {
    this.setState({ shareState: 'loading', shareError: null });

    const type = getCredentialType(phoneNumberOrEmail);
    const shareUrl = shareModalSettings.getShareUrl(urlParam) + hash;

    const title = _get(this.props.location, 'state.detail.title');

    const preparedTitle = _isUndefined(title)
      ? this.getConversationTitle()
      : title;

    const details = {
      user_id: layerClient.userId,
      credential: {
        type,
        value: phoneNumberOrEmail,
      },
      share_url: shareUrl,
      title: preparedTitle,
    };

    return shareContent(details)
      .then((data) => {
        this.setState({ shareState: 'loaded' });
        return data;
      })
      .catch(shareError => {
        const errorMessage = _get(shareError, 'response.data.error.id', 'SOMETHING_WENT_WRONG');

        this.setState({
          shareState: 'error',
          shareError: this.props.t('ERROR_' + errorMessage),
        });

        throw shareError;
      });
  };

  validatePhoneNumberOrEmail = (phoneNumberOrEmail) => {
    const isValidPhoneNumberOrEmail = isUtf8(phoneNumberOrEmail) && (
      isEmail(phoneNumberOrEmail) || isPhoneNumber(phoneNumberOrEmail)
    );

    const updatedState = !isValidPhoneNumberOrEmail
      ? { shareState: 'error', shareError: this.props.t('ERROR_ENTER_VALID_NUMBER_OR_EMAIL') }
      : { shareError: null };

    this.setState(updatedState);
    return isValidPhoneNumberOrEmail;
  };

  isShareConversationUrl = (url) => /\/questions\/.{36}\/(shared|share)/.exec(url) || /\/[a-zA-Z]*\/conversation\/(shared|share)/.exec(url);

  isShareVideoUrl = (url) => /\/video\/.{20}\/(shared|share)/.exec(url);

  isShareDocumentUrl = (url) => /\/documents\/.*\/(shared|share)/.exec(url);

  getConversationId = () => {
    const passedConversationId = _get(this.props.location, 'state.detail.conversationId');
    const pathnameMatchArray = this.props.location.pathname.match(/\b[a-f\d-]{36}\b/g);
    let urlConversationId;
    if (pathnameMatchArray) {
      [urlConversationId] = pathnameMatchArray;
    }
    return passedConversationId || urlConversationId;
  };

  getUrlParam = (shareModalSettings, params) => {
    const urlParam = params[shareModalSettings.urlParam];
    return _isUndefined(urlParam) ? this.getConversationId() : urlParam;
  };

  resetError = () => this.setState({ shareError: null });

  handleToggle = (history, match) => {
    // handle direct link to the page
    if (history.length <= 2) {
      const baseUrl = match.url.replace(/\/(share|shared)/, '');
      history.replace(baseUrl);
    } else {
      history.goBack();
    }
  };

  render() {
    return (
      <Route path={SHARE_MODAL_PATHS_ARRAY} render={({ match, history }) => {
        const regexMatchShareConversation = this.isShareConversationUrl(match.url);
        const regexMatchShareVideo = this.isShareVideoUrl(match.url);
        const regexMatchShareDocument = this.isShareDocumentUrl(match.url);

        const shareModalSettings = SHARE_CONTENT_SETTINGS[match.path];
        const urlParam = this.getUrlParam(shareModalSettings, match.params);

        const regexMatch = regexMatchShareConversation || regexMatchShareVideo || regexMatchShareDocument;
        const currentStep = regexMatch ? camelCase(regexMatch[1]) : null;

        return (
          <ShareModal
            {...this.props}
            isOpen={!!currentStep}
            urlParam={urlParam}
            onShareContent={this.onShareContent}
            onToggle={() => this.handleToggle(history, match)}
            error={this.state.shareError}
            resetError={this.resetError}
            shareState={this.state.shareState}
            shareModalSettings={shareModalSettings}
            validatePhoneNumberOrEmail={phoneNumberOrEmail => this.validatePhoneNumberOrEmail(phoneNumberOrEmail)}
          />
        );
      }}/>
    );
  }
}

export default withTranslation()(withRouter(ShareModalContainer));
