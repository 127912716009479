// @flow

import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import MaterialIcon from '@material/react-material-icon';
import { withTranslation } from 'react-i18next';

import PV_STATUSES from '../../../const/status/pv-status';
import XIRCLES_STATUSES from '../../../const/status/xircles-status';
import IconButton from '../../../material-components/IconButton';
import Dialog, {DialogTitle, DialogContent} from '../../../material-components/Dialog';
import ForwardConversationModalFragment from '../ForwardConversationModalFragment';
import ClearPVModalFragment from '../ClearPVModalFragment';
import DeliverAnswerModalFragment from '../DeliverAnswerModalFragment';
import CloseRequestModalFragment from '../CloseRequestModalFragment';
import DeleteRequestModalFragment from '../DeleteRequestModalFragment';
import ErrorMessage from '../ErrorMessage';
import LoadingSpinner from '../../LoadingSpinner';
import {layerClient} from '../../../get-layer';
import ProvideContentModalFragment from '../ProvideContentModalFragment';

const xSlateLighter = '#CCCED3';

type Props = {
  isOpen: boolean,
  conversationId: string,
  error: string | null,
  resetError: Function,
  operatorState: 'loading' | 'loaded' | 'error',
  onForwardMedinfo: Function,
  onPVStatusUpdate: Function,
  onReportPV: Function,
  onCloseRequest: Function,
  onDeleteRequest: Function,
  onToggle: Function,
  t: Function,
};

type State = {
  errorMessage: string,
};

class OperatorModal extends React.Component<Props, State> {
  state = {};

  handleRenderForwardToMedinfoFragment = () => {
    const {conversationId, operatorState, onForwardMedinfo} = this.props;
    const isLoading = operatorState === 'loading';

    return (
      <div>
        {isLoading && <LoadingSpinner/>}
        <ForwardConversationModalFragment
          onSubmit={() => {
            onForwardMedinfo({conversation_id: conversationId})
              .then(this.handleGoBack);
          }}
          onCancel={this.handleGoBack}
        />
      </div>
    );
  };

  handleRenderDeliverAnswerFragment = () => {
    const userId = layerClient.user.userId;
    const {conversationId, operatorState} = this.props;
    const isLoading = operatorState === 'loading';
    const conversation = layerClient.getConversation(conversationId);
    return (
      <div>
        {isLoading && <LoadingSpinner/>}
        <DeliverAnswerModalFragment
          conversationId={conversationId}
          conversation={conversation}
          userId={userId}
          onCancel={this.handleGoBack}
        >

        </DeliverAnswerModalFragment>
      </div>
    );
  }

  handleRenderClearPVFragment = () => {
    const {conversationId, operatorState, onPVStatusUpdate, onReportPV} = this.props;
    const isLoading = operatorState === 'loading';

    return (
      <div>
        {isLoading && <LoadingSpinner/>}
        <ClearPVModalFragment
          onNoPVIssueDetected={() => {
            onPVStatusUpdate({
              external_id: conversationId,
              ticket: {pv_status: PV_STATUSES.CLEARED},
            })
              .then(this.handleGoBack)
          }}
          onReportPVIssue={() => {
            onReportPV({conversation_id: conversationId})
              .then(this.handleGoBack)
          }}
          onCancel={this.handleGoBack}
        />
      </div>
    );
  };

  handleRenderCloseRequestFragment = () => {
    const {conversationId, operatorState, onCloseRequest} = this.props;
    const isLoading = operatorState === 'loading';
    const conversation = layerClient.getConversation(conversationId);
    const questionTitle = conversation.metadata.conversationName;

    return (
      <div>
        {isLoading && <LoadingSpinner/>}
        <CloseRequestModalFragment
          onSubmit={() => {
            onCloseRequest({
              external_id: conversationId,
              ticket: {
                xircles_status: XIRCLES_STATUSES.CLOSED,
                subject: questionTitle
              },
            })
              .then(this.handleGoBack);
          }}
          onCancel={this.handleGoBack}
        />
      </div>
    );
  };

  handleRenderDeleteRequestFragment = () => {
    const {conversationId, operatorState, onDeleteRequest} = this.props;
    const isLoading = operatorState === 'loading';

    return (
      <div>
        {isLoading && <LoadingSpinner/>}
        <DeleteRequestModalFragment
          onSubmit={() => {
            onDeleteRequest({
              external_id: conversationId,
              ticket: {xircles_status: XIRCLES_STATUSES.DELETED},
            })
              .then(this.handleGoBack);
          }}
          onCancel={this.handleGoBack}
        />
      </div>
    );
  };

  handleRenderProvideContentFragment = () => {
    const { conversationId, operatorState } = this.props;
    const isLoading = operatorState === 'loading';
    const conversation = layerClient.getConversation(conversationId);
    return (
      <div>
        {isLoading && <LoadingSpinner/>}
        <ProvideContentModalFragment
          conversation={conversation}
          onCancel={this.handleGoBack}
        >
        </ProvideContentModalFragment>
      </div>
    );
  };

  getTitle = () => {
    switch (this.props.location.pathname.split('/').filter(p => p !== '').pop()) {
      case 'forward-to-medinfo':
        return this.props.t('MODAL_FORWARD_TO_MEDINFO');
      case 'clear-pv':
        return this.props.t('MODAL_CLEAR_PV');
      case 'deliver-answer':
        return this.props.t('MODAL_DELIVER_ANSWER');
      case 'close-request':
        return this.props.t('MODAL_CLOSE_REQUEST');
      case 'delete-request':
        return this.props.t('MODAL_DELETE_REQUEST');

      default:
        return '';
    }
  };

  renderCloseButton() {
    return (
      <div style={{position: 'fixed', top: '-2px', right: '0px', color: `${xSlateLighter}`}}>
        <IconButton
          size="small"
          className="closeBtn"
          onClick={this.onClose}
        >
          <MaterialIcon icon="clear"/>
        </IconButton>
      </div>
    );
  }

  onEnterErrorMessage = () => {
    this.setState({errorMessage: this.props.error});
  };

  onExitedErrorMessage = () => {
    this.setState({errorMessage: null});
  };

  renderError() {
    const {error, resetError} = this.props;
    const {errorMessage} = this.state;

    return (
      <ErrorMessage
        error={error}
        errorMessage={errorMessage}
        resetError={resetError}
        onEnterErrorMessage={this.onEnterErrorMessage}
        onExitedErrorMessage={this.onExitedErrorMessage}
      />
    );
  }

  onClose = (ev) => {
    this.props.onToggle(ev);
  };

  handleGoBack = () => this.props.history.goBack();

  render() {
    const {
      isOpen,
      onToggle,
    } = this.props;

    this.isDeliverAnswer = this.props.location.pathname.slice(-14) === 'deliver-answer';
    this.isProvideContent = this.props.location.pathname.slice(-15) === 'provide-content';

    return (
      <Dialog
        className={`xircles-operator-dialog ${this.isDeliverAnswer ? 'xircles-operator-dialog__deliver-answer' : ''} ${this.isProvideContent ? 'xircles-operator-dialog__provide-content' : ''}`}
        open={isOpen}
        onClose={onToggle}
      >
        <DialogTitle style={{textAlign: 'center'}}>
          {this.renderError()}
          {this.getTitle()}
          {this.renderCloseButton()}
        </DialogTitle>
        <DialogContent>
          <div className="my-wrapper">
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={this.props.location.key}
                timeout={{enter: 400, exit: 400}}
                classNames="fade"
              >
                <section className="route-section">
                  <Switch location={this.props.location}>
                    <Route path={'/questions/:conversationId/forward-to-medinfo'} exact
                           render={this.handleRenderForwardToMedinfoFragment}/>
                    <Route path={'/questions/:conversationId/clear-pv'} exact
                           render={this.handleRenderClearPVFragment}/>
                    <Route path={'/questions/:conversationId/close-request'} exact
                           render={this.handleRenderCloseRequestFragment}/>
                    <Route path={'/questions/:conversationId/delete-request'} exact
                           render={this.handleRenderDeleteRequestFragment}/>
                    <Route path={'/questions/:conversationId/deliver-answer'} exact
                           render={this.handleRenderDeliverAnswerFragment}/>


                    <Route path={'/questions/:conversationId/provide-content'} exact
                           render={this.handleRenderProvideContentFragment}/>
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(OperatorModal));
