// @flow

/**
 *      Creates stateless UI component with buttons for Question page
 *
 *      send            - callback ()=>{} for send button, NOT required
 *      buttons         - array with props for BigButton, NOT required
 *      showSubmit      - boolean value for displaying send button, NOT required
 */

/**
 * GTM - Trigger
 *
 * name: Click on Shortcut
 * fires on: Click element matches CSS selector button.short-cut
 */

import * as React from 'react';

import './styles.less';
import BigButton from './BigButton/index';

type Props = {
  innerRef: any,
  buttons?: Array,
  className?: string,
  messageTranslation: string
}

const BigButtonBar = (props: Props) => {
  const { messageTranslation } = props;
  return (
    <div className="bigButtonBarWrapper">
      <div ref={props.innerRef} className={`bigButtonBar ${props.className}`}>
        <div className={'bigButtonBar__message'}>
          <p>{messageTranslation}</p>
        </div>
        <div className={'bigButtonBar__shortcut-container'}>
          {
            props.buttons.map((item, number) => (
              <BigButton
                id={`shortcut-${number + 1}`}
                text={item.text}
                mouseDownHandler={item.onClick}
                className={item.className}
                key={number}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
};

BigButtonBar.defaultProps = {
  send: null,
  buttons: [],
  showSubmit: true,
};

export default BigButtonBar;
