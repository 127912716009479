import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesContentCardModel extends MessageTypeModel {
    generateParts(callback) {
        const body = this.initBodyWithMetadata([
            'header',
            'url',
            'text',
            'linkText',
            'matchedTags',
            'score',
            'onlyForHcp',
            'externalDisclaimer',
        ]);

        this.part = new MessagePart({
            mimeType: this.constructor.MIMEType,
            body: JSON.stringify(body),
        });
        callback([this.part]);
    }
}

XirclesContentCardModel.prototype.header = '';
XirclesContentCardModel.prototype.url = '';
XirclesContentCardModel.prototype.text = '';
XirclesContentCardModel.prototype.linkText = '';
XirclesContentCardModel.prototype.matchedTags = '';
XirclesContentCardModel.prototype.score = 0;
XirclesContentCardModel.prototype.onlyForHcp = false;
XirclesContentCardModel.prototype.externalDisclaimer = undefined;

XirclesContentCardModel.MIMEType = 'application/xircles.content-card+json';
XirclesContentCardModel.LabelSingular = 'Content Card';
XirclesContentCardModel.messageRenderer = 'xircles-content-card-message-view';

Root.initClass.apply(XirclesContentCardModel, [XirclesContentCardModel, 'XirclesContentCardModel']);
Client.registerMessageTypeModelClass(XirclesContentCardModel, 'XirclesContentCardModel');

export default XirclesContentCardModel;
