export default {
  NOT_FINISHED: 'STATUS_NOT_FINISHED',
  REFRAMED: 'STATUS_REFRAMED',
  FORWARD_REQUESTED: 'STATUS_FORWARD_REQUEST',
  FORWARDED: 'STATUS_FORWARDED',
  ANSWER_DELIVERED: 'STATUS_ANSWER_DELIVERED',
  HELPFUL: 'STATUS_HELPFUL',
  CLOSED: 'STATUS_CLOSED',
  DELETED: 'STATUS_DELETED',
  TAKEOVER: 'STATUS_TAKEOVER',
};