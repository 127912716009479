import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import config from './config.json';
import { releasePath } from './utils/api';


const backendOpts = {
  loadPath: `${config.CDNUrl}${config.Stage === 'dev' ? '' : releasePath()}/translations/translation.${config.Language || 'en'}.json`
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    useLocalStorage: true,
    debug: false,
    fallbackLng: 'en',
    load: 'languageOnly',
    backend: backendOpts,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false,
      wait: true,
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    }
  });

export default i18n;