import { getImageSize, generateSVG } from '../utils/common';

const LayerImageMessageViewMixin = {
  methods: {
    _handleTouchStart() {
      this.documentTap = true;
    },
    _handleTouchMove() {
      this.documentTap = false;
    },
    _getImageIndex() {
      const [zoomedImage] = document.getElementsByClassName('zoomed-image');
      return (zoomedImage && zoomedImage.getAttribute('data-index')) || undefined;
    },
    onCreate: async function () {
      this.documentTap = false;
      this.style.minHeight = '165px';

      const index = document.getElementsByClassName('layer-image-message-wrapper').length;

      const wrapper = document.createElement('div');
      wrapper.classList.add('layer-image-message-wrapper', 'layer-image-message-wrapper-' + index);
      wrapper.setAttribute('data-index', index);
      wrapper.addEventListener('click', () => this._handleExpandButtonClick(index));

      const [isScrimExists] = document.getElementsByClassName('expand-image__scrim');

      if (!isScrimExists) {
        const scrim = document.createElement('div');
        scrim.classList.add('expand-image__scrim');
        scrim.addEventListener('click', () => this._handleExpandButtonClick());

        if ('ontouchstart' in document.documentElement && 'ontouchend' in document.documentElement) {
          scrim.addEventListener('touchstart', () => this._handleTouchStart(), { passive: true });
          scrim.addEventListener('touchmove', () => this._handleTouchMove(), { passive: true });
          scrim.addEventListener('touchend', () => {
            if (this.documentTap) {
              this._handleExpandButtonClick();
            }
          }, { passive: true });
        }

        const [questionPanel] = document.getElementsByClassName('question-panel');
        questionPanel && questionPanel.appendChild(scrim);
      }

      if ('ontouchstart' in document.documentElement && 'ontouchend' in document.documentElement) {
        wrapper.addEventListener('touchstart', () => this._handleTouchStart(), { passive: true });
        wrapper.addEventListener('touchmove', () => this._handleTouchMove(), { passive: true });
        wrapper.addEventListener('touchend', () => {
          if (this.documentTap) {
            this._handleExpandButtonClick(index);
          }
        }, { passive: true });
      }

      const expandButton = document.createElement('button');
      expandButton.id = 'expand-image-button';
      expandButton.classList.add('expand-image-button');

      const image = this.nodes.image;
      this.removeChild(this.nodes.image);

      const naturalImageSize = await getImageSize(this.nodes.image);

      let maxWidth, maxHeight, sizeProportion;

      if (window.innerWidth < window.innerHeight) {
        maxWidth = window.innerWidth > naturalImageSize.width + 20 ? naturalImageSize.width : window.innerWidth - 20;
        sizeProportion = maxWidth / naturalImageSize.width;
        maxHeight = naturalImageSize.height * sizeProportion;
      } else {
        maxHeight = window.innerHeight > naturalImageSize.height + 150 ? naturalImageSize.height : window.innerHeight - 150;
        sizeProportion = maxHeight / naturalImageSize.height;
        maxWidth = naturalImageSize.width * sizeProportion;

        if (maxWidth > window.innerWidth || maxWidth + 150 > window.innerWidth) {
          maxWidth = window.innerWidth > naturalImageSize.width + 150 ? naturalImageSize.width : window.innerWidth - 150;
          sizeProportion = maxWidth / naturalImageSize.width;
          maxHeight = naturalImageSize.height * sizeProportion;
        }
      }

      var expandImageStyle = document.createElement('style');
      expandImageStyle.type = 'text/css';
      expandImageStyle.innerHTML = `
        .expand-image-${index} {
          position: fixed !important;
          top: 50%;
          left: 50%;
          max-width: ${maxWidth}px !important;
          max-height: ${maxHeight}px !important;
          margin-left: -${maxWidth / 2}px !important;
          margin-top: -${maxHeight / 2}px !important;
        }
      `;

      document.head.appendChild(expandImageStyle);

      const iconExpand = generateSVG({ fill: 'none', height: '18', width: '18', viewBox: '0 0 18 18', d: 'M7 18v-2H3.41l4.5-4.5-1.41-1.41-4.5 4.5V11H0v7h7m4.5-10.09l4.5-4.5V7h2V0h-7v2h3.59l-4.5 4.5 1.41 1.41z' });
      const iconCollapse = generateSVG({ fill: 'none', height: '27', width: '27', viewBox: '0 0 27 27', d: 'M24.75.135L18 6.885V1.5h-3V12h10.5V9h-5.385l6.75-6.75L24.75.135M1.5 15v3h5.385l-6.75 6.75 2.115 2.115L9 20.115V25.5h3V15H1.5z' });

      expandButton.appendChild(iconExpand);
      expandButton.appendChild(iconCollapse);

      wrapper.appendChild(image);
      wrapper.appendChild(expandButton);
      this.appendChild(wrapper);
    },
    onRerender() {
      const customData = this.model.customData;
      if (customData && customData.image_type) {
        this.classList.add(`img-container-${customData.image_type}`);
        this.nodes.image.classList.add(`${customData.image_type}`);
      }
    },
    _handleExpandButtonClick(index) {
      const updatedIndex = index === undefined ? this._getImageIndex() : index;

      if (updatedIndex !== undefined) {
        const [layerImageMessageWrapper] = document.getElementsByClassName('layer-image-message-wrapper-' + updatedIndex);
        layerImageMessageWrapper.classList.toggle('expand-image-' + updatedIndex);
        layerImageMessageWrapper.classList.toggle('zoomed-image');

        const [scrim] = document.getElementsByClassName('expand-image__scrim');
        scrim.classList.toggle('expand-image__scrim--show');
        }
    }
  }
};

export default LayerImageMessageViewMixin;
