// @flow

import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { Route } from 'react-router-dom';
import _values from 'lodash/values';
import _get from 'lodash/get';

import {
  isExternalDisclaimerRoute,
  isIEWarningRoute,
  isLoginWasRemovedRoute,
  isNetworkErrorRoute,
  isSaveChangesRoute,
  isSomethingWentWrongRoute,
  isTooManyRequestsRoute,
  isNoProductRoute,
  isFutureProductRoute,
  isUnavailableContentRoute,
  isDataPrivacyRoute,
  isPrivacyNoticeRoute,
  isTermsOfUseRoute,
} from '../utils/common';
import InfoModal from '../components/Modal/InfoModal';

type Props = {};

type State = {};

export const INFO_MODAL_PATHS = {
  EXTERNAL_DISCLAIMER: '/questions/:conversationId/external-disclaimer',
  IE_WARNING: '*/ie-warning',
  DATA_PRIVACY: '*/data-privacy',
  PRIVACY_NOTICE: '*/privacy-notice',
  LOGIN_WAS_REMOVED: '*/login-was-removed',
  NETWORK_ERROR: '*/network-error',
  SAVE_CHANGES: '*/save-changes',
  SOMETHING_WENT_WRONG: '*/something-went-wrong',
  TOO_MANY_REQUESTS: '*/too-many-requests',
  TERMS_OF_USE: '*/terms-of-use',
  UNAVAILABLE_CONTENT: '*/unavailable-content',
  NO_PRODUCT: '*/no-product',
  FUTURE_PRODUCT: '*/future-product',
};

export const INFO_MODAL_PATHS_ARRAY = _values(INFO_MODAL_PATHS);

class InfoModalContainer extends React.Component<Props, State> {

  isInfoUrl = (url) => (
    [
      isExternalDisclaimerRoute(url),
      isIEWarningRoute(url),
      isLoginWasRemovedRoute(url),
      isNetworkErrorRoute(url),
      isSaveChangesRoute(url),
      isSomethingWentWrongRoute(url),
      isTooManyRequestsRoute(url),
      isNoProductRoute(url),
      isFutureProductRoute(url),
      isDataPrivacyRoute(url),
      isUnavailableContentRoute(url),
      isPrivacyNoticeRoute(url),
      isTermsOfUseRoute(url),
    ].some((route) => !!route)
  );

  handleToggle = (history) => {
    const conversationName = _get(history.location, 'state.detail.conversationName');
    const stepBackNumber = _get(history.location, 'state.detail.stepBackNumber');
    const goBackUrl = '/';
    if (stepBackNumber) {
      return history.go(stepBackNumber);
    }
    if (conversationName) {
      window.dispatchEvent(new CustomEvent('reframe-question', { detail: { goBackUrl } }));
    } else {
      // handle direct link to the page
      if (history.length <= 2) {
        history.replace(goBackUrl);
      } else {
        history.goBack();
      }
    }
  };

  render() {
    return (
      <Route
        path={INFO_MODAL_PATHS_ARRAY}
        render={({ match, history }) => {
          const regexMatch = this.isInfoUrl(match.url);

          return (
            <Fragment>
              <Helmet>
                <meta name="robots" content="none" />
              </Helmet>
              <InfoModal
                isOpen={regexMatch}
                isCustomWrapper={
                  isTooManyRequestsRoute(match.url) ||
                  isNoProductRoute(match.url) ||
                  isFutureProductRoute(match.url) ||
                  isDataPrivacyRoute(match.url)
                }
                isPrivacyNotice={isPrivacyNoticeRoute(match.url)}
                isTermsOfUse={isTermsOfUseRoute(match.url)}
                conversationId={match.params.conversationId}
                onToggle={() => this.handleToggle(history)}
              />
            </Fragment>
          );
        }}
      />
    );
  }
}

export default InfoModalContainer;
