// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import './styles.less';

const BrandFooter = (props) => (
  <footer className="brand-footer">
    <div className="brand-footer__content">
      <div className="brand-footer__content-row">
        <div className="brand-footer__content-column">
          <img className="xircles-white-logo" alt="Xircles" />
        </div>
        <div className="brand-footer__content-description-columns">
          <div className="brand-footer__content-column">
            <h3>{props.t('COMMON_GET_IN_TOUCH')}</h3>
            <div className="brand-footer__content-link-wrapper">
              <span>{props.t('COMMON_COUNTRY')}: </span>
              <a href={`tel:${props.t('COMMON_CONTACT_PHONE_NUMBER').replace(/\s/g, '')}`} className="brand-footer__content-link">{props.t('COMMON_CONTACT_PHONE_NUMBER')}</a>
            </div>
            <a href={`mailto:${props.t('COMMON_CONTACT_EMAIL')}`} className="brand-footer__content-link brand-footer__content-link--underlined">{props.t('COMMON_CONTACT_EMAIL')}</a>
            <div className="brand-footer__content-link-wrapper--company-link">
              <span>{props.t('COMMON_BRAND')}, {props.t('COMMON_ENGAGED_BY')}</span>
              <a href={props.t('COMMON_ABOUT_COMPANY_LINK')} className="brand-footer__content-link">{props.t('COMMON_COMPANY')}</a>
              <span className="city">, {props.t('COMMON_COMPANY_CITY')}.</span>
            </div>
            <a href={props.t('COMMON_FOOTER_IMPRINT_LINK')} className="brand-footer__content-link brand-footer__content-link--underlined">{props.t('COMMON_FOOTER_IMPRINT')}</a>
            <a href={props.t('COMMON_DATA_PRIVACY_STATEMENT_LINK')} className="brand-footer__content-link brand-footer__content-link--underlined">{props.t('COMMON_DATA_PRIVACY_STATEMENT')}</a>
          </div>
          <div className="brand-footer__content-column">
            <span>{props.t('COMMON_HEALTHCARE_PARTNER')}</span>
            <span className="description">{props.t('COMMON_CONTACT_US_DESCRIPTION') + ' '}<a href={props.t('COMMON_CONTACT_US_LINK')} className="brand-footer__content-link brand-footer__content-link--underlined">{props.t('COMMON_CONTACT_US').trim()}.</a></span>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default withTranslation()(BrandFooter);